import React, { useEffect, useMemo } from 'react';
import '../CreativeAnalytics.scss';
import { PeriodPicker } from '../PerformanceFrequency/Pickers';
import { useCreativeAnalyticsGet, useCreativeAnalyticsSet } from '../contexts';
import { LIBRARY, INSIGHTS } from '../../../store/CreativeReports/constants';
import { useCreativeAnalyticsStore } from '../../../selectors';
import { getMetricsWithData } from '../PerformanceFrequency/utils';
import { BreadcrumbItem, Breadcrumbs } from '../../../components/Breadcrumbs';
import { SpendThresholdPicker } from '../../../components/Pickers/SpendThreshold';
import { AudiencePicker } from '../../../components/Pickers/Audience';
import './styles.scss';
import { AdvancedOptions } from './AdvancedOptions';
import Presets from '../Presets';
import Refresh from './Refresh';
import Skeleton from 'react-loading-skeleton';
import Button from '../../../components/Button';
import { usePermissions } from '../../../components/Permissions/usePermissions';
import { PRESETS } from '../../../components/Permissions/constants';
import { CUSTOM_SCORING_METRICS } from '../PerformanceFrequency/constants';

export const TOGGLE_STYLE = {
  color: '#1E2B4E',
  fontWeight: 500,
  fontSize: '12px',
  padding: '0',
  margin: '0px 0.5rem',
  userSelect: 'none',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
};

const Header = ({
  display,
  mode,
  filteredData,
  setClientMetrics,
  isPF,
  RecsToggle,
  ScatterToggle,
  GroupByRatioToggle,
  selectedLabels,
  handleFilter,
  showActiveOnly,
  setShowActiveOnly,
  filteredSearchLookup,
  handleSelectLabel,
  retrievedDate,
  MissingDataToggle,
  refresh,
  handleSharePfr,
}) => {
  const {
    isNonPaidMediaType,
    isOrganicView,
    fetchPerformanceData,
    fetchCompareData,
    isEmailView,
    planningMode,
    customScoringData,
  } = useCreativeAnalyticsGet();
  const hasPermission = usePermissions();
  const { missingData } = useCreativeAnalyticsStore();

  useEffect(() => {
    if (filteredData) {
      if (filteredData.length) {
        let metrics = filteredData[0].client_down_funnel_events;

        if (customScoringData) {
          metrics = [
            ...metrics,
            ...CUSTOM_SCORING_METRICS,
          ];
        }
        setClientMetrics(metrics);
      }
    }
  }, [filteredData, customScoringData]);

  const creativesOpts = [
    {
      label: 'All Creatives',
      value: 'all',
    },
    {
      label: 'Active Creatives',
      value: 'active',
      disable: !isPF,
    },
  ];

  let HeaderComp = null;

  let BreadcrumbsHeader = (
    <div className="d-flex align-items-center justify-content-between">
      <Breadcrumbs>
        <BreadcrumbItem
          variant="select"
          text="All Creatives"
          selectedValue={showActiveOnly ? creativesOpts[1] : creativesOpts[0]}
          handleSelect={(selected) => {
            console.log(selected);
            setShowActiveOnly(selected?.value === 'active');
          }}
          options={creativesOpts}
        />

        {/* <BreadcrumbItem variant="custom" disable={isNonPaidMediaType}>
          <AudiencePicker
            toggleClass="analytics__picker darwin-button darwin-button--raised"
            toggleStyle={TOGGLE_STYLE}
          />
        </BreadcrumbItem> */}

        <BreadcrumbItem
          variant="custom"
          disable={isNonPaidMediaType || isEmailView}
        >
          <SpendThresholdPicker
            toggleClass="analytics__picker darwin-button darwin-button--raised"
            toggleStyle={TOGGLE_STYLE}
          />
        </BreadcrumbItem>

        <BreadcrumbItem variant="custom">
          <PeriodPicker
            toggleClass="analytics__picker darwin-button darwin-button--raised"
            name="period1"
            disableToday={false}
            toggleStyle={TOGGLE_STYLE}
            fetchPerformanceData={fetchPerformanceData}
            fetchCompareData={fetchCompareData}
            isOrganicView={isOrganicView}
          />
        </BreadcrumbItem>

        {selectedLabels.map((label, i) => {
          if (label.isLoading) {
            // return <Skeleton count={1} />
            return (
              <BreadcrumbItem variant="custom" text="loading">
                <Skeleton
                  style={{
                    width: '40px',
                    borderRadius: '25px',
                  }}
                  count={1}
                />
              </BreadcrumbItem>
            );
          }

          return (
            <BreadcrumbItem
              variant="text"
              text={label.label}
              onClick={() => handleFilter(i, label)}
            />
          );
        })}

        <BreadcrumbItem
          variant="select"
          options={filteredSearchLookup}
          handleSelect={handleSelectLabel}
          selectedValue={null}
        />
      </Breadcrumbs>
      <div className="d-flex align-items-center">
        {filteredData.length > 0 && (
          <>
            {display === 'performance_frequency' && !isOrganicView && (
              <div id="recs-toggle" className="mr-2">
                {RecsToggle}
              </div>
            )}
            {!!ScatterToggle && <div className="ml-2">{ScatterToggle}</div>}
          </>
        )}

        <Refresh
          {...{
            retrievedDate,
            filteredData,
            refresh,
          }}
        />
        {filteredData.length > 0 && (
          <>
            {!isOrganicView && hasPermission(PRESETS) && (
              <div className="mr-2">
                <Presets />
              </div>
            )}
            {missingData.length ? (
              <div className="mr-2">{MissingDataToggle}</div>
            ) : null}
            <AdvancedOptions {...{ GroupByRatioToggle }} />
          </>
        )}
      </div>
    </div>
  );

  let headerStyle = {
    // zIndex: 100,
  };

  if (isPF) {
    headerStyle = {
      position: 'sticky',
      top: '0px',
    };
  }

  return !['impact_report', 'copy'].includes(display) && !planningMode ? (
    <div
      className={`analytics__header ${mode === 'library' ? 'library' : ''}`}
      style={{ ...headerStyle, borderBottom: '1px solid #e4e9f0' }}
    >
      {BreadcrumbsHeader}
      {HeaderComp}
    </div>
  ) : null;
};

export default Header;
