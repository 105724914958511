import React from 'react';
import TooltipWrapper from './TooltipWrapper';

type MultipleLabelsProps = {
  label: string;
  selected: string;
  onMultipleSelect: (label: string, selection: string) => void;
  confidence: number;
  prediction: string;
};

const MultipleLabels = ({
  label,
  onMultipleSelect,
  current,
  annotatedVisuals,
  visual_hash,
  selectedFrame,
  predictedVisual,
}: MultipleLabelsProps) => {
  const prediction_match = predictedVisual?.[selectedFrame.index]?.labels?.[
      label
    ] || null;

  const confidence = (!!predictedVisual && prediction_match?.confidence) || null;
  const prediction = (confidence && prediction_match?.status) || null;
  const annotated_match =
    annotatedVisuals[visual_hash]?.asset[selectedFrame.index]?.labels?.[label];

  let selected = null;

  if (confidence && !annotated_match) {
    selected = prediction;
  } else if (annotated_match) {
    try {
      selected = annotated_match.status || 'no_label';
    } catch (e) {
      console.error(e);
    }
  }

  const handleClick = (selection) => {
    onMultipleSelect(label, selection);
  };

  const predictedNo = prediction === 'no';
  const predictedNoLabel =
    prediction && prediction !== 'yes' && prediction !== 'no';
  const predictedYes = prediction === 'yes';

  const selectedNo = selected === 'no';
  const selectedNoLabel = selected === 'no_label' || !selected;
  const selectedYes = selected === 'yes';

  const Option = ({ value, children, isSelected, isPredicted }) => {
    let color = '#33B6FF';
    if (isPredicted) color = '#94DB88';

    let style = {};

    if ((isPredicted || isSelected) && value === 'no_label') {
      style = {
        borderLeft: color,
        borderRight: color,
      };
    }

    return (
      <TooltipWrapper {...{ isSelected, isPredicted, confidence }}>
        <div
          className={`multiple-label ${
            isSelected ? 'multiple-label--selected' : ''
          }
          ${isSelected && isPredicted ? 'multiple-label--predicted' : ''}
        `}
          style={style}
          onClick={() => handleClick(value)}
        >
          {children}
        </div>
      </TooltipWrapper>
    );
  };

  return (
    <li className="multiple-options">
      <h3 className="multiple-options__heading">{label}</h3>
      <div className="multiple-options__select">
        <Option value="no" isSelected={selectedNo} isPredicted={predictedNo}>
          No
        </Option>
        <Option
          value="no_label"
          isSelected={selectedNoLabel}
          isPredicted={predictedNoLabel}
        >
          N/A
        </Option>
        <Option value="yes" isSelected={selectedYes} isPredicted={predictedYes}>
          Yes
        </Option>
      </div>
    </li>
  );
};

export default MultipleLabels;
