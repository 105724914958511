import React, { useRef, useState } from 'react';
import { PeriodPicker } from '../PerformanceFrequency/Pickers';
import { useCreativeAnalyticsGet } from '../contexts';
import { useViewSettings } from '../../../selectors';
import Button from '../../../components/Button';
import { TOGGLE_STYLE } from './Header';
import { useClickOutside } from '../../../hooks/useClickOutside';
import { MinResultsPicker } from '../../../components/Pickers/MinResults';
import { PrimaryMetricPicker } from '../../../components/Pickers/PrimaryMetric';
// comparison period
// min results
// group ratios
// show missing data

const DropdownItem = ({ label=null, children }) => {
  return (
    <div className="header-dropdown-item">
      {!!label && <label>{label}</label>}
      {children}
    </div>
  )
}

const Dropdown = ({ show, setShow, GroupByRatioToggle }) => {
  if (!show) return null;
  const { fetchPerformanceData, fetchCompareData, display } =
    useCreativeAnalyticsGet();
  const { isOrganicView, isROAS } = useViewSettings();
  const ref = useRef();
  useClickOutside(ref, () => setShow(false));

  return (
    <div className="header-dropdown" ref={ref}>
      {/* {!isDnaTable && ( */}
      <>
        <DropdownItem label="Primary Metric">
          <PrimaryMetricPicker
            toggleClass="analytics__picker analytics__picker--btn darwin-button darwin-button--raised"
            toggleStyle={{ ...TOGGLE_STYLE, padding: '0.4rem 1rem' }}
          />
        </DropdownItem>

        <DropdownItem label="Group by ad name">
          {!isOrganicView && <div className="mr-2">{GroupByRatioToggle}</div>}
        </DropdownItem>

        <DropdownItem label="Min Results">
          {!isROAS && !isOrganicView && (
            <MinResultsPicker
              toggleClass="analytics__picker analytics__picker--btn darwin-button darwin-button--raised"
              toggleStyle={{ ...TOGGLE_STYLE, padding: '0.4rem 1rem' }}
            />
          )}
        </DropdownItem>
      </>
      {/* )} */}
    </div>
  );
};

export const AdvancedOptions = ({ GroupByRatioToggle }) => {
  const [show, setShow] = useState<boolean>(false);

  return (
    <div className="advanced-opts">
      <Button
        className="advanced-opts-toggle"
        onClick={() => setShow((prev) => !prev)}
        active={show}
        appearance="raised"
      >
        Advanced
      </Button>
      <Dropdown {...{ show, setShow, GroupByRatioToggle }} />
    </div>
  );
};
