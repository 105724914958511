import { useSelector } from 'react-redux';
import { useCreativeAnalyticsGet } from '../../contexts';
import { usePlatform } from '../selectors';

const makeUpdateOnePayload = ({
  visual_hash,
  category,
  custom_category,
  darwin_client_id,
  platform,
  view_id,
  labelOptions,
  annotatedVisuals,
  service_type,
  user_id,
  category_id,
  mutually_exclusive,
}) => {
  const label_changes = annotatedVisuals[visual_hash]
    ? annotatedVisuals[visual_hash].asset
    : {};

  return {
    visual_hash,
    category,
    custom_category,
    label_changes,
    multiple_labels: !mutually_exclusive,
    darwin_client_id,
    platform,
    view_id,
    compliance: false,
    visual_mode: false,
    output_labels: labelOptions,
    user_id: user_id,
    service_type: service_type,
    category_id,
  };
};

export const useSaveCustomCategory = () => {
  const { asyncApi } = useCreativeAnalyticsGet();
  const { darwin_client_id, view_id, user_id } = useSelector(
    ({ ViewSettings: { viewId, darwin_client_id, user_id } }) => ({
      view_id: viewId,
      darwin_client_id,
      user_id,
    })
  );
  const platform = usePlatform();

  return ({
    visual_hash,
    category_name,
    annotatedVisuals,
    changedLabels,
    labelOptions,
    category_id,
    mutually_exclusive,
  }) => {
    const postPayload = makeUpdateOnePayload({
      visual_hash,
      category: category_name,
      custom_category: true,
      darwin_client_id,
      platform,
      view_id,
      labelOptions,
      annotatedVisuals,
      service_type: 'annotations',
      user_id: user_id,
      category_id,
      mutually_exclusive,
    });

    console.log({
      category: category_name,
      isSingle: true,
      postPayload,
      shouldTrain: false,
      changedLabels,
      visual_mode: false,
      category_id,
    })
    asyncApi.createAsyncProcess({
      category: category_name,
      isSingle: true,
      postPayload,
      shouldTrain: false,
      changedLabels,
      visual_mode: false,
      category_id,
      shouldNotify: false,
      onlyNotifyOnce: true,
    });
  };
};
