import React, { useEffect, useState } from 'react';
import { useQaContext } from './context';
import Tooltip from '../../../../components/Tooltip';
import { toCurrency } from '../../../../utils/numbers';
import { DARWIN_NAVY } from '../../../../constants';
import FrameSelect from '../VisualsList/FrameSelect';
import { useDispatch } from 'react-redux';
import { SELECT_FRAME, SET_CURRENT_VISUAL_INDEX } from './reducer';
import '../VisualsList/FrameSelect.scss';
import { Tag } from '../../../../components/Tags';
import IconButton from '../../../../components/IconButton';

export const AssetDisplay = ({ shouldShowFrames }) => {
  const { qaState, qaDispatch, visualsData } = useQaContext();

  const selectedVisual = visualsData[qaState.currentVisualIndex];

  const previousVisual = () => {
    qaDispatch({
      type: SET_CURRENT_VISUAL_INDEX,
      currentVisualIndex:
        qaState.currentVisualIndex > 0
          ? qaState.currentVisualIndex - 1
          : visualsData.length - 1,
    });
  };

  const nextVisual = () => {
    qaDispatch({
      type: SET_CURRENT_VISUAL_INDEX,
      currentVisualIndex:
        qaState.currentVisualIndex < visualsData.length - 1
          ? qaState.currentVisualIndex + 1
          : 0,
    });
  };

  const handleSelectFrame = (frameIndex) => {
    qaDispatch({ type: SELECT_FRAME, frameIndex });
  };

  return (
    <div className="asset-display-wrapper">
      <div className="carousel-toggle">
        <IconButton className="caret-button" onClick={previousVisual}>
          <i className="fa fa-caret-left"></i>
        </IconButton>
        <p className="carousel-toggle__label">
          Visual {qaState.currentVisualIndex + 1} of {visualsData.length}
        </p>
        <IconButton className="caret-button" onClick={nextVisual}>
          <i className="fa fa-caret-right"></i>
        </IconButton>
      </div>
      <Visual
        selectedFrameIndex={qaState.selectedFrameIndex}
        {...{ selectedVisual, handleSelectFrame, shouldShowFrames }}
      />
    </div>
  );
};

const Visual = ({
  selectedVisual,
  selectedFrameIndex,
  handleSelectFrame,
  shouldShowFrames,
}) => {
  if (!selectedVisual) return null;
  const visual_hash = selectedVisual.id;
  const { spend, visual_type } = selectedVisual;
  const isVideo = visual_type === 'video';
  // const [selectedFrame, setSelectedFrame] = useState({
  //   ...selectedVisual.asset[selectedFrameIndex],
  //   index: 0,
  // });

  const selectedFrame = selectedVisual.asset.find(
    (_, i) => i === selectedFrameIndex
  );

  const framesList = isVideo ? selectedVisual.asset : null;

  let url = selectedVisual.asset.url || selectedVisual.asset.asset_url || '';

  if (selectedFrame) url = selectedFrame.url;

  if (selectedVisual.asset.thumbnail_url) {
    url = selectedVisual.asset.thumbnail_url;
  }

  if (!url || !url.length) return null;

  let visual = (
    <div className="visuals-list__img">
      <p>No preview found. :(</p>
      <p>hash {visual_hash}</p>
    </div>
  );

  if (url && url.length) {
    visual = (
      <img className="visuals-list__img" src={selectedFrame.url} alt="" />
    );
  }

  if (isVideo && !shouldShowFrames) {
    console.log({
      selectedVisual,
    });
    visual = (
      <>
        <video
          key={selectedVisual.asset_url}
          autoPlay
          loop
          muted
          plays-inline="true"
          className="visuals-list__img"
          controls
        >
          <source src={selectedVisual.asset_url} type="video/mp4" />
        </video>
      </>
    );
  }

  const frame =
    selectedFrame.frame_num || selectedFrame.frame_num === 0
      ? selectedFrame.frame_num
      : 'thumbnail';
  const timestamp =
    frame === 'thumbnail' ? 'Thumbnail' : `Frame at 00:00:0${frame}`;

  return (
    <Tooltip
      content={
        <div>
          <p className="m-0 text-white">ID: {visual_hash}</p>
          <p className="m-0 text-white">Spend: {toCurrency(spend)}</p>
        </div>
      }
    >
      <div>
        <div className="asset-display">
          {visual}
          <Tag style={{ marginTop: '2rem' }}>{visual_hash}</Tag>

          {isVideo && shouldShowFrames && (
            <p
              style={{
                marginTop: '1rem',
                color: DARWIN_NAVY,
                fontWeight: '500',
                textAlign: 'center',
              }}
            >
              <i className="fa-solid fa-film mr-2"></i>
              {timestamp}
            </p>
          )}
        </div>

        {isVideo && shouldShowFrames && (
          <FrameSelect
            framesList={framesList}
            selectedFrame={{
              ...selectedFrame,
              index: selectedFrameIndex,
            }}
            setSelectedFrame={(frame) => handleSelectFrame(frame.index)}
          />
        )}
      </div>
    </Tooltip>
  );
};

const DummyComponent = () => <div>Dummy Component</div>;
