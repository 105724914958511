import { batch } from 'react-redux';
import {
  VIEW_SETTINGS_SET,
  VIEW_SETTINGS,
  VIEW_SETTINGS_FETCH_AUDIENCES,
  VIEW_SETTINGS_FETCH_CLIENTS,
  VIEW_AUDIENCE,
  setViewSettings,
  fetchAudiences,
  VIEW_CLIENTS,
  VIEW_SETTINGS_FETCH_CAMPAIGNS,
  VIEW_CAMPAIGNS,
} from './actions';
import { REPORTS, resetReports } from '../CreativeReports/actions';
import { ADSETS, resetAdsets } from '../Adsets/actions';
import { API_SUCCESS, apiRequest, API_FAILURE } from '../Api/actions';
import { fetchDashboardConfig } from '../background/api.dashboard';
import { setLoader } from '../Loaders/actions';
import { setError } from '../Errors/actions';
import { PARAM_DEFAULTS } from '../constants';
import { resetAnalytics } from '../CreativeAnalytics/creativeAnalyticsSlice';
import { makePermissionsLookup } from '../../components/Permissions/usePermissions';
import { fillMissingPermissions } from '../../components/Permissions/utils';
import { MOCK_PERMISSIONS } from '../../components/Permissions/constants';
import { CUSTOM_SCORES } from './custom-scores';
import { resetApiState } from '../CreativeAnalytics/api';

function shouldReset(action) {
  return action.type === VIEW_SETTINGS_SET && action.meta.resetStore;
}

const resetMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);

    if (shouldReset(action)) {
      dispatch({
        type: `[${VIEW_SETTINGS}] RESET_STORE`,
        meta: { entities: [REPORTS, ADSETS] },
        actions: [fetchAudiences({ viewId: '' })],
      });
    }
  };

const viewSettingsMiddleware = (store) => (next) => (action) => {
  const result = next(action);

  switch (action.type) {
    case VIEW_SETTINGS_FETCH_AUDIENCES:
      return handleFetchAudiences(store, action);

    case VIEW_SETTINGS_FETCH_CLIENTS:
      return handleFetchClients(store, action);

    case VIEW_SETTINGS_FETCH_CAMPAIGNS:
      return handleFetchCampaignsByPlatform(store, action);

    case `[${VIEW_AUDIENCE}] ${API_SUCCESS}`:
      return successfulFetchAudiences(store, action);

    case `[${VIEW_AUDIENCE}] ${API_FAILURE}`:
      return failedFetchAudiences(store, action);

    case `[${VIEW_CLIENTS}] ${API_SUCCESS}`:
      return successfulFetchClients(store, action);

    case `[${VIEW_CLIENTS}] ${API_FAILURE}`:
      return failedFetchClients(store, action);

    case `[${VIEW_CAMPAIGNS}] ${API_SUCCESS}`:
      return successfulFetchCampaigns(store, action);

    case `[${VIEW_CAMPAIGNS}] ${API_FAILURE}`:
      return failedFetchCampaigns(store, action);

    default:
      return result;
  }

  return result;
};

const handleFetchAudiences = ({ dispatch, getState }, action) => {
  const { data } = action;

  const viewId =
    data.viewId !== '' ? data.viewId : getState().ViewSettings.viewId;

  if (viewId) {
    return batch(() => {
      dispatch(setLoader(VIEW_AUDIENCE, true));
      dispatch(
        apiRequest({
          url: ['api', viewId].join('/'),
          entity: VIEW_AUDIENCE,
          method: 'GET',
        })
      );
    });
  }
};

const successfulFetchAudiences = ({ dispatch }, action) => {
  const {
    response: {
      darwin_client_id,
      audience,
      campaigns,
      campaign_names,
      cubeToken,
      dashboardConfig,
      account_id,
      action_name,
      platforms,
      account_platforms,
      customIntegratedMetrics,
      ga_enabled,
      insights_by_asset,
      darwin_light,
      darwin_direct_enabled,
      custom_events,
      creative_studio_only,
      concepts,
      viewName,
      additional_metrics,
      pull_live_metrics,
      label_queue,
      primary_metric,
      permissions,
      custom_performance_scores,
      visual_success_conditions,
      report_group_id,
    },
  } = action;

  const isDirectMail = platforms.includes('direct mail');

  return batch(() => {
    dispatch(
      setViewSettings({
        darwin_client_id,
        account_id,
        start_date: platforms.includes('direct mail')
          ? new Date('January 1, 2010')
          : PARAM_DEFAULTS.start_date,
        cubeToken,
        action_name,
        isROAS: action_name ? action_name.value?.includes('value_') : false,
        audience: audience || [],
        campaigns: campaigns || [],
        campaign_names: campaign_names || [],
        dashboardConfig: dashboardConfig || [],
        platforms,
        account_platforms: account_platforms || [],
        customIntegratedMetrics: customIntegratedMetrics || [],
        additional_metrics: additional_metrics || [],
        ga_enabled,
        insights_by_asset,
        darwin_light,
        darwin_direct_enabled,
        isDirectMail: platforms.includes('direct mail'),
        isOrganicView:
          isDirectMail && viewName?.toLowerCase()?.includes('organic'),
        isCompetitorView:
          isDirectMail && viewName.toLowerCase().includes('competitor'),
        custom_events: custom_events || null,
        creative_studio_only: creative_studio_only || false,
        concepts: concepts || [],
        isNonPaidMediaType: ['email subject', 'email content', 'organic'].some(
          (type) => viewName.toLowerCase().includes(type)
        ),
        isUltraLight: viewName.toLowerCase().includes('basic'),
        pull_live_metrics,
        label_queue,
        default_primary_metric:
          primary_metric?.length > 0 ? primary_metric : null,
        isEmailView:
          viewName.toLowerCase().includes('email') &&
          platforms.includes('direct mail'),
        permissions: fillMissingPermissions(permissions ?? {}),
        custom_performance_scores: custom_performance_scores?.scores
          ? custom_performance_scores
          : CUSTOM_SCORES[account_id] ?? {},
        visual_success_conditions: visual_success_conditions ?? [],
        isTikTokView: platforms.includes('tiktok'),
        report_group_id: report_group_id ?? null,
        nestedViews: {},
      })
    );
    dispatch(setLoader(VIEW_AUDIENCE, false));
    dispatch(setError(VIEW_AUDIENCE, false));

    [resetReports(), resetAdsets(), resetAnalytics(), resetApiState()].forEach(
      (action0) => dispatch(action0)
    );
  });
};

const failedFetchAudiences = ({ dispatch }, action) => {
  const { error } = action;

  return batch(() => {
    dispatch(setLoader(VIEW_AUDIENCE, false));
    dispatch(setError(VIEW_AUDIENCE, error));
  });
};

const handleFetchClients = ({ dispatch }) => {
  return batch(() => {
    dispatch(setLoader(VIEW_CLIENTS, true));
    dispatch(
      apiRequest({
        url: ['api', 'bulk-actions', 'view-editor', 'get-client-list'].join(
          '/'
        ),
        entity: VIEW_CLIENTS,
        method: 'GET',
      })
    );
  });
};

const successfulFetchClients = ({ dispatch }, action) => {
  const {
    response: { data },
  } = action;

  return batch(() => {
    dispatch(setViewSettings({ clients: data }));
    dispatch(setLoader(VIEW_CLIENTS, false));
    dispatch(setError(VIEW_CLIENTS, false));
  });
};
const failedFetchClients = ({ dispatch }, action) => {
  const { error } = action;

  return batch(() => {
    dispatch(setLoader(VIEW_CLIENTS, false));
    dispatch(setError(VIEW_CLIENTS, error));
  });
};

const handleFetchCampaignsByPlatform = ({ dispatch, getState }, action) => {
  const { data } = action;
  const darwin_client_id =
    data.darwin_client_id !== ''
      ? data.darwin_client_id
      : getState().ViewSettings.darwin_client_id;
  const platform = data.platform || null;

  if (darwin_client_id && data.platform) {
    return batch(() => {
      dispatch(setLoader(VIEW_CAMPAIGNS, true));
      dispatch(
        apiRequest({
          url: [
            'api',
            'bulk-actions',
            'view-editor',
            darwin_client_id,
            'get-campaigns-by-platform',
            platform,
          ].join('/'),
          entity: VIEW_CAMPAIGNS,
          method: 'GET',
        })
      );
    });
  }
};

const successfulFetchCampaigns = ({ dispatch }, action) => {
  const {
    response: { data },
  } = action;

  return batch(() => {
    dispatch(
      setViewSettings({
        campaignsByPlatform: { [data.platform]: data.campaigns },
      })
    );
    dispatch(setLoader(VIEW_CAMPAIGNS, false));
    dispatch(setError(VIEW_CAMPAIGNS, false));
  });
};
const failedFetchCampaigns = ({ dispatch }, action) => {
  const { error } = action;

  return batch(() => {
    dispatch(setLoader(VIEW_CAMPAIGNS, false));
    dispatch(setError(VIEW_CAMPAIGNS, error));
  });
};

const dashboardMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    if (
      action.type === VIEW_SETTINGS_SET &&
      action.meta.updateDashboardConfig
    ) {
      dispatch(fetchDashboardConfig(action.settings.dashboardConfig));
    }

    next(action);
  };

const middlewares = [
  resetMiddleware,
  viewSettingsMiddleware,
  dashboardMiddleware,
];

export default middlewares;
