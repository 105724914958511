import React, {
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ErrorBoundary } from '../../components/Helpers/Error';
import { useDnaReportColumns } from './columns';
import {
  useCreativeAnalyticsGet,
  useCreativeAnalyticsSet,
} from '../CreativeAnalytics/contexts';
import { useCreativeAnalyticsStore, useViewSettings } from '../../selectors';
import { useCustomScoringApi, useDNAReportApi, useDnaApi } from './api';
import Table from './Table';
import { useDispatch, useSelector } from 'react-redux';
import {
  setDNAReportData,
  selectedVisuals,
  toggleSelectVisual,
} from '../../store/CreativeAnalytics/creativeAnalyticsSlice';
import {
  checkSortCache,
  checkSortCacheWithParams,
  getFilteredCreatives,
  getRows,
  makeTitle,
  getExistingManualTags,
} from './utils';
import CreativePerformance from './CreativePerformance';
import './index.scss';

import {
  useTable,
  useSortBy,
  useFilters,
  useFlexLayout,
  useColumnOrder,
  useRowSelect,
} from 'react-table';
import { getDnaTableCsv, getExportFileBlob } from './export';
import { useExportData } from './export';
import { IndeterminateCheckbox } from './columns';
import { ManualTags } from './ManualTags';
import { useDNAParams, useShareReport, useSortBinding } from './hooks';
import { CreativeGroup } from './types';
import { RightDrawerWrapper } from '../CreativeAnalytics/RightDrawer';
import CreativesSelect from './CreativesSelect';
import { CommonElements } from './CommonElements';
import { RIGHT_RAIL_WIDTH } from './constants';
import { CountSelect, ModeToggle } from './Helpers';
import Button from '../../components/Button';
import { useGetCommonElements } from '../CreativeAnalytics/PerformanceFrequency/api';
import { useDNAColumnOptions } from './useDNAColumnOptions';
import { isEqual } from 'lodash';
import { Editor } from './CreativesSelect/Editor';
import { DnaColumnPicker } from './DnaColumnPicker';
import { VisualTable } from './CreativePerformance/VisualTable';
import { Tag } from '../../components/Tags';
import { useLazyGetDnaReportQuery } from '../../store/CreativeAnalytics/api';
import Tooltip from '../../components/Tooltip';
import IconButton from '../../components/IconButton';
import { usePreviousDateRangeData } from './PeriodComparison/api';
import { CreativePerformanceProvider } from './context';
import { getTooltipText } from './PeriodComparison/utils';
import ScatterIcon from './icons/ScatterIcon';
import VisualsIcon from './icons/VisualsIcon';
import TableIcon from './icons/TableIcon';
import Scatter from './Scatter';
import { ShareReport } from './ShareReport';
import useTrackDeps from '../../hooks/useTrackDeps';
import { toDarwinDate } from '../../utils/darwin-dates';
import { SortOptions } from './CreativePerformance/SortOptions';

const Wrapper = ({
  visualsData,
  byId,
  pfrLoading,
  error,
  metadata,
  columns,
  handleColumns,
  filteredVisuals,
}) => {
  const [groupParams, setGroupParams]: [
    CreativeGroup,
    React.Dispatch<SetStateAction<CreativeGroup>>,
  ] = useDNAParams();
  const trendData: {
    data: [];
    status: 'success' | 'loading' | 'error';
    request: () => void;
  } = usePreviousDateRangeData();
  const customScoringApi = useCustomScoringApi();

  const { additional_metrics, custom_performance_scores } = useViewSettings();
  const {
    filteredData,
    primary_metric,
    clientMetrics,
    isROAS,
    customEvents,
    start_date,
    end_date,
    visualsLookup,
    loading,
    customScoringData,
  } = useCreativeAnalyticsGet();
  const { setCustomScoringData, setClientMetrics } = useCreativeAnalyticsSet();
  const { selectedVisuals } = useCreativeAnalyticsStore();
  const {
    viewId: view_id,
    darwin_client_id: client_id,
    isOrganicView,
  } = useViewSettings();
  const [activeManualTagsModal, setActiveManualTagsModal] = useState<
    string | null
  >(null);
  const [showCreativeSelect, setShowCreativeSelect] = useState(false);
  const commonElementsApi = useGetCommonElements();
  const columnOptions = useDNAColumnOptions({
    isROAS,
    clientMetrics,
    customEvents,
  });
  const metricsLookup = useMemo(() => {
    const resultMap = new Map();
    columnOptions?.forEach((obj) => {
      if (obj?.value) {
        resultMap.set(obj.value, obj.label);
      }
    });
    return resultMap;
  }, [columnOptions]);
  const lastCommonElementsRequest = useRef(null);
  const [isEditing, setIsEditing] = useState(false);
  const [hiddenElements, setHiddenElements] = useState([]);
  const [showScatter, setShowScatter] = useState(false);
  const [showVisuals, setShowVisuals] = useState(true);
  const [showTable, setShowTable] = useState(true);
  const [stacked, setStacked] = useState(true);

  useEffect(() => {
    if (stacked) {
      setGroupParams({ stacked: true });
    } else {
      setGroupParams({ stacked: false });
    }
  }, [stacked]);

  const {
    shouldShowShareModal,
    openShareModal,
    closeShareModal,
    submitShareReport,
    sharedReportId,
    loadingShareReport,
  } = useShareReport();

  const formatMetric = (metric): string => {
    const match = metricsLookup?.get(metric);

    if (match) {
      return match;
    }

    return metric;
  };

  const parseCommonElements = (data) => {
    if (!data) return [];
    try {
      const arr = JSON.parse(data.data.body);
      if (!Array.isArray(arr)) {
        return [];
      } else {
        return arr;
      }
    } catch (e) {
      console.error(e);
      return [];
    }
  };

  const commonElementsData = parseCommonElements(commonElementsApi.post.data);

  const metadataFields = useMemo(() => {
    if (metadata) {
      return metadata.categories?.map((c) => c.category_name);
    }
  }, [metadata]);

  const handleCreateManualTag = (visual_id) => {
    setActiveManualTagsModal(visual_id);
  };

  const customScoringColumns: string[] = useMemo(() => {
    if (!customScoringApi.data?.[0]) return [];
    const obj = customScoringApi.data[0];

    return Object.keys(obj).filter((col) => {
      const isValid = ![
        'Asset URL',
        'Visual',
        'Fitness Score',
        'Spend Quintile',
        'Performance Quintile',
        ,
        'CPA',
        'Spend',
        'Period Type',
        'Period Dates',
        'Ad Names',
      ].includes(col);

      return isValid;
    }, []);
  }, [customScoringApi.data]);

  useEffect(() => {
    if (customScoringApi.data && !customScoringData.length) {
      setCustomScoringData(customScoringApi.data);
    }
  }, [customScoringApi.data]);

  const cols = useDnaReportColumns({
    primary_metric,
    metadata,
    additional_metrics,
    handleCreateManualTag,
    clientMetrics,
    customScoringData: customScoringApi.data,
  });

  const rows = useMemo(() => {
    return getRows(
      visualsData,
      metadata,
      primary_metric,
      trendData,
      start_date,
      end_date,
      customScoringApi.data
    );
  }, [
    visualsData,
    metadata,
    trendData.status,
    toDarwinDate(start_date),
    toDarwinDate(end_date),
    customScoringApi.data?.length,
  ]);
  const menuHeight = rows.length * 40;

  const allAreSelected = useMemo(() => {
    return rows.every((row) => groupParams.selectedVisuals.includes(row._id));
  }, [rows, groupParams.selectedVisuals]);

  const fetchCommonElements = (visualIds) => {
    if (isEqual(visualIds, lastCommonElementsRequest.current)) {
      return;
    }
    commonElementsApi.post.request(visualIds);
    lastCommonElementsRequest.current = [...visualIds];
  };

  const selectAllVisuals = () => {
    setGroupParams({
      selectedVisuals: visualsData.map((visual) => visual._id),
    });
  };

  const deselectAllVisuals = () => {
    setGroupParams({ selectedVisuals: [] });
  };

  const tableColumns = useMemo(() => {
    return cols.filter((column) =>
      [
        'id',
        ...columns,
        ...customScoringColumns,
        'period_dates',
        'period_type',
        'new_visual',
      ].includes(column.accessor)
    );
  }, [cols, columns]);

  const tableInstance = useTable(
    {
      columns: tableColumns,
      data: rows,
      getExportFileBlob,
      initialState: {
        sortBy: [
          {
            id: groupParams.sortBy ?? 'fitness_score',
            desc: groupParams.sortOrder === 'descending',
          },
        ],
        selectedRowIds: groupParams.selectedVisuals.reduce((acc, id) => {
          acc[id] = true;
          return acc;
        }, {}),
        filters: groupParams.columnFilters ?? [],
        hiddenColumns: ['period_type', 'period_dates', 'new_visual'],
      },
      autoResetHiddenColumns: false,
      autoResetSortBy: false,
      autoResetFilters: false,
    },
    useFilters,
    useSortBy,
    useFlexLayout,
    useColumnOrder,
    useExportData,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: 'selection',
          width: 64,
          maxWidth: 64,
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox
                onChange={(e) => {
                  handleToggleAllRows(e.target.checked);
                }}
                {...getCheckboxState()}
              />
            </div>
          ),
          Cell: ({ row }) => {
            const id = row.original.id ?? row.original._id;
            return (
              <div className="checkbox-cell">
                <IndeterminateCheckbox
                  onChange={() => toggleRowSelected(id, true)}
                  checked={groupParams.selectedVisuals.some(
                    (visualId) => id === visualId
                  )}
                />
              </div>
            );
          },
        },
        ...columns,
      ]);
    }
  );

  const { setSortBy } = tableInstance;

  const setSort = (id, desc) => {
    setSortBy([{ id: id, desc }]);

    if (!columns.includes(id)) {
      handleColumns({
        column: id,
        checked: true,
      });
    }
  };

  useSortBinding({ tableInstance, groupParams, setGroupParams });
  const fetchCustomScoringData = () => {
    customScoringApi.request(getDnaTableCsv(tableInstance, visualsLookup));
  };

  const prevColFilters = useRef(null);

  useEffect(() => {
    if (tableInstance.state.filters?.length) {
      if (!isEqual(prevColFilters.current, tableInstance.state.filters)) {
        setGroupParams({ columnFilters: tableInstance.state.filters });
        prevColFilters.current = tableInstance.state.filters;
      }
    }
  }, [tableInstance.state.filters]);

  const getCheckboxState = () => {
    return {
      checked: allAreSelected,
      indeterminate:
        !allAreSelected &&
        rows.some((row) => groupParams.selectedVisuals.includes(row._id)),
    };
  };

  const data = useMemo(() => {
    const filtered = [...tableInstance.rows].filter((row) =>
      groupParams.selectedVisuals.includes(row.original._id)
    );

    const totalSpend = filtered.reduce(
      (total, row) => (total += row.original.spend),
      0
    );

    return (
      filtered.map((row, i) => ({
        ...row.original,
        rank: i + 1,
        percentSpend: isFinite(row.original.spend / totalSpend)
          ? row.original.spend / totalSpend
          : 0,
      })) ?? []
    );
  }, [tableInstance.rows, groupParams.selectedVisuals]);

  const toggleRowSelected = (id, shouldAccumulate) => {
    let update = [];

    if (shouldAccumulate) {
      if (groupParams.selectedVisuals.includes(id)) {
        update = [...groupParams.selectedVisuals].filter(
          (existingId) => existingId !== id
        );
      } else {
        update = [...groupParams.selectedVisuals, id];
      }
    } else {
      if (groupParams.selectedVisuals.includes(id)) {
        update = [];
      } else {
        update = [id];
      }
    }

    setGroupParams({ selectedVisuals: update });
  };

  const handleToggleAllRows = (checked) => {
    if (checked) return selectAllVisuals();
    else return deselectAllVisuals();
  };

  const selectTop3 = (visuals, metric) => {
    try {
      const preferLowerValue = metric?.slice(0, 2) === 'cp';
      const sortDesc = (a, b) => b[metric] - a[metric];
      const sortAsc = (a, b) => a[metric] - b[metric];

      const sorted = visuals.sort((a, b) => {
        if (!a[metric]) {
          return 1;
        } else if (!b[metric]) {
          return -1;
        } else {
          return preferLowerValue ? sortAsc(a, b) : sortDesc(a, b);
        }
      });
      const ids = sorted
        .slice(0, 3)
        .map((visual) => visual.id || visual._id)
        .filter((id) => !!id);

      setGroupParams({
        selectedVisuals: ids,
      });
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (groupParams.selectBy === 'top') {
      selectTop3(
        [...tableInstance.rows.map((row) => row.original)],
        groupParams.metric
      );
    }
  }, [groupParams.selectBy, groupParams.metric]);

  const title = makeTitle({
    ...groupParams,
    setGroupParams,
    formattedMetric: formatMetric(groupParams.metric),
    formattedSortMetric: formatMetric(groupParams.sortBy),
  });

  useEffect(() => {
    if (!!data.length) {
      fetchCommonElements(data.map(({ _id }) => _id));
    }
  }, [data]);

  const isSorted = !!tableInstance.state?.sortBy?.length;

  const SortText = (
    <p
      style={{
        fontWeight: 500,
        marginBottom: 0,
        marginLeft: '1rem',
      }}
      className="sort-text"
    >
      {isSorted && (
        <i
          className={`fa-solid fa-caret${
            groupParams.sortOrder === 'descending' ? '-down' : '-up'
          } mr-2`}
        />
      )}
      {!isSorted && (
        <i className="fa-solid fa-minus mr-2" style={{ color: '#616161' }} />
      )}
      {isSorted ? (
        <span>Sorted by {formatMetric(groupParams.sortBy)} </span>
      ) : (
        <span style={{ color: '#616161' }}>No sort applied</span>
      )}
      {isSorted && <span>{formatMetric(groupParams.sortOrder)}</span>}
    </p>
  );

  const SectionToggle = ({ handleClick, icon, label, active }) => {
    return (
      <Button
        className={`section-toggle ${active ? 'active' : ''}`}
        onClick={handleClick}
      >
        {icon}
        <span>{label}</span>
      </Button>
    );
  };

  let Render = (
    <div
      style={{ width: 'calc(100vw - 300px)', height: '50vh' }}
      className="d-flex justify-content-center align-items-center"
    >
      <div className="dna__loader" />
    </div>
  );

  if (!pfrLoading) {
    if (tableInstance.rows.length > 0) {
      Render = (
        <>
          <div
            className={`section-container section-container__scatter ${
              showScatter ? 'show' : ''
            }`}
          >
            <Scatter
              rows={tableInstance.rows}
              handleSeriesClick={toggleRowSelected}
              selectedVisuals={groupParams.selectedVisuals}
              filteredVisuals={filteredVisuals ?? []}
            />
          </div>
          <div
            className={`section-container section-container__visuals ${
              showVisuals ? 'show' : ''
            }`}
          >
            <div>
              <CreativePerformance
                data={data}
                selectedColumns={columns}
                tableInstance={tableInstance}
                metadata={metadata}
                metadataFields={metadataFields}
                handleCreateManualTag={handleCreateManualTag}
                primary_metric={primary_metric}
                columns={columns}
                groupParams={groupParams}
                formatMetric={formatMetric}
                columnOptions={columnOptions}
                handleColumns={handleColumns}
                SortToggle={
                  <SortOptions
                    {...{ SortText, setGroupParams, setSort, primary_metric }}
                  />
                }
                ShareButton={
                  <Button onClick={openShareModal} appearance="raised">
                    <i
                      class="fa-solid fa-share mr-2"
                      style={{ transform: 'translateY(-1px)' }}
                    ></i>
                    <span>Share</span>
                  </Button>
                }
                stacked={stacked}
                setStacked={setStacked}
                ColumnPicker={
                  <DnaColumnPicker
                    columns={columns}
                    onChange={handleColumns}
                    menuHeight={menuHeight}
                  />
                }
              />
              {!!commonElementsData?.length && (
                <CommonElements
                  commonElementsData={commonElementsData}
                  filteredData={filteredData}
                  loading={commonElementsApi.post.loading}
                  {...{ hiddenElements, setHiddenElements }}
                />
              )}
            </div>
          </div>
          <div
            className={`section-container section-container__table ${
              showTable ? 'show' : ''
            }`}
          >
            <Table
              tableInstance={tableInstance}
              manualTags={metadata?.manual_tags}
              QuickSelect={
                <div className="d-flex" style={{ position: 'relative' }}>
                  <Button
                    className="mr-2"
                    id="quick-select-btn"
                    appearance="raised"
                    active={showCreativeSelect}
                    onClick={() => setShowCreativeSelect((prev) => !prev)}
                  >
                    Quick Select
                  </Button>
                  {showCreativeSelect && (
                    <CreativesSelect
                      groupParams={groupParams}
                      setGroupParams={setGroupParams}
                      metadata={metadata}
                      setShowCreativeSelect={setShowCreativeSelect}
                      {...{ isEditing, setIsEditing }}
                    />
                  )}
                </div>
              }
              CustomScoringData={
                <Tooltip
                  content={
                    <div>
                      <p className="mb-0">Fetch custom scoring data</p>
                      {customScoringApi.status === 'success' && (
                        <p className="mb-0">Data fetched successfully.</p>
                      )}
                      {customScoringApi.status === 'error' && (
                        <p className="mb-0">
                          There was an error fetching the data.{' '}
                          {customScoringApi.error}
                        </p>
                      )}
                    </div>
                  }
                >
                  <div>
                    <IconButton
                      onClick={fetchCustomScoringData}
                      status={customScoringApi.status}
                      className="mr-2"
                      // active={trendData.status !== 'loading'}
                    >
                      <i class="fa-regular fa-file-lines"></i>
                    </IconButton>
                  </div>
                </Tooltip>
              }
              ColumnPicker={
                <DnaColumnPicker
                  columns={columns}
                  onChange={handleColumns}
                  menuHeight={menuHeight}
                />
              }
              TrendData={
                <Tooltip content={getTooltipText(trendData)}>
                  <div>
                    <IconButton
                      onClick={() => trendData.request()}
                      status={trendData.status}
                      className="mr-2"
                      // active={trendData.status !== 'loading'}
                    >
                      <i className="fa-solid fa-chart-line"></i>
                    </IconButton>
                  </div>
                </Tooltip>
              }
              groupParams={groupParams}
              selectAllVisuals={selectAllVisuals}
              hasTrendData={trendData.status === 'success'}
            />
          </div>
        </>
      );
    } else {
      Render = <div>No data to display.</div>;
    }
  }

  return (
    <ErrorBoundary>
      <CreativePerformanceProvider {...{ trendData }}>
        <div className="dna">
          <div className="d-flex justify-content-between">
            <div
              className="dna-content"
              style={{
                paddingTop: !loading && !filteredData.length ? '4rem' : '2rem',
              }}
            >
              <div className="d-flex align-items-center dna__header">
                <div className="d-flex">
                  <h2 className="dna__heading">Creative Performance</h2>
                </div>

                <div className="dna__toggles">
                  <SectionToggle
                    label="Scatter"
                    icon={<ScatterIcon active={showScatter} />}
                    handleClick={() => setShowScatter((prev) => !prev)}
                    active={showScatter}
                  />
                  <SectionToggle
                    label="Visuals"
                    icon={<VisualsIcon active={showVisuals} />}
                    handleClick={() => setShowVisuals((prev) => !prev)}
                    active={showVisuals}
                  />
                  <SectionToggle
                    label="Table"
                    icon={<TableIcon active={showTable} />}
                    handleClick={() => setShowTable((prev) => !prev)}
                    active={showTable}
                  />
                </div>
              </div>
              {Render}
            </div>
            {isEditing && (
              <RightDrawerWrapper
                style={{
                  minWidth: `${RIGHT_RAIL_WIDTH}px`,
                  borderLeft: '1px solid #ECF1F9',
                }}
              >
                <Editor
                  groupParams={groupParams}
                  setGroupParams={setGroupParams}
                  metadata={metadata}
                  setIsEditing={setIsEditing}
                />
              </RightDrawerWrapper>
            )}
          </div>
        </div>

        {!!activeManualTagsModal && (
          <ManualTags
            current={byId[activeManualTagsModal] ?? null}
            close={() => setActiveManualTagsModal(null)}
            existing={getExistingManualTags(activeManualTagsModal, metadata)}
          />
        )}

        {!!shouldShowShareModal && (
          <ShareReport
            close={closeShareModal}
            submit={({ report_name, report_description, elementsToInclude }) =>
              submitShareReport({
                data,
                columns,
                metadataFields,
                report_name,
                report_description,
                columnOptions,
                params: groupParams,
                metadata,
              })
            }
            sharedReportId={sharedReportId}
            loading={loadingShareReport}
            location="dna"
          />
        )}
      </CreativePerformanceProvider>
    </ErrorBoundary>
  );
};

export default Wrapper;
