import React, { createContext, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';

const GetCtx = createContext(null);
const SetCtx = createContext(null);

export function CreativeAnalyticsProvider({
  filteredData=[],
  filteredCompareData,
  sortedCompareData,
  categoriesLookup = {},
  compareCategoriesLookup = {},
  data,
  start_date,
  end_date,
  asyncApi,
  account_id,
  display,
  category,
  spendThreshold,
  creativeCount,
  period1,
  period2,
  selected,
  newAds,
  insights_by_asset,
  onlyNewAds,
  primaryPhraseLabels,
  categoryLabelOptions,
  handleSelectElement,
  editCategory,
  editCategoryVisual,
  shouldShowBoxPlot,
  maxSquaresLookup,
  isROAS,
  getUdcApi,
  derivedLabelsApi,
  getDerivedLabelsApi,
  setEditCategory,
  setEditCategoryVisual,
  setShouldShowBoxPlot,
  categoryFilters,
  selectedLabels,
  setSelectedLabels,
  setParams,
  clientMetrics,
  setClientMetrics,
  isDirectMail,
  allVisuals,
  filteredAllVisualsLabel,
  cpasList,
  reportCardDataType,
  reportCardMetric,
  metricOptions,
  requestOpen,
  setRequestOpen,
  rcCategorySettings,
  setRcCategorySettings,
  selectedAudience,
  reportCardSettings,
  columnDropdownOptions,
  customEvents,
  additional_metrics,
  editBasicLabels,
  setEditBasicLabels,
  activeBoxPlotCategory,
  setActiveBoxPlotCategory,
  visualsLookup,
  resultsThreshold,
  fetchPerformanceData,
  fetchCompareData,
  mode,
  libraryView,
  primary_metric,
  isNonPaidMediaType,
  isUltraLight,
  isOrganicView,
  viewName,
  phraseThemeDataApi,
  organizePhraseThemesApi,
  phraseThemeData,
  searchTermsData,
  pull_live_metrics,
  presetsApi,
  shouldGroupLabelsByRatio,
  defaultPfrColumns,
  defaultDnaColumns,
  defaultDates,
  isEmailView,
  setClustersData,
  clustersApi,
  allVisualsList,
  min,
  loading,
  dnaColumnsParam,
  impactCategories,
  setImpactCategories,
  isStaticReport,
  columns,
  handleColumns,
  customIntegratedMetrics,
  ga_enabled,
  columnOptions,
  planningMode,
  setPlanningMode,
  coreVisuals,
  setCoreVisuals,
  requestWidth,
  setRequestWidth,
  isGoHealth,
  rightDrawerDisplay,
  customScoringData,
  setCustomScoringData,
  children,
}) {
  const audienceLabels = filteredData.filter(
    (label) => label.category === 'Audience'
  );

  return (
    <GetCtx.Provider
      value={{
        filteredData: filteredData || [],
        filteredCompareData,
        sortedCompareData,
        categoriesLookup,
        compareCategoriesLookup,
        visualsLookup,
        data,
        asyncApi,
        account_id,
        display,
        category,
        spendThreshold,
        creativeCount,
        period1,
        period2,
        start_date,
        end_date,
        selected,
        newAds,
        onlyNewAds,
        primaryPhraseLabels,
        categoryLabelOptions,
        insights_by_asset,
        editCategory,
        editCategoryVisual,
        shouldShowBoxPlot,
        selectedLabels,
        categoryFilters,
        maxSquaresLookup,
        isROAS,
        getUdcApi,
        derivedLabelsApi,
        getDerivedLabelsApi,
        clientMetrics,
        isDirectMail,
        allVisuals,
        filteredAllVisualsLabel,
        cpasList,
        reportCardDataType,
        reportCardMetric,
        metricOptions,
        requestOpen,
        rcCategorySettings,
        selectedAudience,
        reportCardSettings,
        columnDropdownOptions,
        editBasicLabels,
        activeBoxPlotCategory,
        resultsThreshold,
        isNonPaidMediaType,
        isUltraLight,
        isOrganicView,
        fetchPerformanceData,
        fetchCompareData,
        mode,
        libraryView,
        primary_metric,
        viewName,
        phraseThemeDataApi,
        organizePhraseThemesApi,
        phraseThemeData,
        searchTermsData,
        pull_live_metrics,
        presetsApi,
        shouldGroupLabelsByRatio,
        defaultPfrColumns,
        defaultDnaColumns,
        defaultDates,
        isEmailView,
        customEvents,
        additional_metrics,
        clustersApi,
        allVisualsList,
        min,
        loading,
        dnaColumnsParam,
        impactCategories,
        isStaticReport,
        audienceLabels,
        columns,
        handleColumns,
        customIntegratedMetrics,
        ga_enabled,
        columnOptions,
        planningMode,
        coreVisuals,
        requestWidth,
        isGoHealth,
        rightDrawerDisplay,
        customScoringData,
      }}
    >
      <SetCtx.Provider
        value={{
          setParams,
          handleSelectElement,
          setEditCategory,
          setEditCategoryVisual,
          setShouldShowBoxPlot,
          setSelectedLabels,
          setClientMetrics,
          setRequestOpen,
          setRcCategorySettings,
          setEditBasicLabels,
          setActiveBoxPlotCategory,
          setClustersData,
          setImpactCategories,
          setPlanningMode,
          setCoreVisuals,
          setRequestWidth,
          setCustomScoringData,
        }}
      >
        {children}
      </SetCtx.Provider>
    </GetCtx.Provider>
  );
}

export function useCreativeAnalyticsGet() {
  return useContext(GetCtx);
}

export function useCreativeAnalyticsSet() {
  return useContext(SetCtx);
}
