import React, { useEffect, useMemo, useRef } from 'react';
import { usePostRequest } from '../../../../components/Hooks/usePostRequest';
import { useRecommendationsContext } from '../context';
import _ from 'lodash';

import './styles.scss';
import { BasicLoading } from '../../../../components/Helpers/Loading';
import { Callout } from '../../../../components/Callout';
import { isEqual } from 'lodash';
import Button from '../../../../components/Button';
import IconButton from '../../../../components/IconButton';

const fieldsToExclude = [
  'asset',
  'isTopPerforming',
  'isWinner',
  'isLoser',
  'isNew',
  'isSelected',
  'isTopSpending',
  'manual_tags',
  '_id',
];

function excludeFields(obj) {
  return Object.keys(obj)
    .filter((key) => !fieldsToExclude.includes(key))
    .reduce((newObj, key) => {
      newObj[key] = obj[key];
      return newObj;
    }, {});
}

const makePayload = (visuals = []) => {
  // const dna_table = visuals.map((visual) => {
  //   return {
  //     'Asset URL': visual.asset_url || '',
  //     'Fitness Score': visual.fitness_score || null,
  //     'Spend Quintile': visual.spend_quintile || null,
  //     'Performance Quintile': visual.primary_metric_quintile || null,
  //     CPA: visual.cpa || null,
  //     Spend: visual.spend || null,
  //     'Ad Names': visual.ad_names || [],
  //   };
  // });

  // remove asset, manual tags,

  return {
    dna_table: visuals.map((visual) => {
      return {
        id: visual.id || visual._id,
        ...excludeFields(visual),
      };
    }),
  };
};

const formatData = (data) => {
  if (data?.data?.insights) {
    return data.data.insights;
  }

  return null;
};

const endpoint = ['api', 'creative-analytics', 'get-general-facts'].join('/');

const useFactsApi = () => {
  const { visuals, fetchPrevPeriodData, trendData } =
    useRecommendationsContext();
  const { data, loading, error, request } = usePostRequest(endpoint);

  const prevVisualIds = useRef([]);

  useEffect(() => {
    fetchPrevPeriodData();
  }, []);

  const visualIds = visuals.map((visual) => visual.id || visual._id);

  useEffect(() => {
    if (
      trendData.status === 'success' &&
      !_.isEqual(prevVisualIds.current, visualIds)
    ) {
      const payload = makePayload(visuals);
      request(payload);
      prevVisualIds.current = visualIds;
    }
  }, [visualIds, trendData.status]);

  return {
    data: formatData(data),
    loading: loading || trendData.status === 'loading',
    error,
    request,
    fetchPrevPeriodData: () => {
      prevVisualIds.current = [];
      fetchPrevPeriodData();
    },
  };
};

export const Facts = () => {
  const { data, loading, error, fetchPrevPeriodData } = useFactsApi();

  if (loading) {
    return (
      <div className="m-5 d-flex justify-content-center w-100 text-center">
        <i className="fas fa-spinner fa-spin" style={{ fontSize: '1.2rem' }} />
      </div>
    );
  } else if (error) {
    return <p>Error: {error?.error?.toString()}</p>;
  } else if (data?.length > 0) {
    return (
      <div className="facts">
        <IconButton
          style={{ position: 'absolute', top: 0, left: 0 }}
          className="mt-3"
          appearance="raised"
          onClick={() => {
            fetchPrevPeriodData();
          }}
        >
          <i className="fa-solid fa-arrow-rotate-right" />
        </IconButton>
        <FactsDisplay data={data} />
      </div>
    );
  } else {
    return (
      <div className="m-3 d-flex justify-content-between w-100">
        <p>No data to display.</p>
        <Button
          appearance="raised"
          onClick={() => {
            fetchPrevPeriodData();
          }}
        >
          Retry
        </Button>
      </div>
    );
  }
};

export const FactsDisplay = ({ data }) => {
  return (
    <ul>
      {data.map((item, index) => {
        return (
          <li className="fact-item">
            <div className="fact-item-content">
              <div className="mb-5">
                <h3 className="fact-item__heading">
                  <i className="fa-solid fa-magnifying-glass-chart"></i>Insight
                  Statement {index + 1}
                </h3>
                <p className="fact-item__desc">{item.insight_statement}</p>
              </div>

              <div className="fact-grid">
                <p className="fact-grid__label">Insight Value:</p>
                <div className="fact-grid__value">
                  <p>{item.insight_value}</p>
                </div>

                <p className="fact-grid__label">Insight Context:</p>
                <div className="fact-grid__value">
                  <p>{item.insight_context}</p>
                </div>
              </div>
            </div>
          </li>
        );
      })}
    </ul>
  );
};
