import React, { createContext, useContext } from 'react';

const Ctx = createContext(null);

export function SuggestionsProvider({
  showMenu,
  activeTab,
  bestPerforming,
  isROAS,
  fromCreativeStudio,
  isDirectMail,
  suggestions,
  handleRevisionModal,
  handleQuickRevisionRequest,
  handleAddFavorite,
  handleDeleteFavorite,
  favoriteSuggestions,
  generalSuggestions,
  executionSuggestions,
  performanceSuggestions,
  getGeneralSuggestions,
  trackSuggestions,
  easySuggestions,
  getEasySuggestions,
  copilotData,
  copilotLoading,
  copilotRequestError,
  fetchCopilot,
  userFeedback,
  setUserFeedback,
  previousOutput,
  children,
}) {
  return (
    <Ctx.Provider
      value={{
        showMenu,
        activeTab,
        bestPerforming,
        isROAS,
        fromCreativeStudio,
        isDirectMail,
        suggestions,
        handleRevisionModal,
        handleQuickRevisionRequest,
        handleAddFavorite,
        handleDeleteFavorite,
        favoriteSuggestions,
        generalSuggestions,
        executionSuggestions,
        performanceSuggestions,
        getGeneralSuggestions,
        trackSuggestions,
        easySuggestions,
        getEasySuggestions,
        copilotData,
        copilotLoading,
        copilotRequestError,
        fetchCopilot,
        userFeedback,
        setUserFeedback,
        previousOutput,
      }}
    >
      {children}
    </Ctx.Provider>
  );
}

export function useSuggestionsContext() {
  return useContext(Ctx);
}