import React, { useRef, useState } from 'react';
import { useClickOutside } from '../../../hooks/useClickOutside';
import Button from '../../../components/Button';

const DropdownItem = ({ label, onClick }) => {
  return (
    <div className="sort-dropdown__item" onClick={onClick}>
      Sort by {label} {}
    </div>
  );
};

const SortDropdown = ({ show, setShow, setSort, sortDesc, primary_metric }) => {
  if (!show) return null;

  const ref = useRef();
  useClickOutside(ref, () => setShow(false));

  return (
    <div className="sort-dropdown" ref={ref}>
      <>
        <DropdownItem
          label="Spend descending"
          onClick={() => {
            setSort('spend', true);
            setShow(false);
          }}
        />
        <DropdownItem
          label="Spend ascending"
          onClick={() => {
            setSort('spend', false);
            setShow(false);
          }}
        />
        <DropdownItem
          label="Performance (best first)"
          onClick={() => {
            setSort(primary_metric, sortDesc);
            setShow(false);
          }}
        />
        <DropdownItem
          label="Performance (worst first)"
          onClick={() => {
            setSort(primary_metric, !sortDesc);
            setShow(false);
          }}
        />
      </>
    </div>
  );
};

export const SortOptions = ({ SortText, setSort, primary_metric }) => {
  const [show, setShow] = useState(false);
  const sortDesc = ['roas', 'ctr', 'impressions', 'results', 'clicks'].includes(
    primary_metric
  )
    ? true
    : false;

  return (
    <div className="sort-options">
      <Button appearance="raised" onClick={() => setShow((prev) => !prev)}>
        {SortText}
      </Button>
      <SortDropdown {...{ show, setShow, setSort, sortDesc, primary_metric }} />
    </div>
  );
};
