import { useReducer } from 'react';

export type CustomCategoryStateType = {
  annotatedVisuals: Map<string, {}>;
  predictedVisuals: Map<string, {}>;
};

type DisplayType = 'ccc' | 'phrase-builder';

export type QaStateType = {
  display: DisplayType;
  currentVisualIndex: number;
  categories: CustomCategoryStateType[];
  changedLabels: [];
  selectedFrameIndex: number;
};

export const INITIAL_QA_STATE = {
  display: 'ccc',
  currentVisualIndex: 0,
  categories: [],
  changedLabels: new Set([]),
  selectedFrameIndex: 0,
  changedCategoryIds: new Set([]),
};
export const SET_DATA = 'SET_DATA';
export const SET_DISPLAY = 'SET_DISPLAY';
export const SELECT_FRAME = 'SELECT_FRAME';
export const SET_CURRENT_VISUAL_INDEX = 'SET_CURRENT_VISUAL_INDEX';
export const SET_ANNOTATED_VISUALS = 'SET_ANNOTATED_VISUALS';
export const SET_PREDICTED_VISUALS = 'SET_PREDICTED_VISUALS';
export const ANNOTATE_ME_LABEL = 'ANNOTATE_ME_LABEL'; //mutually exclusive category
export const ANNOTATE_ML_LABEL = 'ANNOTATE_ML_LABEL'; //multiple labels category
export const RESET = 'RESET';

export type QaAction =
  | { type: 'SET_DATA'; annotatedVisuals: {}; predictedVisuals: {} }
  | {
      type: 'ANNOTATE_ME_LABEL';
      label: string;
      visual_hash: string;
      visual: {};
      selectedFrame: {};
    };

const setCategoriesData = (data, prevState) => {
  return data.map((category) => {
    try {
      return {
        category_id: category.category_id,
        category_name: category.category_name,
        mutually_exclusive: category.mutually_exclusive,
        labels: Object.keys(category.output_label_info ?? {}),
        annotatedVisuals: new Map(
          Object.entries(category.annotated_visuals ?? {})
        ),
        predictedVisuals: new Map(
          Object.entries(category.predicted_visuals ?? {})
        ),
      };
    } catch (e) {
      console.log('There was an error setting the category data:');
      console.log(e);
      console.log(data);
    }
  });
};

export const qaReducer = (state, action) => {
  switch (action.type) {
    case SET_DISPLAY:
      return {
        ...state,
        display: action.display,
      };
    case SET_DATA:
      return {
        ...state,
        categories: setCategoriesData(action.data, state),
        // annotatedVisuals: new Map(Object.entries(action.annotatedVisuals)),
        // predictedVisuals: new Map(Object.entries(action.predictedVisuals)),
      };
    case SET_CURRENT_VISUAL_INDEX:
      return {
        ...state,
        currentVisualIndex: action.currentVisualIndex,
        selectedFrameIndex: 0,
      };
    case SELECT_FRAME:
      return {
        ...state,
        selectedFrameIndex: action.frameIndex,
      };
    case ANNOTATE_ME_LABEL:
      const { label, visual_hash, visual, selectedFrame, category_id } = action;
      console.log({ state, action });
      return {
        ...state,
        changedLabels: new Set([...state.changedLabels, action.label]),
        changedCategoryIds: new Set([...state.changedCategoryIds, category_id]),
        // categories: updateCategory(),
        categories: annotateMutuallyExclusiveLabel({
          existingCategories: state.categories,
          category_id,
          annotatedVisuals: state.annotatedVisuals,
          label,
          visual_hash,
          visual,
          selectedFrame,
        }),
      };
    case ANNOTATE_ML_LABEL:
      return {
        ...state,
        changedLabels: new Set([...state.changedLabels, action.label]),
        changedCategoryIds: new Set([
          ...state.changedCategoryIds,
          action.category_id,
        ]),
        categories: annotateMultipleLabels({
          existingCategories: state.categories,
          category_id: action.category_id,
          // annotatedVisuals: state.annotatedVisuals,
          label: action.label,
          selection: action.selection,
          visual_hash: action.visual_hash,
          visual: action.visual,
          selectedFrame: action.selectedFrame,
        }),
      };
    case RESET:
      return {
        ...state,
        changedLabels: new Set([]),
        changedCategoryIds: new Set([]),
      };
  }
};

export const useQaState = () => {
  const [state, dispatch] = useReducer(qaReducer, INITIAL_QA_STATE);

  return [state, dispatch];
};

const annotateMutuallyExclusiveLabel = ({
  existingCategories,
  // annotatedVisuals,
  category_id,
  categoryType,
  label,
  visual_hash,
  visual,
  selectedFrame,
}) => {
  const existingCategory = existingCategories.find(
    (x) => x.category_id === category_id
  );

  if (!existingCategory) {
    console.log('Error annotating.');
    console.log({
      existingCategories,
      category_id,
      categoryType,
      label,
      visual_hash,
      visual,
      selectedFrame,
    });
  }

  const { annotatedVisuals } = existingCategory;

  const frameIndex = selectedFrame?.index || 0;

  const update: Map<string, {}> = new Map(annotatedVisuals);
  const annotatedMatch = update.get(visual_hash);

  if (
    annotatedMatch?.asset?.[frameIndex] &&
    label === annotatedMatch.asset[frameIndex].labels
  ) {
    delete update[visual_hash];
    // return update;
  } else {
    const current = annotatedMatch || visual;

    const updatedVisual = {
      ...current,
      asset: current.asset.map((asset, i) =>
        frameIndex === i
          ? {
              ...asset,
              labels: label,
            }
          : asset
      ),
    };

    update.set(visual_hash, updatedVisual);

    // return update;
  }

  return existingCategories.map((category) => {
    if (category.category_id === category_id) {
      return {
        ...category,
        annotatedVisuals: update,
      };
    }

    return category;
  });
};

const getLabels = (current, selectedFrame) => {
  let result = {};
  const labels = current?.asset?.[selectedFrame.index]?.labels;

  if (!labels) return result;

  Object.entries(labels).forEach(([key, l]) => {
    result[key] = l || {
      status: 'no',
      confidence: null,
    };
  });

  return result;
};

const annotateMultipleLabels = ({
  existingCategories,
  // annotatedVisuals,
  category_id,
  label,
  selection,
  predictedVisuals,
  visual_hash,
  visual,
  selectedFrame,
}) => {
  const existingCategory = existingCategories.find(
    (x) => x.category_id === category_id
  );

  if (!existingCategory) {
    console.log('Error annotating.');
    console.log({
      existingCategories,
      category_id,
      label,
      visual_hash,
      visual,
      selectedFrame,
    });
  }

  const { annotatedVisuals } = existingCategory;

  const frameIndex = selectedFrame?.index || 0;

  const update: Map<string, {}> = new Map(annotatedVisuals);
  const annotatedMatch = update.get(visual_hash);

  const current =
    Array.isArray(annotatedMatch?.asset) && annotatedMatch?.asset?.length
      ? annotatedMatch
      : visual;
  const labels = getLabels(current, selectedFrame);

  const updatedVisual = {
    ...current,
    asset: current.asset.map((asset, i) => {
      return frameIndex === i
        ? {
            ...asset,
            labels: {
              ...labels,
              [label]: {
                status: selection,
                confidence: null,
              },
            },
          }
        : asset;
    }),
  };

  update.set(visual_hash, updatedVisual);

  return existingCategories.map((category) => {
    if (category.category_id === category_id) {
      return {
        ...category,
        annotatedVisuals: update,
      };
    }

    return category;
  });
};
