import React from 'react';
import { RecIcon, BarGraphIcon } from '../../components/Icons';

export const DARWIN_INDEX_LOOKUP = {
  Image: '45%',
  Video: '41%',
  Carousel: '43%',
  '9:16 Ratio': '39%',
  '1:1 Ratio': '43%',
  '4:5 Ratio': '36%',
  'Primary Text Top ': '38%',
  'Primary Text Middle': '39%',
  'Primary Text Bottom': '36%',
  'Primary Text Size XL': '44%',
  'Primary Text Size LG': '46%',
  'Primary Text Size MD': '38%',
  'Primary Text Size SM': '38%',
  'Secondary Text Position Top': '45%',
  'Secondary Text Position Middle': '49%',
  'Secondary Text Position Bottom': '25%',
  '0-10%': '34%',
  '10-20%': '46%',
  '20-30%': '47%',
  '30-40%': '67%',
  'All Visuals': '40%',
  'Focal Point: Weak': '40%',
  'Focal Point: Average': '43%',
  'Focal Point: Strong': '35%',
  'Focal Point: Very Strong': '34%',
  'Focal Point: Extremely Strong': '35%',
  'No Text': '45%',
  '10 Words or Less': '36%',
  '11-20 Words': '38%',
  '20+ Words': '49%',
  'One Main Color': '37%',
  'Two Main Colors': '39%',
};

export const REC_TABS = {
  test_more: 'Test More of This',
  test_less: 'Test Less of This',
  top_performers: 'Top Performers',
  try_these: 'Try These',
};

export const LOCKED_DATES = [
  {
    viewIds: ['Ki3K0dH16iJcKOWb1Z', 'Krn2bJakmHMvDH7cLa', 'yX6F2p5T5WKMmLbEJ6'],
    accountIds: ['act_462484415951159'],
    dateRange: ['2024-01-01', '2024-04-30'],
  },
];
