import { TOP_TIER_LABELS } from './constants';
import { PRIMARY_PHRASE } from '../../../components/LabelMatrix/constants';
import { FORMAT, KEYWORD } from '../categories';

export const getAvgSuccessRate = (labels) => {
  let positiveImg = 0;
  let positiveVid = 0;
  let negativeImg = 0;
  let negativeVid = 0;

  labels.forEach((label) => {
    if (label.value === 'IMAGE+') {
      positiveImg += label.winners.length;
      negativeImg += label.losers.length;
    }
    if (label.value === 'VIDEO+') {
      positiveVid += label.winners.length;
      negativeVid += label.losers.length;
    }
  });

  return (
    ((positiveImg + positiveVid) /
      (positiveImg + positiveVid + negativeImg + negativeVid)) *
    100
  );
};

export const getRecommendations = (labels, avgSuccessRate, category) => {
  let results = [];
  const distinctLabels = new Set();
  const isTestMore = category === 'test_more';
  const isTestLess = category === 'test_less';
  let imageRate = 0;
  let videoRate = 0;

  const isValid = (label) => {
    const TTNT_CATEGORIES = [KEYWORD, FORMAT];
    if (!TTNT_CATEGORIES.includes(label.category) && label.type !== 'client_custom_category') return false;

    if (isTestMore) {
      return label.performance_percent > avgSuccessRate;
    }
    if (isTestLess) {
      return label.performance_percent < avgSuccessRate;
    }
    return false;
  };

  TOP_TIER_LABELS.forEach((topTierLabel) => {
    labels.forEach((label) => {
      if (label.value === 'IMAGE+') {
        imageRate = label.performance_percent;
      }
      if (label.value === 'VIDEO+') {
        videoRate = label.performance_percent;
      }
      if (label.value === topTierLabel) {
        if (isValid(label)) {
          results.push(label);
          distinctLabels.add(label.value);
        }
      }
    });
  });

  let rest = [];

  labels.forEach((label) => {
    if (
      isValid(label) &&
      !distinctLabels.has(label.value) &&
      label.value !== 'all_visuals' &&
      label.value !== 'new_visuals'
    ) {
      rest.push(label);
      distinctLabels.add(label.value);
    }
  });

  rest.sort((a, b) => {
    return a.synonym === b.synonym ? 0 : a.synonym ? -1 : 1;
  });

  results.push(...rest);

  const hasImage = distinctLabels.has('IMAGE+');
  const hasVideo = distinctLabels.has('VIDEO+');

  // only show either IMAGE or VIDEO in single tab, whichever has higher success rate
  if (hasImage && hasVideo) {
    results = results.filter((label) => {
      return imageRate > videoRate
        ? label.value !== 'VIDEO+'
        : label.value !== 'IMAGE+';
    });
  }

  return results;
};

function fallbackCopyTextToClipboard(text) {
  var textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand('copy');
    var msg = successful ? 'successful' : 'unsuccessful';
    console.log('Fallback: Copying text command was ' + msg);
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
}

export function copyTextToClipboard(text) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(
    function () {
      console.log('Async: Copying to clipboard was successful!');
    },
    function (err) {
      console.error('Async: Could not copy text: ', err);
    }
  );
}

export function filterLabels(recommendations, spendThreshold, creativeCount) {
  let result = [];

  recommendations.forEach((label) => {
    let newLabel = label;

    newLabel.losers = newLabel.losers.filter((visual) => {
      return visual.spend > spendThreshold && visual.asset_url.length;
    });

    newLabel.winners = newLabel.winners.filter((visual) => {
      return visual.spend > spendThreshold && visual.asset_url.length;
    });

    const visuals = [...newLabel.losers, ...newLabel.winners];

    if (visuals.length >= creativeCount) {
      result.push(newLabel);
    }
  });

  return result;
}

export const sortLabels = (tab) => (a, b) => {
  const ratingA = parseFloat(a.rating) || 1.5;
  const ratingB = parseFloat(b.rating) || 1.5;
  const isTestMore = tab === 'test-more';
  const isTestLess = tab === 'test-less';
  const categoryA = a.category || '';
  const categoryB = b.category || '';
  const typeA = a.type || '';
  const typeB = b.type || '';
  const CCC = 'client_custom_category';

  if (ratingA > ratingB) return -1;
  if (ratingA < ratingB) return 1;

  if (categoryA === PRIMARY_PHRASE && categoryB !== PRIMARY_PHRASE) return -1;
  if (categoryB === PRIMARY_PHRASE && categoryA !== PRIMARY_PHRASE) return 1;

  if (typeA === CCC && typeB !== CCC) return -1;
  if (typeB === CCC && typeA !== CCC) return 1;

  if (isTestMore) {
    if (a.performance_percent > b.performance_percent) return -1;
    if (a.performance_percent < b.performance_percent) return 1;
  }

  if (isTestLess) {
    if (a.performance_percent < b.performance_percent) return -1;
    if (a.performance_percent > b.performance_percent) return 1;
  }

  return 0;
};

export const sortTryThese = (a, b) => {
  const labelA = a.label;
  const labelB = b.label;
  
  return labelA.localeCompare(labelB, undefined, { ignorePunctuation: true }) || 0;
};
