import React from 'react';
import { useQaContext } from './context';
import { MULTIPLE_LABELS, MUTUALLY_EXCLUSIVE } from '../constants';
import { ANNOTATE_ME_LABEL, ANNOTATE_ML_LABEL } from './reducer';
import TooltipWrapper from '../VisualsList/TooltipWrapper';

import '../VisualsList/VisualsList.scss';
import '../VisualsList/FrameSelect.scss';
import Button from '../../../../components/Button';
import { useSaveCustomCategory } from './hooks';
import { useDispatch, useSelector } from 'react-redux';
import { setCustomCategoriesData } from '../../../../store/CreativeAnalytics/creativeAnalyticsSlice';

const deriveCategoryState = (category, selectedVisual) => {
  return {
    category_name: category.category_name,
    category_id: category.category_id,
    annotatedVisuals: category.annotatedVisuals,
    selectedAnnotatedVisual: category.annotatedVisuals.get(selectedVisual.id),
    selectedPredictedVisual: category.predictedVisuals.get(selectedVisual.id),
    mutually_exclusive: category.mutually_exclusive,
    labelOptions: category.labels || [],
  };
};

// const useUpdateStore = () => {
//   const { customCategoriesData = [] } = useSelector(
//     ({ CreativeAnalytics: { customCategoriesData } }) => ({
//       customCategoriesData,
//     })
//   );
//   const dispatch = useDispatch();

//   return (updatedCategories) => {
//     if (updatedCategories.length) {
//       dispatch(
//         setCustomCategoriesData(
//           [...customCategoriesData].filter(
//             (x) => !updatedCategories.includes(x.category_id)
//           )
//         )
//       );
//     }
//   };
// };

export const CategoriesEditor = () => {
  const { visualsData, qaState, qaDispatch } = useQaContext();
  const {
    currentVisualIndex,
    categories,
    selectedFrameIndex,
    changedCategoryIds,
    changedLabels,
  } = qaState;
  const saveCategory = useSaveCustomCategory();
  // const updateStore = useUpdateStore();

  if (!categories.length) return <p>No categories found on this view.</p>;

  const selectedVisual = visualsData[currentVisualIndex];

  if (!selectedVisual) {
    return <p>No visual selected.</p>;
  }

  const selectedFrame = {
    ...selectedVisual.asset[selectedFrameIndex],
    index: selectedFrameIndex,
  };
  const saveAll = () => {
    changedCategoryIds.forEach((id) => {
      const match = categories.find((category) => category.category_id === id);

      if (match) {
        const {
          category_name,
          category_id,
          annotatedVisuals,
          labelOptions,
          mutually_exclusive,
        } = deriveCategoryState(match, selectedVisual);

        saveCategory({
          visual_hash: selectedVisual.id,
          category_name,
          category_id,
          annotatedVisuals: Object.fromEntries(annotatedVisuals.entries()),
          changedLabels: Array.from(changedLabels),
          labelOptions,
          mutually_exclusive,
        });
      }
    });

    // updateStore(Array.from(changedCategoryIds));
  };

  return (
    <div className="editor visuals-list">
      <div className="d-flex justify-content-end">
        <Button
          disabled={!changedCategoryIds.size}
          appearance="secondary"
          onClick={saveAll}
        >
          Save all changes
        </Button>
      </div>

      {categories.map((category) => {
        const {
          category_name,
          category_id,
          annotatedVisuals,
          selectedAnnotatedVisual,
          selectedPredictedVisual,
          labelOptions,
          mutually_exclusive,
        } = deriveCategoryState(category, selectedVisual);

        if (!labelOptions?.length) return null;

        const save = () => {
          saveCategory({
            visual_hash: selectedVisual.id,
            category_name,
            category_id,
            annotatedVisuals: Object.fromEntries(annotatedVisuals.entries()),
            changedLabels: Array.from(changedLabels),
            labelOptions,
            mutually_exclusive,
          });
          // updateStore([category_id]);
        };

        const onSelect = (label, selection) =>
          mutually_exclusive
            ? qaDispatch({
                type: ANNOTATE_ME_LABEL,
                label,
                visual_hash: selectedVisual.id,
                visual: selectedVisual,
                category_id,
                selectedFrame,
              })
            : qaDispatch({
                type: ANNOTATE_ML_LABEL,
                label,
                visual_hash: selectedVisual.id,
                visual: selectedVisual,
                selectedFrame,
                category_id,
                selection,
              });

        return (
          <div className="category-block">
            <div className="category-block__header d-flex justify-content-between align-items-center">
              <h3 className="category-block__title">{category_name}</h3>
              <Button
                appearance="raised"
                onClick={save}
                disabled={!changedCategoryIds.has(category_id)}
              >
                Save
              </Button>
            </div>
            <LabelsEditor
              category_name={category_name}
              labelOptions={labelOptions}
              mutually_exclusive={mutually_exclusive}
              current={selectedVisual}
              visual_hash={selectedVisual.id}
              selectedAnnotatedVisual={selectedAnnotatedVisual}
              selectedPredictedVisual={selectedPredictedVisual}
              onSelect={onSelect}
              selectedFrame={selectedFrame}
            />
          </div>
        );
      })}
    </div>
  );
};

const LabelsEditor = ({
  category_name,
  labelOptions,
  mutually_exclusive,
  current,
  visual_hash,
  selectedAnnotatedVisual,
  selectedPredictedVisual,
  onSelect,
  selectedFrame = {},
}) => {
  return (
    <div className="label-select-wrapper">
      <ul className="label-select">
        {labelOptions.map((label) => {
          if (mutually_exclusive) {
            return (
              <MutuallyExclusive
                {...{
                  label,
                  onSelect,
                  current,
                  selectedAnnotatedVisual,
                  visual_hash,
                  selectedFrame,
                  selectedPredictedVisual,
                }}
              />
            );
          }

          return (
            <MultipleLabels
              {...{
                label,
                onSelect,
                current,
                selectedAnnotatedVisual,
                visual_hash,
                selectedFrame,
                selectedPredictedVisual,
              }}
            />
          );
        })}
      </ul>
    </div>
  );
};

const MutuallyExclusive = ({
  label,
  onSelect,
  current,
  selectedAnnotatedVisual,
  visual_hash,
  selectedFrame,
  selectedPredictedVisual,
}) => {
  const prediction_match =
    selectedPredictedVisual?.asset?.[selectedFrame?.index]?.labels || null;
  const confidence =
    selectedPredictedVisual?.asset?.[selectedFrame?.index]?.confidence || null;
  const predictedLabel = (confidence && prediction_match) || null;
  let isPredicted = predictedLabel === label;
  let isSelected = null;

  if (confidence && predictedLabel === label) {
    isSelected = true;
  }

  if (selectedAnnotatedVisual) {
    if (selectedAnnotatedVisual.asset?.[selectedFrame?.index]) {
      isSelected =
        selectedAnnotatedVisual.asset[selectedFrame.index].labels === label;
    } else if (selectedAnnotatedVisual.labels) {
      isSelected = selectedAnnotatedVisual.labels === label;
    }
  }

  return (
    <TooltipWrapper {...{ isSelected, isPredicted, confidence }}>
      <li
        className={`visuals-list__single-label ${
          isSelected ? 'visuals-list__single-label--selected' : ''
        }
          ${
            isSelected && isPredicted
              ? 'visuals-list__single-label--predicted'
              : ''
          }
        `}
        onClick={() => onSelect(label)}
      >
        <div className="visuals-list__circle"></div>
        <span title={label}>{label}</span>
      </li>
    </TooltipWrapper>
  );
};

const MultipleLabels = ({
  label,
  onSelect,
  current,
  selectedAnnotatedVisual,
  visual_hash,
  selectedFrame,
  selectedPredictedVisual,
}) => {

  const prediction_match =
    selectedPredictedVisual?.asset?.[selectedFrame.index]?.labels?.[label] || null;

  const confidence =
    (!!selectedPredictedVisual && prediction_match?.confidence) || null;
  const prediction = (confidence && prediction_match?.status) || null;
  const annotated_match =
    selectedAnnotatedVisual?.asset[selectedFrame.index]?.labels?.[label];

  let selected = null;

  if (confidence && !annotated_match) {
    selected = prediction;
  } else if (annotated_match) {
    try {
      selected = annotated_match.status || 'no_label';
    } catch (e) {
      console.error(e);
    }
  }

  const handleClick = (selection) => {
    onSelect(label, selection);
  };

  const predictedNo = prediction === 'no';
  const predictedNoLabel =
    prediction && prediction !== 'yes' && prediction !== 'no';
  const predictedYes = prediction === 'yes';

  const selectedNo = selected === 'no';
  const selectedNoLabel = selected === 'no_label' || !selected;
  const selectedYes = selected === 'yes';

  const Option = ({ value, children, isSelected, isPredicted }) => {
    let color = '#33B6FF';
    if (isPredicted) color = '#94DB88';

    let style = {};

    if ((isPredicted || isSelected) && value === 'no_label') {
      style = {
        borderLeft: color,
        borderRight: color,
      };
    }

    return (
      <TooltipWrapper {...{ isSelected, isPredicted, confidence }}>
        <div
          className={`multiple-label ${
            isSelected ? 'multiple-label--selected' : ''
          }
          ${isSelected && isPredicted ? 'multiple-label--predicted' : ''}
        `}
          style={style}
          onClick={() => handleClick(value)}
        >
          {children}
        </div>
      </TooltipWrapper>
    );
  };

  return (
    <li className="multiple-options">
      <h3 className="multiple-options__heading">{label}</h3>
      <div className="multiple-options__select">
        <Option value="no" isSelected={selectedNo} isPredicted={predictedNo}>
          No
        </Option>
        <Option
          value="no_label"
          isSelected={selectedNoLabel}
          isPredicted={predictedNoLabel}
        >
          N/A
        </Option>
        <Option value="yes" isSelected={selectedYes} isPredicted={predictedYes}>
          Yes
        </Option>
      </div>
    </li>
  );
};
