/* eslint-disable arrow-body-style */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import _ from 'lodash';
import { ErrorBoundary } from '../../components/Helpers/Error';
import { useDispatch, useSelector } from 'react-redux';
import {
  ADSET_NAME,
  AD_NAME,
  CLUSTERS,
  COPILOT,
  COPY,
  DASHBOARD,
  HURDLE,
  LIBRARY,
  PERFORMANCE_FREQUENCY,
  PHRASE_THEME,
  RECOMMENDATIONS,
  TRENDING,
  VARIATION,
} from '../../store/CreativeReports/constants';
import PerformanceFrequency from './PerformanceFrequency';
import TryThese from './TryThese';
import { useCreativeAnalyticsApis } from './api';
import {
  useAdNameLabelsApi,
  useGetAdNameLabelsApi,
} from './CategoryBuilder/Custom/AdNameLabels/api';
import {
  useAnalyticsParams,
  useStandardLabels,
  useElementSelect,
  useDatePeriods,
} from './hooks';
import { setReports } from '../../store/CreativeReports/actions';
import { useGetDnaTableApi } from './api';
import { darwinDateToDate } from '../../utils/darwin-dates';
import './CreativeAnalytics.scss';
import AsyncCCCProcess from './CategoryBuilder/AsyncCCCProcess';
import TopPerformers from './TopPerformers';
import Header from './Header/Header';
import CategoryBuilder from './CategoryBuilder';
import ReportCard from './ReportCard';
import {
  CREATIVE_ANALYTICS,
  OPEN_CREATIVE_REQUEST_DRAWER,
} from '../../store/UserActivity/constants';
import CreativeRequest from './CreativeRequest';
import { PRIMARY_PHRASE } from '../../components/LabelMatrix/constants';
import { CreativeRequestProvider } from './CreativeRequest/contexts';
import { CreativeAnalyticsProvider } from './contexts';
import LeftDisplay from './LeftDisplay';
import { getCol, useColumns } from './PerformanceFrequency/hooks';
import {
  findMaxSquares,
  sortCompareData,
  getVisualsLookup,
  getCategoriesLookup,
  findLabelsByVisual,
  getAllVisuals,
  getAdNames,
  getAllVisualsAndAds,
} from './utils';
import useCategoryFilters from './hooks/useCategoryFilters';
import { trackEvent } from '../../store/UserActivity/actions';
import { useUrlParams } from './hooks/useUrlParams';
import { LabelObject, MissingDataType, RightDrawerOptions } from './types';
import { CategoriesLookup, VisualsLookup } from './ReportCard/types';
import VisualsLibrary from './VisualLibrary/VisualsLibrary';
import { useUDCApi } from './CategoryBuilder/Custom/UDC/api';
import { usePortals } from './hooks/usePortals';
import Tooltip from '../../components/Tooltip';
import LibraryAnalytics from './LibraryAnalytics/Wrapper';
import { useColumnDropdownOptions } from './PerformanceFrequency/Columns';
import { useCustomEvents, useCustomMetrics } from './selectors';
import InsightsDashboard from '../InsightsDashboard/InsightsDashboard';
import LabelsSetup from './BasicLabelsSetup/BasicLabelsSetup';
import { useSearchTermsApi } from './BasicLabelsSetup/api';
import { useLibraryAnalyticsState } from './LibraryAnalytics/hooks';
import { useSearchParams } from '../../components/Hooks/search-params';
import {
  setCategoriesLookup,
  setRightDrawerDisplay,
  setStoredFilteredCompareData,
} from '../../store/CreativeAnalytics/creativeAnalyticsSlice';
import { PHRASE_BUILDER, REPORT_CARD } from './ReportCard/constants';
import { useDerivedLabelsApi } from './CategoryBuilder/Custom/DerivedLabels/api';
import ActionButtons from './ActionButtons/ActionButtons';
import { CreativeRequestToggle } from './CreativeRequest/Toggle';
import { LibraryAnalyticsToggle } from './LibraryAnalytics/Toggle';
import { DashboardEditModeToggle } from '../InsightsDashboard/DashboardEditModeToggle';
import CategoryEditor from './ReportCard/CategoryEditor';
import { useClickOutside } from '../../hooks/useClickOutside';
import { ClickAwayListener } from '@mui/material';
import './ActionButtons/ActionButtons.scss';
import { hasRoles } from '../../cookies';
import BoxPlot from './BoxPlot';
import { useExplorerState } from './Explorer/hooks/useExplorerState';
import Explorer from './Explorer';
import ExplorerContextProvider from './Explorer/contexts/explorerContext';
import { CategoryBuilderProvider } from './CategoryBuilder/contexts';
import Overlay from '../../components/Overlay';
import Modal from '../../components/Modal';
import { useReportCardPrefs } from './hooks/useReportCardPrefs';
import { useEventTracking } from './hooks/useEventTracking';
import { useKeywords } from './hooks/useKeywords';
import { usePerformanceData } from './hooks/usePerformanceData';
import { useInit } from './hooks/useInit';
import { useDnaTable } from './hooks/useDnaTable';
import { useReportRefresh } from './hooks/useReportRefresh';
import { useNewAds } from './hooks/useNewAds';
import { useMissingSpend } from './hooks/useMissingSpend';
import { useLabelSetup } from './hooks/useLabelSetup';
import { useDerivedLabelsDelete } from './hooks/useDerivedLabelsDelete';
import { getCpasList } from './utils/getCpasList';
import { getPrimaryPhraseLabels } from './utils/getPrimaryPhraseLabels';
import Settings from './Settings';
import IconButton from '../../components/IconButton';
import { MoreSettings } from './Settings/MoreSettings';
import Button from '../../components/Button';
import { RefreshReport } from './Settings/RefreshReport';
import { useLoading, useViewSettings } from '../../selectors';
import Recommendations from './Recommendations';
import { useRecommendationsStatus } from './Recommendations/api';
import DNATable from '../DNA';
import Dashboard from '../InsightsDashboard';
import CreativeReports from '../CreativeReports';
import Scatter from './Scatter';
import { RightDrawerWrapper } from './RightDrawer';
import MissingData from './MissingData';
import { Banner } from './Banner';
import PhraseTheme from './PhraseTheme';
import ActiveIcon from '../../components/Icons/ActiveIcon';
import BlockReport from '../CreativeReports/BlockReport';
import ImpactReport from './ImpactReport';
import _isEqual from 'lodash/isEqual';
import { lastMonthEnd, lastMonthStart } from './ImpactReport/constants';
import { GlobalKeywordSettings } from './Settings/GlobalKeywordSettings';
import Toggle from '../../components/BeagleToggle';
import Clusters from './Clusters';
import SuccessConditions from './SuccessConditions';
import { useCustomScoringApi, useMetadataApi } from '../DNA/api';
import Dna from '../DNA';
import { useLabelSelection } from './hooks/useLabelSelection';
import { usePhraseThemeData } from './hooks/usePhraseThemeData';
import { deriveUdcLabels } from './utils/deriveUdcLabels';
import { useFilteredData } from './hooks/useFilteredData/useFilteredData';
import Variation from './Variation';
import Hurdle from './Hurdle';
import Copilot from './CreativeCopilot';
import { checkIfVisualIsSelected } from './hooks/useFilteredData/utils/filterVisuals';
import { addMetrics } from '../DNA/PeriodComparison/utils';
import { getRows } from '../DNA/utils';
import Copy from './CopyReport';
import { useShareStaticReport } from './hooks/useShareStaticReport';
import { ShareReport } from '../DNA/ShareReport';
import { QA, useShowQaEditor } from './CategoryBuilder/QA';
import { useSetShowQaEditor } from './LeftDisplay/components/Menu/hooks';
import { HideLabels } from './Settings/HideLabels';
import {
  PlanningFillIcon,
  PlanningNoFillIcon,
} from './PerformanceFrequency/icons';
import { TrendingIcon } from './LeftDisplay/icons/trending';
import { Bar } from './LeftDisplay/icons/bar';
import { usePermissions } from '../../components/Permissions/usePermissions';
import useTrackDeps from '../../hooks/useTrackDeps';
import { GROUP_BY_AD_NAME } from '../../components/Permissions/constants';

type CreativeAnalyticsProps = {
  view_id: string;
  viewName: string;
  account_id: string;
  start_date: string;
  end_date: string;
  selectedAudience: string;
  only_new_ads: boolean;
  insights_by_asset: boolean;
  show_request: boolean;
  isDirectMail: boolean;
  actionName: string;
  mode: 'library' | 'analytics';
  gaEnabled: boolean;
  isNonPaidMediaType: boolean;
  isUltraLight: boolean;
  rightDrawerDisplay: RightDrawerOptions;
  missingData: MissingDataType;
  customIntegratedMetrics: [];
};

const CreativeAnalytics = ({
  view_id,
  viewName,
  account_id,
  only_new_ads,
  insights_by_asset,
  show_request,
  isDirectMail,
  actionName,
  mode = 'analytics',
  gaEnabled,
  isNonPaidMediaType,
  isUltraLight,
  rightDrawerDisplay,
  customIntegratedMetrics,
  selectedAudience: storedAudience,
  isOrganicView,
}: CreativeAnalyticsProps) => {
  const dispatch = useDispatch();
  const [{ period1, period2, rc, refresh }, setParams] = useAnalyticsParams();
  const { setParams: setSearchParams, getParams } = useSearchParams();
  const isROAS = actionName && actionName.value?.includes('value_');
  const isCompetitorView =
    isDirectMail && viewName.toLowerCase().includes('competitor');
  const [editCategory, setEditCategory] = useState(null);
  const [editCategoryVisual, setEditCategoryVisual] = useState(null);
  const {
    spendThreshold,
    creativeCount,
    recDepth,
    fixedLabels,
    sort,
    min,
    primary_metric: primaryMetricParam,
    category,
    display,
  } = useUrlParams(isROAS, isOrganicView);
  const {
    newAdsApi,
    asyncApi,
    postRefreshApi,
    refreshStatusApi,
    missingSpendApi,
    getUdcApi,
    revisionRequestApi,
    keywordsApi,
    reportCardPrefsApi,
    getDerivedLabelsApi,
    presetsApi,
    clustersApi,
  } = useCreativeAnalyticsApis();
  const libraryAnalyticsState = useLibraryAnalyticsState();
  const [fullData, setFullData] = useState<LabelObject[] | null>(null);
  const { categoryFilters, dispatchCategoryFilter } = useCategoryFilters(mode);
  const [
    standardLabels,
    trending,
    executionStyles,
    inspiration,
    categoryLabelOptions,
  ] = useStandardLabels(account_id);
  const [recsTab, setRecsTab] = useState('test_more');
  const [TTNTSections, setTTNTSections] = useState(null);
  const {
    ActionButtonsPortal,
    CreativeRequestPortal,
    ReportCardPortal,
    LibraryAnalyticsPortal,
  } = usePortals();
  const [period1Start, period1End, period2Start, period2End] = useDatePeriods(
    period1,
    period2
  );

  const [clientMetrics, setClientMetrics] = useState<string[]>([]);
  const [showActiveOnly, setShowActiveOnly] = useState(false);
  const fromCreativeStudio = false;
  const dnaTableApi = useGetDnaTableApi();
  const {
    reportCardDataType,
    setReportCardDataType,
    reportCardMetric,
    setReportCardMetric,
    reportCardSettings,
    fetchCustomSettings,
    dispatchReportCardSettings,
    loadingRcSettings,
  } = useReportCardPrefs(view_id, isROAS, reportCardPrefsApi);
  const { newAds, onlyNewAds, setOnlyNewAds } = useNewAds(newAdsApi);
  const [requestOpen, setRequestOpen] = useState(false);
  const [rcCategorySettings, setRcCategorySettings] = useState([]);
  const customEvents = useCustomEvents();
  const {
    additional_metrics,
    darwin_client_id,
    pull_live_metrics,
    label_queue,
    isEmailView,
    isCompressionView,
  } = useViewSettings();
  const [libraryView, setLibraryView] = useState('library');
  const searchTermsApi = useSearchTermsApi();
  const [reportCardMode, setReportCardMode] = useState(REPORT_CARD);
  const [openedMenu, setOpenedMenu] = useState(null);
  const [activeBoxPlotCategory, setActiveBoxPlotCategory] = useState(null);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const ref = useRef();
  const explorerState = useExplorerState();
  const getAdNameLabelsApi = useGetAdNameLabelsApi();
  const recommendationsStatus = useRecommendationsStatus();
  const params = getParams(
    'audience',
    'min',
    'labels',
    'group',
    'columns',
    'dna_columns',
    'udc_labels'
  );
  const selectedAudience = storedAudience || params.audience || '';
  const resultsThreshold = params.min || 0;
  const labelsParam = params.labels || '';
  const groupLabels = params.group || 'no';
  const columnsParam = params.columns || '';
  const dnaColumnsParam = params.dna_columns || '';
  const udcLabelsParam = params.udc_labels || '';
  const { audienceOptions, metricsData, metadata, custom_performance_scores } =
    useSelector(
      ({
        ViewSettings: { audience, custom_performance_scores },
        CreativeAnalytics: { metricsData, dnaReportData },
      }) => {
        return {
          audienceOptions: audience || [],
          metricsData: metricsData || [],
          metadata: dnaReportData,
          custom_performance_scores,
        };
      }
    );
  const [defaultPfrColumns, setDefaultPfrColumns] = useState(null);
  const [defaultDnaColumns, setDefaultDnaColumns] = useState(null);
  const DEFAULT_START = viewName.toLowerCase().split(' ').includes('demo')
    ? new Date('January 1, 2010')
    : lastMonthStart;
  const defaultDates = [DEFAULT_START, lastMonthEnd];
  const [clustersData, setClustersData] = useState([]);
  const [impactCategories, setImpactCategories] = useState([]);
  const [showQaEditor, setShowQaEditor] = useShowQaEditor();
  const [planningMode, setPlanningMode] = useState(false);
  const [coreVisuals, setCoreVisuals] = useState({
    0: { urls: [], types: [], html: '' },
    1: { urls: [], types: [], html: '' },
    2: { urls: [], types: [], html: '' },
    3: { urls: [], types: [], html: '' },
  });
  const defaultRequestWidth = 580;
  const [requestWidth, setRequestWidth] = useState(defaultRequestWidth);
  const [customScoringData, setCustomScoringData] = useState([]);

  useEffect(() => {
    if (!requestOpen && planningMode) setRequestOpen(true);
    if (requestOpen && !planningMode) setRequestOpen(false);
  }, [planningMode]);

  useEffect(() => {
    if (!requestOpen) {
      setRequestWidth(0);
    } else {
      setRequestWidth(defaultRequestWidth);
    }
  }, [requestOpen]);

  const udc_labels = useMemo(() => {
    return deriveUdcLabels(udcLabelsParam);
  }, [udcLabelsParam]);

  const [columns, handleColumns] = useColumns({
    isROAS,
    primary_metric: primaryMetricParam,
    display,
    isOrganicView,
    columnsParam,
    dnaColumnsParam,
    setDefaultPfrColumns,
    setSearchParams,
    planningMode,
  });

  const primary_metric = primaryMetricParam.replace('median_', '');

  const toggleGroupByRatio = () => {
    if (!groupLabels || groupLabels === 'no') {
      setSearchParams({ group: 'yes' });
    } else {
      setSearchParams({ group: 'no' });
    }
  };

  const shouldGroupLabelsByRatio = useMemo(() => {
    if (groupLabels === 'yes') return true;
    return false;
  }, [groupLabels]);

  const performanceDataProps = {
    account_id,
    period1Start,
    period1End,
    period2Start,
    period2End,
    getUdcApi,
    keywordsApi,
    getDerivedLabelsApi,
    getAdNameLabelsApi,
    fetchCustomSettings,
    showActiveOnly,
    spendThreshold,
    only_new_ads,
    newAds,
    minResults: min,
    shouldGroupLabelsByRatio,
    customIntegratedMetrics,
    clientMetrics,
    additional_metrics,
    primary_metric,
    labelsParam,
    udc_labels,
    selectedAudience,
    refresh,
    loadingRcSettings,
  };

  const {
    data,
    compareData,
    error,
    loading,
    loadingCompare,
    fetchPerformanceData,
    fetchCompareData,
    selectedLabels,
    visualIdsFromSelectedLabels,
    // allVisualsLabel,
    allVisualsList,
    showLoading,
    retrievedDate,
  } = usePerformanceData({
    performanceDataProps,
  });
  useInit({
    view_id,
    insights_by_asset,
    fetchPerformanceData,
    fetchCompareData,
    period1Start,
    period1End,
    period1,
    period2,
    period2Start,
    period2End,
    newAdsApi,
  });

  useClickOutside(ref, () => {
    if (openedMenu && ref.current) {
      setOpenedMenu(null);
    }
  });

  const isUBeauty = account_id === 'act_478482719415309';
  useEffect(() => {
    if (isUBeauty) {
      setSearchParams({
        spendThreshold: spendThreshold ?? 500,
        primary_metric: 'cp_Rockerbox_Conversions',
      });
    }
  }, [isUBeauty]);

  // const { phraseThemeDataApi, organizePhraseThemesApi, phraseThemeData } =
  //   usePhraseThemeData({ view_id, darwin_client_id });

  useEffect(() => {
    if (isOrganicView) {
      const primary = actionName.name || clientMetrics[0]; // in case actionName is empty
      setSearchParams({ spendThreshold: 1, primary_metric: primary });
    }
  }, [isOrganicView, clientMetrics]);

  useEffect(() => {
    if (getAdNameLabelsApi.data) {
      try {
        if (!Object.keys(getAdNameLabelsApi.data.ad_name_labels).length) {
          reportCardSettings.deleteCategoryFromSettings('Ad Name Labels');
        }
      } catch (e) {
        console.error(e);
      }
    }
  }, [getAdNameLabelsApi.data]);

  const [dashboardEditMode, setDashboardEditMode] = useState(false);

  useEffect(() => {
    if (dashboardEditMode) {
      setRequestOpen(false);
      libraryAnalyticsState.setOpenLibraryAnalytics(false);
    }
  }, [dashboardEditMode]);

  useEffect(() => {
    dispatch(setReports({ show_request: requestOpen }));
  }, [requestOpen]);

  const columnDropdownOptions = useMemo(() => {
    return useColumnDropdownOptions({
      isROAS,
      isDirectMail,
      clientMetrics,
      gaEnabled,
      customEvents,
      additional_metrics,
      customIntegratedMetrics,
      isOrganicView,
      account_id,
      isEmailView,
      isCompressionView,
      customScoringData,
    });
  }, [
    isROAS,
    isDirectMail,
    clientMetrics,
    gaEnabled,
    customEvents,
    additional_metrics,
    customIntegratedMetrics,
    isOrganicView,
    account_id,
    isEmailView,
    isCompressionView,
    customScoringData,
  ]);

  const udcApi = useUDCApi(
    setEditCategory,
    fetchPerformanceData,
    reportCardSettings
  );

  const derivedLabelsApi = useDerivedLabelsApi(fetchPerformanceData);

  useEventTracking(libraryView, mode);

  useEffect(() => {
    if (editCategory) setEditCategoryVisual(null);
  }, [editCategory]);

  const [
    {
      filteredData,
      allVisualsLabel,
      filteredAllVisuals: filteredVisuals,
      totalBudget,
    },
    { filteredCompareData },
  ] = useFilteredData({
    data,
    standardLabels,
    newAds,
    spendThreshold,
    creativeCount,
    categoryFilters,
    visualIdsFromSelectedLabels,
    isDirectMail,
    isROAS,
    min,
    isOrganicView,
    isCompetitorView,
    primary_metric,
    viewName,
    // phraseThemeData,
    clustersData,
    customScoringData,
  });

  const filteredAllVisualsLabel = filteredData.find(
    (label) => label.value === 'all_visuals'
  );
  const all_visuals = filteredAllVisualsLabel?.visuals ?? null;
  const { custom_events } = useCustomMetrics();

  const { keywordsData, existingKeywords, allKeywords } = useKeywords({
    filteredData,
    fullData,
    keywordsApi,
  });

  const { handleSelectLabel, handleFilter } = useLabelSelection({
    selectedLabels,
    dispatchCategoryFilter,
    mode,
    fetchPerformanceData,
    selectedAudience,
    labelsParam,
    data,
    filteredData,
    audienceOptions,
    setSearchParams,
    setParams,
    params,
  });

  const { ExportButton } = useDnaTable({
    view_id,
    account_id,
    period1Start,
    period1End,
    dnaTableApi,
  });

  const cpasList = useMemo(
    () => getCpasList(filteredData, isROAS),
    [filteredData]
  );

  const allVisuals = useMemo(() => {
    if (!allVisualsLabel) return [];
    return getAllVisuals(filteredData, allVisualsLabel);
  }, [filteredData, allVisualsLabel]);

  const p2Visuals = useMemo(() => {
    if (filteredCompareData) {
      return getAllVisualsAndAds(filteredCompareData);
    }
  }, [filteredCompareData]);

  const [selected, handleSelectElement] = useElementSelect(
    period1,
    period2,
    filteredData,
    allVisuals,
    p2Visuals,
    isROAS,
    totalBudget,
    spendThreshold,
    isOrganicView,
    clientMetrics,
    primary_metric
  );

  useEffect(() => {
    if (filteredCompareData.length)
      dispatch(setStoredFilteredCompareData(filteredCompareData));
  }, [filteredCompareData.length]);

  const labelsByVisual = useMemo(() => {
    return findLabelsByVisual(filteredData);
  }, [filteredData]);

  const categoriesLookup: CategoriesLookup = useMemo(
    () =>
      getCategoriesLookup({
        filteredData,
        isROAS,
        isDirectMail,
        reportCardDataType,
        viewName,
        reportCardMetric: reportCardSettings.selected_metric,
        spendThreshold,
      }),
    [filteredData, reportCardDataType]
  );

  const metricOptions = reportCardSettings.metric_options;

  const { categorySettings } = reportCardSettings;

  const adNameLabelsApi = useAdNameLabelsApi(
    fetchPerformanceData,
    getAdNameLabelsApi,
    account_id
  );

  useDerivedLabelsDelete(fetchPerformanceData, reportCardSettings);

  useEffect(() => {
    if (Object.keys(categoriesLookup).length > 0) {
      dispatch(setCategoriesLookup(categoriesLookup));
    }
  }, [Object.keys(categoriesLookup).length]);

  const visualsLookup: VisualsLookup = useMemo(
    () =>
      getVisualsLookup(filteredData, categoriesLookup, labelsByVisual, false),
    [filteredData, categoriesLookup, labelsByVisual]
  );

  const filteredSearchLookup = useMemo(() => {
    return filteredData.reduce((arr, label) => {
      const maybeCategory = categorySettings?.find(
        (c) => c.category_name === label.category
      );
      const isVisible =
        maybeCategory?.enabled.report_card && label.visuals?.length > 0;

      if (isVisible) {
        arr.push({
          name: label.name,
          label: `${label.category} -> ${label.name}`,
          value: label.value,
          labelObj: label,
          category: label.category,
          visuals: [...label.visuals],
        });
      }

      return arr;
    }, []);
  }, [filteredData, categorySettings]);

  const primaryPhraseLabels = useMemo(
    () => getPrimaryPhraseLabels(data),
    [data]
  );

  const maxSquaresLookup = useMemo(() => {
    if (loadingCompare) return null;
    if (filteredData.length && filteredCompareData.length) {
      return findMaxSquares(filteredData, filteredCompareData);
    }
    return null;
  }, [loadingCompare, filteredData, filteredCompareData]);

  const sortedCompareData = useMemo(() => {
    return sortCompareData(filteredData, filteredCompareData);
  }, [filteredData, filteredCompareData]);

  const compareCategoriesLookup: CategoriesLookup = useMemo(
    () =>
      getCategoriesLookup({
        filteredData: sortedCompareData,
        isROAS,
        isDirectMail,
        reportCardDataType,
        viewName,
        reportCardMetric: reportCardSettings.selected_metric,
        spendThreshold,
      }),
    [sortedCompareData, reportCardDataType]
  );

  const { handleRefreshReport } = useReportRefresh(
    account_id,
    postRefreshApi,
    refreshStatusApi
  );
  const analyticsComp = () => {
    switch (display) {
      case RECOMMENDATIONS:
        return TopPerformers;
      case PHRASE_THEME:
        return PhraseTheme;
      case 'dna_table':
        return null;
      case ADSET_NAME:
      case AD_NAME:
        return CreativeReports;
      case DASHBOARD:
        return Dashboard;
      case 'block_report':
        return BlockReport;
      case LIBRARY:
        return VisualsLibrary;
      case 'impact_report':
        return ImpactReport;
      case CLUSTERS:
        return Clusters;
      case VARIATION:
        return Variation;
      case HURDLE:
        return Hurdle;
      case COPILOT:
        return Copilot;
      case COPY:
        return Copy;
      default:
        return PerformanceFrequency;
    }
  };

  let Comp = null;
  const isDashboard = mode === LIBRARY && libraryView === 'insights';

  switch (mode) {
    case 'analytics':
      Comp = analyticsComp();
      break;
    case LIBRARY:
      Comp = isDashboard ? InsightsDashboard : VisualsLibrary;
      break;
    default:
      Comp = PerformanceFrequency;
  }

  if (!insights_by_asset) {
    Comp = PerformanceFrequency;
  }

  const defaultAnalyticsTableCategory = useMemo(() => {
    try {
      if (filteredData.some((x) => x.category?.trim() === 'Message Theme')) {
        return 'Message Theme';
      } else {
        return 'Format';
      }
    } catch (e) {
      console.error(e);

      return 'Format';
    }
  }, [filteredData]);
  const handleClickAway = () => {
    setOpenedMenu(false);
  };

  const enableCreativeRequest =
    insights_by_asset && reportCardMode !== PHRASE_BUILDER;
  const enableLibraryAnalytics =
    !requestOpen && reportCardMode !== PHRASE_BUILDER;
  const isPF =
    Comp === PerformanceFrequency || Comp === DNATable || Comp === PhraseTheme;

  useMissingSpend({
    data,
    view_id,
    isPF,
    missingSpendApi,
    start_date: period1Start,
    end_date: period1End,
    pull_live_metrics,
    label_queue,
  });

  const {
    sharedReportId,
    handleSharePfr,
    shouldShowShareModal,
    closeShareModal,
    submitShareStaticPfr,
    loadingSharePfr,
  } = useShareStaticReport({
    columnOptions: columnDropdownOptions,
    filteredData,
    filteredVisuals,
    reportCardSettings,
    columns,
    selectedLabels,
    period1Start,
    period1End,
    primary_metric,
    clientMetrics,
    category,
  });

  const {
    searchTermsData,
    searchTermsLoading,
    searchTermsError,
    LabelSetupIcon,
    editBasicLabels,
    setEditBasicLabels,
  } = useLabelSetup({
    account_id,
    filteredData,
    fullData,
    setFullData,
    searchTermsApi,
  });

  let RightDrawer = null;

  switch (rightDrawerDisplay) {
    case 'recommendations':
      RightDrawer = <Recommendations />;
      break;
    case 'scatter':
      RightDrawer = <Scatter />;
      break;
    case 'missing_data':
      RightDrawer = <MissingData />;
      break;
    default:
      RightDrawer = null;
      break;
  }

  const hasPermission = usePermissions();
  const hideTrending =
    !hasPermission(TRENDING) ||
    isUltraLight ||
    isOrganicView ||
    viewName.includes('Email');

  const isGoHealth = account_id === 'act_637485863314808';

  if (error) {
    return <h3>There was an error. {error.toString()}</h3>;
  }

  return (
    <ErrorBoundary>
      <CreativeAnalyticsProvider
        account_id={account_id}
        data={data}
        filteredData={filteredData}
        filteredCompareData={filteredCompareData}
        allVisualsList={allVisualsList}
        sortedCompareData={sortedCompareData}
        categoriesLookup={categoriesLookup}
        compareCategoriesLookup={compareCategoriesLookup}
        asyncApi={asyncApi}
        display={display}
        category={category}
        spendThreshold={spendThreshold}
        creativeCount={creativeCount}
        period1={period1}
        period2={period2}
        start_date={darwinDateToDate(period1Start)}
        end_date={darwinDateToDate(period1End)}
        newAds={newAds}
        insights_by_asset={insights_by_asset}
        onlyNewAds={onlyNewAds}
        primaryPhraseLabels={primaryPhraseLabels}
        categoryLabelOptions={categoryLabelOptions}
        selected={selected}
        handleSelectElement={handleSelectElement}
        editCategory={editCategory}
        editCategoryVisual={editCategoryVisual}
        maxSquaresLookup={maxSquaresLookup}
        isROAS={isROAS}
        setEditCategory={setEditCategory}
        setEditCategoryVisual={setEditCategoryVisual}
        categoryFilters={categoryFilters}
        selectedLabels={selectedLabels}
        setParams={setParams}
        clientMetrics={clientMetrics}
        setClientMetrics={setClientMetrics}
        isDirectMail={isDirectMail}
        getUdcApi={getUdcApi}
        derivedLabelsApi={derivedLabelsApi}
        getDerivedLabelsApi={getDerivedLabelsApi}
        allVisuals={allVisuals}
        filteredAllVisualsLabel={filteredAllVisualsLabel}
        cpasList={cpasList}
        reportCardDataType={reportCardDataType}
        reportCardMetric={reportCardMetric}
        metricOptions={metricOptions}
        reportCardSettings={reportCardSettings}
        columnDropdownOptions={columnDropdownOptions}
        customEvents={customEvents}
        editBasicLabels={editBasicLabels}
        setEditBasicLabels={setEditBasicLabels}
        rcCategorySettings={rcCategorySettings}
        requestOpen={requestOpen}
        setRequestOpen={setRequestOpen}
        visualsLookup={visualsLookup}
        activeBoxPlotCategory={activeBoxPlotCategory}
        setActiveBoxPlotCategory={setActiveBoxPlotCategory}
        resultsThreshold={resultsThreshold}
        isNonPaidMediaType={isNonPaidMediaType}
        isUltraLight={isUltraLight}
        isOrganicView={isOrganicView}
        fetchPerformanceData={fetchPerformanceData}
        fetchCompareData={fetchCompareData}
        libraryView={libraryView}
        selectedAudience={selectedAudience}
        primary_metric={primary_metric}
        mode={mode}
        viewName={viewName}
        // phraseThemeDataApi={phraseThemeDataApi}
        // organizePhraseThemesApi={organizePhraseThemesApi}
        // phraseThemeData={phraseThemeData}
        pull_live_metrics={pull_live_metrics}
        presetsApi={presetsApi}
        shouldGroupLabelsByRatio={shouldGroupLabelsByRatio}
        defaultPfrColumns={defaultPfrColumns}
        defaultDnaColumns={defaultDnaColumns}
        defaultDates={defaultDates}
        searchTermsData={searchTermsData}
        isEmailView={isEmailView}
        setClustersData={setClustersData}
        clustersApi={clustersApi}
        min={min}
        loading={loading}
        dnaColumnsParam={dnaColumnsParam}
        impactCategories={impactCategories}
        setImpactCategories={setImpactCategories}
        columns={columns}
        handleColumns={handleColumns}
        planningMode={planningMode}
        setPlanningMode={setPlanningMode}
        coreVisuals={coreVisuals}
        setCoreVisuals={setCoreVisuals}
        requestWidth={requestWidth}
        setRequestWidth={setRequestWidth}
      >
        <CategoryBuilderProvider
          {...{
            data,
            fetchPerformanceData,
            getUdcApi,
            udcApi,
            derivedLabelsApi,
            getDerivedLabelsApi,
            allKeywords,
            getAdNameLabelsApi,
            adNameLabelsApi,
            setEditCategory,
            setEditCategoryVisual,
          }}
        >
          <ExplorerContextProvider {...{ explorerState }}>
            <CreativeRequestProvider>
              <div className="analytics">
                {!!shouldShowShareModal && (
                  <ShareReport
                    location="pfr"
                    close={closeShareModal}
                    submit={({
                      report_name,
                      report_description,
                      selectedCategories,
                    }) =>
                      submitShareStaticPfr({
                        report_name,
                        report_description,
                        selectedCategories,
                      })
                    }
                    sharedReportId={sharedReportId}
                    loading={loadingSharePfr}
                    categorySettings={categorySettings}
                  />
                )}
                {mode === 'analytics' && !planningMode && (
                  <LeftDisplay
                    {...{
                      display,
                      setIsSettingsOpen,
                      loading,
                      isDirectMail,
                      isNonPaidMediaType,
                    }}
                  />
                )}
                <div
                  className={`analytics__content ${
                    show_request ? 'request-open' : ''
                  } ${
                    ['dna_table', 'variation', 'hurdle', 'copilot'].includes(
                      display
                    )
                      ? 'gray-bg'
                      : ''
                  } ${planningMode ? 'planning-mode' : ''}`}
                >
                  {openedMenu && (
                    <ClickAwayListener onClickAway={handleClickAway}>
                      <div className="clickaway-div">
                        <CategoryEditor
                          analyticsView={true}
                          {...{
                            categorySettings,
                            dispatchReportCardSettings,
                            reportCardSettings,
                          }}
                        />
                      </div>
                    </ClickAwayListener>
                  )}
                  <Header
                    RecsToggle={
                      <Tooltip
                        content={`Recommendations${
                          !!recommendationsStatus
                            ? recommendationsStatus === 'success'
                              ? ' ready to view'
                              : ` (${recommendationsStatus})`
                            : ''
                        }`}
                        follow={false}
                        placement={'left'}
                      >
                        <div>
                          <IconButton
                            style={{ height: '30px', padding: '0 6px' }}
                            appearance="raised"
                            active={rightDrawerDisplay === 'recommendations'}
                            status={recommendationsStatus}
                            onClick={() => {
                              dispatch(
                                setRightDrawerDisplay(
                                  rightDrawerDisplay === 'recommendations'
                                    ? null
                                    : 'recommendations'
                                )
                              );
                            }}
                          >
                            <i class="fa-solid fa-wand-magic-sparkles"></i>
                          </IconButton>
                        </div>
                      </Tooltip>
                    }
                    MissingDataToggle={
                      <Tooltip
                        content={`Missing data`}
                        follow={false}
                        placement={'left'}
                      >
                        <div>
                          <IconButton
                            style={{ height: '30px', padding: '0 6px' }}
                            appearance="raised"
                            active={rightDrawerDisplay === 'missing_data'}
                            onClick={() => {
                              dispatch(
                                setRightDrawerDisplay(
                                  rightDrawerDisplay === 'missing_data'
                                    ? null
                                    : 'missing_data'
                                )
                              );
                            }}
                          >
                            <i
                              class="fa-solid fa-border-none"
                              style={{ fontSize: '15px' }}
                            ></i>
                          </IconButton>
                        </div>
                      </Tooltip>
                    }
                    RefreshIcon={
                      <Tooltip
                        content={'Refresh performance data'}
                        follow={false}
                        placement={'left'}
                      >
                        <IconButton onClick={fetchPerformanceData}>
                          <i className="fa fa-rotate-right"></i>
                        </IconButton>
                      </Tooltip>
                    }
                    isPF={isPF}
                    mode={mode}
                    libraryView={libraryView}
                    setLibraryView={setLibraryView}
                    display={display}
                    columns={columns}
                    handleColumns={handleColumns}
                    onlyNewAds={onlyNewAds}
                    recsTab={recsTab}
                    toggleOnlyNewAds={() => setOnlyNewAds((prev) => !prev)}
                    filteredData={filteredData}
                    selectedLabels={selectedLabels}
                    handleRefreshReport={handleRefreshReport}
                    isDirectMail={isDirectMail}
                    clientMetrics={clientMetrics}
                    setClientMetrics={setClientMetrics}
                    fetchPerformanceData={fetchPerformanceData}
                    useMissingSpend={useMissingSpend}
                    fetchFrequencyCompareData={fetchCompareData}
                    viewName={viewName}
                    setParams={setParams}
                    filteredVisuals={filteredVisuals}
                    dashboardEditMode={dashboardEditMode}
                    {...{
                      selectedLabels,
                      handleFilter,
                      showActiveOnly,
                      setShowActiveOnly,
                      filteredSearchLookup,
                      handleSelectLabel,
                      handleSharePfr,
                    }}
                    GroupByRatioToggle={
                      <Tooltip
                        content={
                          'This is a BETA feature and will group creatives together based on the shared occurrence of ad names. If you customize your placements then this feature will potentially be misleading.'
                        }
                        follow={false}
                        placement={'left'}
                      >
                        <div>
                          <Toggle
                            checked={shouldGroupLabelsByRatio}
                            onChange={toggleGroupByRatio}
                            id="group-by-labels-toggle"
                          />
                        </div>
                      </Tooltip>
                    }
                    ActiveVisualsToggle={
                      <Tooltip
                        content={'Only show visuals that are currently running'}
                        follow={false}
                        placement={'left'}
                      >
                        <div>
                          <Button
                            style={{ height: '30px', padding: '0 6px' }}
                            appearance="raised"
                            active={showActiveOnly}
                            onClick={() => setShowActiveOnly((prev) => !prev)}
                          >
                            <ActiveIcon className="mr-2" /> Active visuals
                          </Button>
                        </div>
                      </Tooltip>
                    }
                    retrievedDate={retrievedDate}
                    refresh={refresh}
                  />

                  {([PERFORMANCE_FREQUENCY, RECOMMENDATIONS].includes(
                    display
                  ) ||
                    (!filteredData.length && !display.includes('copilot'))) &&
                    !loading && (
                      <>
                        <div className="planning-mode__toggle-border">
                          <Tooltip
                            content="Planning mode"
                            disabled={planningMode}
                            placement={'right'}
                          >
                            <div
                              onClick={() => {
                                setPlanningMode((prev) => !prev);
                                if (planningMode) {
                                  if (!filteredData.length) {
                                    setSearchParams({
                                      display: 'dna_table',
                                    });
                                  } else {
                                    setSearchParams({
                                      display: PERFORMANCE_FREQUENCY,
                                    });

                                    if (!!rightDrawerDisplay) {
                                      dispatch(setRightDrawerDisplay(null));
                                    }
                                  }
                                } else {
                                  setSearchParams({
                                    display: PERFORMANCE_FREQUENCY,
                                  });
                                  setRequestOpen(true);
                                }
                              }}
                              className="planning-mode__toggle"
                            >
                              {!planningMode && <PlanningNoFillIcon />}
                              {planningMode && <i className="fa fa-close" />}
                            </div>
                          </Tooltip>
                        </div>
                      </>
                    )}

                  {planningMode && (
                    <div
                      className="planning-mode__header"
                      style={{
                        marginRight:
                          requestOpen || !!rightDrawerDisplay ? '0' : '2rem',
                      }}
                    >
                      <div className="d-flex align-items-end">
                        {!hideTrending && (
                          <div
                            className={`tab ${
                              display === RECOMMENDATIONS ? 'active' : ''
                            }`}
                            onClick={() =>
                              setSearchParams({ display: RECOMMENDATIONS })
                            }
                          >
                            <TrendingIcon />
                            <span>Trending</span>
                          </div>
                        )}
                        <div
                          className={`tab ${
                            display === PERFORMANCE_FREQUENCY ? 'active' : ''
                          }`}
                          onClick={() =>
                            setSearchParams({ display: PERFORMANCE_FREQUENCY })
                          }
                        >
                          <Bar />
                          <span>Detailed Reports</span>
                        </div>
                      </div>
                      <Tooltip content="Impact report">
                        <Button
                          appearance="raised"
                          className="simple-impact-toggle"
                          onClick={() => {
                            if (requestOpen) {
                              setRequestOpen(false);
                            }

                            dispatch(
                              setRightDrawerDisplay(
                                rightDrawerDisplay === 'recommendations'
                                  ? null
                                  : 'recommendations'
                              )
                            );
                          }}
                        >
                          <i className="fa-solid fa-wand-magic-sparkles"></i>
                        </Button>
                      </Tooltip>
                    </div>
                  )}

                  {isPF && <Banner />}

                  {(loading || loadingRcSettings) &&
                  display !== 'block_report' &&
                  display !== 'dna_table' ? (
                    <h3
                      className="performance-frequency__loading mx-3"
                      style={{ marginTop: '1rem' }}
                    >
                      Fetching performance data
                    </h3>
                  ) : (
                    <div
                      className={`analytics__main ${
                        ['impact_report', 'copy'].includes(display)
                          ? 'no-header'
                          : ''
                      }`}
                    >
                      {!!Comp &&
                      (filteredData.length > 0 ||
                        display === 'block_report' ||
                        display === RECOMMENDATIONS) ? (
                        <Comp
                          {...{
                            data,
                            filteredData,
                            filteredCompareData,
                            sortedCompareData,
                            creativeCount,
                            newAds,
                            insights_by_asset,
                            only_new_ads,
                            trending,
                            executionStyles,
                            inspiration,
                            show_request,
                            account_id,
                            columns,
                            handleColumns,
                            recsTab,
                            period1,
                            period2,
                            loading,
                            loadingCompare,
                            showLoading,
                            setTTNTSections,
                            TryThese,
                            isROAS,
                            sort,
                            labelsByVisual,
                            filteredVisuals,
                            isDirectMail,
                            allVisualsLabel,
                            gaEnabled,
                            libraryAnalyticsState,
                            requestOpen,
                            dashboardEditMode,
                            setDashboardEditMode,
                            view_id,
                            darwin_client_id,
                            getParams,
                            filteredSearchLookup,
                            dispatchReportCardSettings,
                            handleSharePfr,
                          }}
                          LabelSetupIcon={LabelSetupIcon}
                          LibraryExport={ExportButton}
                          TryThese={
                            <TryThese
                              {...{
                                data,
                                filteredData,
                                only_new_ads,
                                trending,
                                executionStyles,
                                inspiration,
                                show_request,
                                account_id,
                                view_id,
                              }}
                              recsTab={recsTab}
                            />
                          }
                        />
                      ) : !!Comp && !loading ? (
                        <p>No data found for this view.</p>
                      ) : null}

                      {display === 'dna_table' && (
                        <Dna
                          pfrLoading={loading}
                          {...{
                            columns,
                            handleColumns,
                            filteredVisuals,
                            shouldGroupLabelsByRatio,
                            refresh,
                            dnaTableApi,
                          }}
                        />
                      )}
                    </div>
                  )}

                  {enableCreativeRequest && requestOpen && (
                    <CreativeRequest
                      filteredData={filteredData}
                      CreativeRequestPortal={CreativeRequestPortal}
                      requestOpen={requestOpen}
                      setRequestOpen={setRequestOpen}
                    />
                  )}

                  {/* {enableLibraryAnalytics && (
                    <LibraryAnalytics
                      filteredData={filteredData}
                      LibraryAnalyticsPortal={LibraryAnalyticsPortal}
                      {...{
                        libraryAnalyticsState,
                        defaultAnalyticsTableCategory,
                        reportCardPrefsApi,
                      }}
                    />
                  )} */}

                  <ActionButtons
                    ActionButtonsPortal={ActionButtonsPortal}
                    show={!dashboardEditMode && !loading}
                  >
                    <CreativeRequestToggle
                      label="Creative Request"
                      show={enableCreativeRequest}
                      className="request-toggle"
                      onClick={() => {
                        setRequestOpen((prev) => {
                          if (!prev)
                            dispatch(
                              trackEvent(
                                CREATIVE_ANALYTICS,
                                OPEN_CREATIVE_REQUEST_DRAWER
                              )
                            );
                          return !prev;
                        });

                        if (!!rightDrawerDisplay) {
                          dispatch(setRightDrawerDisplay(null));
                        }
                      }}
                    />

                    {/* <LibraryAnalyticsToggle
                      label="Analytics"
                      show={enableLibraryAnalytics}
                      className={`library-analytics-toggle ${
                        isNonPaidMediaType || isUltraLight ? 'hide' : ''
                      }`}
                      onClick={() => {
                        if (libraryAnalyticsState.selectedFromDashboard) {
                          libraryAnalyticsState.setSelectedCategory(
                            defaultAnalyticsTableCategory
                          );
                        }

                        libraryAnalyticsState.setOpenLibraryAnalytics(
                          (prev) => !prev
                        );
                      }}
                    /> */}

                    <DashboardEditModeToggle
                      label="Edit dashboard"
                      show={display === DASHBOARD || isDashboard}
                      onClick={() => setDashboardEditMode((prev) => !prev)}
                    />
                  </ActionButtons>

                  {(editCategory ||
                    (editCategoryVisual && editCategoryVisual.show)) && (
                    <Overlay
                      show={true}
                      close={() => {
                        setEditCategory(null);
                        setEditCategoryVisual(null);
                      }}
                    >
                      <Modal styles={{ overflowY: 'initial' }}>
                        <CategoryBuilder
                          category={editCategory}
                          editCategoryVisual={editCategoryVisual}
                        />
                      </Modal>
                    </Overlay>
                  )}

                  {showQaEditor && <QA {...{ loading, setShowQaEditor }} />}

                  {isSettingsOpen && (
                    <Overlay
                      show={true}
                      close={() => {
                        setIsSettingsOpen(false);
                      }}
                    >
                      <Modal styles={{ overflowY: 'initial' }}>
                        <Settings
                          onClose={() => setIsSettingsOpen(false)}
                          CategoryEditor={
                            <CategoryEditor
                              analyticsView={true}
                              style={{}}
                              {...{
                                categorySettings,
                                dispatchReportCardSettings,
                                reportCardSettings,
                              }}
                            />
                          }
                          LabelSetup={
                            <LabelsSetup
                              data={searchTermsData}
                              loading={searchTermsLoading}
                              error={searchTermsError}
                              fetchSearchTerms={searchTermsApi.get.request}
                              {...{
                                account_id,
                                existingKeywords,
                              }}
                            />
                          }
                          MoreSettings={
                            <MoreSettings
                              dispatchCategoryFilter={dispatchCategoryFilter}
                            />
                          }
                          GlobalKeywordSettings={
                            <GlobalKeywordSettings
                              searchTermsData={searchTermsData}
                            />
                          }
                          RefreshReport={
                            <RefreshReport
                              handleRefreshReport={handleRefreshReport}
                            />
                          }
                          SuccessConditions={<SuccessConditions />}
                          HideLabels={
                            <HideLabels categoriesLookup={categoriesLookup} />
                          }
                        />
                      </Modal>
                    </Overlay>
                  )}

                  <BoxPlot
                    activeBoxPlotCategory={activeBoxPlotCategory}
                    setActiveBoxPlotCategory={setActiveBoxPlotCategory}
                  />

                  {rc.length &&
                  visualsLookup &&
                  visualsLookup[rc] &&
                  rc !== '0' ? (
                    <ReportCard
                      visual_hash={rc}
                      depth={recDepth}
                      fixed={fixedLabels}
                      setParams={setParams}
                      visualsLookup={visualsLookup}
                      start_date={darwinDateToDate(period1Start)}
                      end_date={darwinDateToDate(period1End)}
                      insights_by_asset={insights_by_asset}
                      filteredData={filteredData}
                      isROAS={isROAS}
                      fromCreativeStudio={fromCreativeStudio}
                      reportCardPrefsApi={reportCardPrefsApi}
                      {...{
                        spendThreshold,
                        selectedAudience,
                        ReportCardPortal,
                        revisionRequestApi,
                        setReportCardDataType,
                        isDirectMail,
                        viewName,
                        setReportCardMetric,
                        setRcCategorySettings,
                        gaEnabled,
                        reportCardMode,
                        setReportCardMode,
                        period1,
                        primary_metric,
                        columns,
                        handleColumns,
                        dispatchReportCardSettings,
                      }}
                    />
                  ) : null}

                  {/* Best Performing */}
                  {rc.length && rc === '0' && filteredData.length ? (
                    <ReportCard
                      isBestPerformingView={true}
                      visual_hash={rc}
                      depth={recDepth}
                      fixed={fixedLabels}
                      setParams={setParams}
                      visualsLookup={visualsLookup}
                      start_date={darwinDateToDate(period1Start)}
                      end_date={darwinDateToDate(period1End)}
                      insights_by_asset={insights_by_asset}
                      filteredData={filteredData}
                      isROAS={isROAS}
                      fromCreativeStudio={fromCreativeStudio}
                      reportCardPrefsApi={reportCardPrefsApi}
                      {...{
                        spendThreshold,
                        selectedAudience,
                        ReportCardPortal,
                        revisionRequestApi,
                        setReportCardDataType,
                        isDirectMail,
                        viewName,
                        setReportCardMetric,
                        setRcCategorySettings,
                        gaEnabled,
                        reportCardMode,
                        setReportCardMode,
                        filteredVisuals,
                      }}
                    />
                  ) : null}

                  {asyncApi.processes.length
                    ? asyncApi.processes.map((process, index) => {
                        return (
                          <AsyncCCCProcess
                            processIndex={index}
                            key={process.category_id}
                            process={process}
                            processes={asyncApi.processes}
                            remove={asyncApi.removeAsyncProcess}
                            setEditCategory={setEditCategory}
                            fetchPerformanceData={fetchPerformanceData}
                            ongoingBatched={asyncApi.ongoingBatched}
                            isFirstItem={index === 0}
                            isLastItem={index === asyncApi.processes.length - 1}
                          />
                        );
                      })
                    : null}

                  {Comp === PerformanceFrequency ||
                  Comp === PhraseTheme ||
                  Comp === Variation ||
                  Comp === Copilot ? (
                    <Explorer {...{ filteredData, visualsLookup }} />
                  ) : null}
                </div>

                {!!RightDrawer && (
                  <RightDrawerWrapper>{RightDrawer}</RightDrawerWrapper>
                )}
              </div>
            </CreativeRequestProvider>
          </ExplorerContextProvider>
        </CategoryBuilderProvider>
      </CreativeAnalyticsProvider>
    </ErrorBoundary>
  );
};

export default CreativeAnalytics;
