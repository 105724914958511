import React from 'react';
import { OVERVIEW, DETAILS, CATEGORIES } from './constants';

const Nav = ({
  activePage,
  setActivePage,
  fromCreativeStudio,
  isOrganicView,
  fetchCopilot,
  copilotData,
  copilotRequestError,
  copilotLoading,
}) => {
  let pages = [CATEGORIES, DETAILS, OVERVIEW];

  if (fromCreativeStudio) pages = [CATEGORIES];
  if (isOrganicView) pages = [DETAILS, CATEGORIES];

  return (
    <div className="report-card__nav">
      <ul className="nav-group">
        {pages.map((page) => {
          return (
            <NavItem
              {...{
                page,
                activePage,
                setActivePage,
                fetchCopilot,
                copilotData,
                copilotRequestError,
                copilotLoading,
              }}
            >
              {page}
            </NavItem>
          );
        })}
      </ul>
    </div>
  );
};

export default Nav;

const NavItem = ({
  page,
  activePage,
  setActivePage,
  fetchCopilot,
  copilotData,
  copilotRequestError,
  copilotLoading,
  children,
}) => {
  return (
    <li
      className={`nav-item ${page === activePage ? 'active' : 'inactive'}`}
      onClick={() => {
        if (!!activePage) setActivePage(page);
        if (page === OVERVIEW && !copilotLoading && (!copilotData || copilotRequestError)) fetchCopilot();
      }}
    >
      {children}
    </li>
  );
};
