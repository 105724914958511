import React from 'react';
import { ensurePluginOrder } from 'react-table';
import * as XLSX from 'xlsx';
import Papa from 'papaparse';
import { useCreativeAnalyticsGet } from '../CreativeAnalytics/contexts';
import { toDarwinDate } from '../../utils/darwin-dates';

const modifyFields = (headerNames, data, visualsLookup) => {
  // add s3_url field at beginning, add ad_names field at end

  const hasVisualColumn = headerNames.includes('Visual');

  const updatedColumns = hasVisualColumn
    ? ['Asset URL', ...headerNames, 'Ad Names']
    : ['Asset URL', ...headerNames, 'Ad Names'];

  const updatedData = [...data].map((row, index) => {
    const visualObj = visualsLookup[row[0]]?.data;

    row.unshift(visualObj?.asset_url ?? 'n/a');

    row.push(visualObj?.ad_names?.join(', '));
    return row;
  });

  return { updatedColumns, updatedData };
};

export function getExportFileBlob({
  columns,
  data,
  fileType,
  fileName,
  visualsLookup,
}) {
  if (fileType === 'csv') {
    const headerNames = columns.map((col) => col.exportValue);

    const { updatedColumns, updatedData } = modifyFields(
      headerNames,
      data,
      visualsLookup
    );

    const csvString = Papa.unparse({
      fields: updatedColumns,
      data: updatedData,
    });

    return new Blob([csvString], { type: 'text/csv' });
  }

  return false;
}

export function getFirstDefined(...args) {
  for (let i = 0; i < args.length; i += 1) {
    if (typeof args[i] !== 'undefined') {
      return args[i];
    }
  }
}

// Get exported file name(do not specify extension here)
const defaultGetExportFileName = ({ fileType, all }) => {
  return `${all ? 'all-' : ''}data`;
};

// To get cell value while exporting
const defaultGetCellExportValue = (row, col) => {
  if (col.id === 'id') {
    return row['id'] ?? row['_id'];
  }

  return row[col.id];
};

// To get column name while exporting
const defaultGetColumnExportValue = (col) => {
  let name = col.Header;
  if (typeof name === 'object' || typeof name === 'function') {
    name = col.id;
  }
  return name;
};

const defaultGetExportFileBlob = () => {
  throw new Error('React Table: Export Blob is mandatory');
};

export const useExportData = (hooks) => {
  hooks.useInstance.push(useInstance);
};

useExportData.pluginName = 'useExportData';

const checkIfValidColumn = (col, includePreviousData = false) => {
  if (
    ['fitness_score', 'spend_quintile', 'primary_metric_quintile'].includes(
      col.id
    ) &&
    includePreviousData
  ) {
    return false;
  }

  return true;
};

export const getExportableColumns = (allColumns) => {
  const exportableColumns = allColumns.filter(
    (col) =>
      col.canExport &&
      (col.isVisible ||
        ['period_dates', 'period_type', 'new_visual', ''].includes(col.id))
  );
  if (exportableColumns.length === 0) {
    console.warn('No exportable columns are available');
  }

  return exportableColumns;
};

export const getExportableRows = (data, exportableColumns) => {
  let exportableRows = data.map((row) => {
    return exportableColumns.map((col) => {
      const { getCellExportValue = defaultGetCellExportValue } = col;
      const cell = getCellExportValue(row, col) || 'N/A';
      if (typeof cell === 'number') return cell.toFixed(2);
      return cell;
    });
  });

  return exportableRows;
};

export const getDnaTableCsv = (tableInstance, visualsLookup) => {
  const exportableColumns = getExportableColumns(tableInstance.allColumns);

  const exportableRows = getExportableRows(
    tableInstance.data,
    exportableColumns
  );
  const headerNames = exportableColumns.map((col) => col.exportValue);

  const { updatedColumns, updatedData } = modifyFields(
    headerNames,
    exportableRows,
    visualsLookup
  );

  return [updatedColumns, ...updatedData];
};

function useInstance(instance) {
  const {
    rows,
    data,
    initialRows = [],
    allColumns,
    disableExport,
    getExportFileName = defaultGetExportFileName,
    getExportFileBlob = defaultGetExportFileBlob,
    plugins,
  } = instance;
  const { visualsLookup, viewName, start_date, end_date, selectedAudience } =
    useCreativeAnalyticsGet();

  ensurePluginOrder(
    plugins,
    ['useColumnOrder', 'useColumnVisibility', 'useFilters', 'useSortBy'],
    'useExportData'
  );

  allColumns.forEach((column) => {
    const { accessor, getColumnExportValue = defaultGetColumnExportValue } =
      column;

    const canExport = accessor
      ? getFirstDefined(
          column.disableExport === true ? false : undefined,
          disableExport === true ? false : undefined,
          true
        )
      : false;

    column.canExport = canExport;
    column.exportValue = getColumnExportValue(column);
  });

  const exportData = React.useCallback(
    (fileType, all = false, includePreviousData: boolean = false) => {
      const exportableColumns = allColumns.filter(
        (col) =>
          col.canExport &&
          checkIfValidColumn(col, includePreviousData) &&
          (all ||
            col.isVisible ||
            (['period_dates', 'period_type', 'new_visual'].includes(col.id) &&
              includePreviousData))
      );
      if (exportableColumns.length === 0) {
        console.warn('No exportable columns are available');
      }

      let exportableRows = (all ? initialRows : data).map((row) => {
        return exportableColumns.map((col) => {
          const { getCellExportValue = defaultGetCellExportValue } = col;
          const cell = getCellExportValue(row, col) || 'N/A';
          if (typeof cell === 'number') return cell.toFixed(2);
          return cell;
        });
      });

      const fileName = `${viewName}_${toDarwinDate(
        start_date
      )}_to_${toDarwinDate(end_date)}_${
        selectedAudience.length > 0 ? selectedAudience : 'All'
      }Audiences`;

      let fileBlob = getExportFileBlob({
        columns: exportableColumns,
        data: exportableRows,
        fileName,
        fileType,
        visualsLookup,
      });

  console.log({ fileBlob, exportableColumns, exportableRows, initialRows, data })


      if (fileBlob) {
        // downloadFileViaBlob(fileBlob, fileName, fileType);
      }
    },
    [getExportFileBlob, getExportFileName, initialRows, rows, allColumns]
  );


  Object.assign(instance, {
    exportData,
  });
}

function downloadFileViaBlob(fileBlob, fileName, type) {
  if (fileBlob) {
    const dataUrl = URL.createObjectURL(fileBlob);
    const link = document.createElement('a');
    link.download = `${fileName}.${type}`;
    link.href = dataUrl;
    link.click();
  }
}
