import React, { useRef, useState, useEffect } from 'react';
import Tooltip from '../../components/Tooltip';
import { useViewGroupContext } from '../ViewGroup/context';
import { MetaIcon, TikTokIcon } from './icons';

export const TabList = ({ amount, exit, children }) => {
  const ref = useRef(null);
  const { mainView } = useViewGroupContext();

  const [isOverflowing, setIsOverflowing] = useState(false);

  const checkOverflow = () => {
    const { scrollWidth, clientWidth } = ref.current;
    setIsOverflowing(scrollWidth > clientWidth);
  };

  const scrollLeft = (scrollOffset) => {
    if (ref.current) {
      ref.current.scrollLeft += scrollOffset;
    }
  };

  const scrollRight = (scrollOffset) => {
    if (ref.current) {
      ref.current.scrollLeft += scrollOffset;
    }
  };

  useEffect(() => {
    checkOverflow();
    window.addEventListener('resize', checkOverflow);
    return () => window.removeEventListener('resize', checkOverflow);
  }, [amount]);

  return (
    <div className="tab-list-wrapper">
      {isOverflowing && (
        <button
          onClick={() => scrollLeft(-200)}
          className="tab-list__arrow tab-list__left-arrow"
        >
          <i className="fa-solid fa-angle-left"></i>
        </button>
      )}

      <ul className="tab-list" ref={ref}>
        {children}
      </ul>

      {isOverflowing && (
        <button
          onClick={() => scrollRight(200)}
          className="tab-list__arrow tab-list__right-arrow"
        >
          <i className="fa-solid fa-angle-right"></i>
        </button>
      )}

      {!!exit && (
        <Tooltip
          content={`Exit view group and load ${mainView?.viewName ?? ''} view${
            mainView?.viewName ? '' : ' as normal'
          }`}
        >
          <button onClick={exit} className="tab-list-wrapper__exit-btn">
            <i className="fa-solid fa-x"></i>
          </button>
        </Tooltip>
      )}
    </div>
  );
};

type TabProps = {
  label: string;
  onSelect: () => void;
  isSelected: boolean;
};

export const Tab = ({ label, onSelect, isSelected }: TabProps) => {
  return (
    <>
      <li className={`tab ${isSelected ? 'is-selected' : ''}`}>
        <button onClick={onSelect}>
          {label.includes('Meta') && (
            <span className="icon">
              <MetaIcon />
            </span>
          )}
          {label.includes('TikTok') && (
            <span className="icon">
              <TikTokIcon />
            </span>
          )}
          {label}
        </button>
        <span className="divider"></span>
      </li>
    </>
  );
};
