import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useCreativeAnalyticsGet, useCreativeAnalyticsSet } from '../contexts';
import { useRecommendationsApi } from './api';
import {
  buildRequestShape,
  filterLabels,
  formatRecs,
  getCategoriesWithLabelsData,
  getProjections,
  getTopSpender,
} from './utils';
import { useCreativeAnalyticsStore, useViewSettings } from '../../../selectors';
import _isEqual from 'lodash/isEqual';
import { Layout } from './Layout';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorBoundary } from '../../../components/Helpers/Error';
import { setRightDrawerDisplay } from '../../../store/CreativeAnalytics/creativeAnalyticsSlice';
import { useGetCommonElements } from '../PerformanceFrequency/api';
import { useCategories } from '../hooks';
import { DISABLED_CATEGORIES } from '../ReportCard/CategoryEditor/constants';
import { useGetEasySuggestionsApi } from '../ReportCard/api';
import { useReportCardCategories } from '../ReportCard/hooks';
import {
  getCategoryLabelData,
  makeEasyGptPayload,
} from '../ReportCard/Suggestions/utils';
import { useVisualRows } from './hooks';
import { RecommendationsProvider } from './context';
import { useSimpleImpactApi } from './SimpleImpact';

const Wrapper = () => {
  const {
    filteredAllVisualsLabel,
    filteredData,
    primary_metric,
    reportCardSettings: { categorySettings },
    isROAS,
    visualsLookup,
    display,
  } = useCreativeAnalyticsGet();
  const { viewId: view_id, darwin_client_id: client_id } = useViewSettings();
  const dispatch = useDispatch();
  const close = () => dispatch(setRightDrawerDisplay(null));
  const previousPayloadRef = useRef(null);
  const [recs, setRecs] = useState([]);
  const [winnersCommonElements, setWinnersCommonElements] = useState([]);
  const [losersCommonElements, setLosersCommonElements] = useState([]);
  const [allCommonElements, setAllCommonElements] = useState([]);
  const [winnerSuggestions, setWinnerSuggestions] = useState([]);
  const [loserSuggestions, setLoserSuggestions] = useState([]);
  const [idsInLastRequest, setIdsInLastRequest] = useState([]);
  const { selectedVisuals } = useCreativeAnalyticsStore();
  const [commonElementsIsOpen, setCommonElementsIsOpen] = useState(false);

  const { data, request, loading, error } = useRecommendationsApi();
  const {
    post: {
      data: winnersData,
      request: winnersRequest,
      loading: winnersLoading,
      error: winnersError,
    },
  } = useGetCommonElements();
  const {
    post: {
      data: losersData,
      request: losersRequest,
      loading: losersLoading,
      error: losersError,
    },
  } = useGetCommonElements();
  const {
    post: {
      data: allAssetsData,
      request: allAssetsRequest,
      loading: allAssetsLoading,
      error: allAssetsError,
    },
  } = useGetCommonElements();
  const {
    data: winnerSuggestionsData,
    request: winnerSuggestionsRequest,
    loading: winnerSuggestionsLoading,
    error: winnerSuggestionsError,
    count: winnerSuggestionsCount,
  } = useGetEasySuggestionsApi();
  const {
    data: loserSuggestionsData,
    request: loserSuggestionsRequest,
    loading: loserSuggestionsLoading,
    error: loserSuggestionsError,
    count: loserSuggestionsCount,
  } = useGetEasySuggestionsApi();

  const allVisuals = filteredData.find((d) => d.value === 'all_visuals') || {};
  const allVisualsWinnerIds =
    allVisuals.winners && allVisuals.winners.length
      ? allVisuals.winners.map(({ id }) => id)
      : [];
  const allVisualsLoserIds =
    allVisuals.losers && allVisuals.losers.length
      ? allVisuals.losers.map(({ id }) => id)
      : [];
  let winnerIds = allVisualsWinnerIds.filter((id) =>
    display === 'dna_table' ? selectedVisuals.includes(id) : id
  );
  let loserIds = allVisualsLoserIds.filter((id) =>
    display === 'dna_table' ? selectedVisuals.includes(id) : id
  );

  const topSpendingWinner =
    allVisuals.winners && allVisuals.winners.length
      ? getTopSpender(allVisuals.winners)
      : { id: '' };
  const topSpendingLoser =
    allVisuals.losers && allVisuals.losers.length
      ? getTopSpender(allVisuals.losers)
      : { id: '' };
  const topWinnerCategories = useReportCardCategories(
    visualsLookup[topSpendingWinner.id],
    categorySettings
  );
  const topLoserCategories = useReportCardCategories(
    visualsLookup[topSpendingLoser.id],
    categorySettings
  );

  const { navCategories } = useCategories();

  const visibleLabels = useMemo(
    () =>
      filteredData.filter((item) =>
        navCategories.some((c) => item.category === c.label)
      ),
    [filteredData, categorySettings]
  );

  const projections = getProjections(
    allVisuals,
    topSpendingWinner,
    topSpendingLoser,
    isROAS
  );

  const commonElementsProps = {
    winnersCommonElements,
    winnersLoading,
    winnersError,
    losersCommonElements,
    losersLoading,
    losersError,
    winnerIds,
    loserIds,
    allCommonElements,
    allAssetsLoading,
    allAssetsError,
  };

  const topSpendingProps = {
    winnerSuggestions,
    winnerSuggestionsLoading,
    winnerSuggestionsError,
    winnerSuggestionsCount,
    loserSuggestions,
    loserSuggestionsLoading,
    loserSuggestionsError,
    loserSuggestionsCount,
    topSpendingWinner,
    topSpendingLoser,
    projections,
  };

  useEffect(() => {
    if (data) {
      setRecs(formatRecs(data));
    }
  }, [data]);

  useEffect(() => {
    if (winnersData?.data) {
      if (Array.isArray(JSON.parse(winnersData.data.body))) {
        setWinnersCommonElements(
          filterLabels(winnersData.data?.body, visibleLabels)
        );
      }
    }
    if (losersData?.data) {
      if (Array.isArray(JSON.parse(losersData.data.body))) {
        setLosersCommonElements(
          filterLabels(losersData.data?.body, visibleLabels)
        );
      }
    }
    if (allAssetsData?.data) {
      if (Array.isArray(JSON.parse(allAssetsData.data.body))) {
        setAllCommonElements(
          filterLabels(allAssetsData.data?.body, visibleLabels)
        );
      }
    }
  }, [winnersData, losersData, allAssetsData]);

  useEffect(() => {
    if (filteredData.length > 0) {
      fetchRecs();
      fetchCommonElements();
    }
  }, [filteredData]);

  useEffect(() => {
    fetchCommonElements();
  }, [selectedVisuals.length]);

  useEffect(() => {
    if (loserSuggestionsData) {
      setLoserSuggestions(formatData(loserSuggestionsData));

      if (winnerSuggestionsCount < 1) {
        fetchSuggestions('winner');
      }
    }
  }, [loserSuggestionsData]);

  useEffect(() => {
    if (loserSuggestionsError && winnerSuggestionsCount < 1) {
      fetchSuggestions('winner');
    }

    if (winnerSuggestionsError && loserSuggestionsCount < 1) {
      fetchSuggestions('loser');
    }
  }, [loserSuggestionsError, winnerSuggestionsError]);

  useEffect(() => {
    if (winnerSuggestionsData) {
      setWinnerSuggestions(formatData(winnerSuggestionsData));

      if (loserSuggestionsCount < 1) {
        fetchSuggestions('loser');
      }
    }
  }, [winnerSuggestionsData]);

  const formatData = (data) => {
    let result = data;
    if (!Array.isArray(data)) {
      data = [data];
    }
    return result;
  };

  const fetchCommonElements = () => {
    if (commonElementsIsOpen) {
      winnersRequest(winnerIds);
      losersRequest(loserIds);
      allAssetsRequest([...winnerIds, ...loserIds]);
    }
  };

  const fetchSuggestions = (requestType) => {
    let categories = topWinnerCategories;
    let id = topSpendingWinner.id;

    if (requestType === 'loser') {
      categories = topLoserCategories;
      id = topSpendingLoser.id;
    }

    const categoryLabelData = getCategoryLabelData(
      getCategoriesWithLabelsData(categories),
      isROAS,
      visualsLookup[id]
    );

    const payload = makeEasyGptPayload(
      categoryLabelData,
      client_id,
      view_id,
      id
    );

    if (requestType === 'loser') {
      loserSuggestionsRequest(payload);
    } else {
      winnerSuggestionsRequest(payload);
    }
  };

  const fetchRecs = (shouldRefetch = false) => {
    if (
      !filteredAllVisualsLabel ||
      (display === 'dna_table' && selectedVisuals.length < 2)
    )
      return;

    let visualsObj = filteredAllVisualsLabel;

    if (selectedVisuals.length) {
      visualsObj = {
        ...filteredAllVisualsLabel,
        visuals: filteredAllVisualsLabel.visuals.filter(({ id }) =>
          selectedVisuals.includes(id)
        ),
      };
    }

    const payload = {
      view_id,
      client_id,
      ...buildRequestShape(visualsObj, primary_metric),
    };

    if (shouldRefetch || !_isEqual(previousPayloadRef.current, payload)) {
      request(payload);
      setIdsInLastRequest(visualsObj.visuals.map(({ id }) => id));
    }

    previousPayloadRef.current = payload;
  };

  const selectedVisualsChanged = !_isEqual(
    [...idsInLastRequest].sort(),
    [...selectedVisuals].sort()
  );

  if (filteredData.length < 1) {
    return null;
  }

  return (
    <ErrorBoundary error={error?.error}>
      <RecommendationsProvider>
        <Layout
          {...{
            recs,
            loading,
            error,
            close,
            fetchRecs,
            commonElementsProps,
            topSpendingProps,
            isROAS,
            fetchSuggestions,
            fetchCommonElements,
            display,
            selectedVisualsChanged,
            selectedVisuals,
            setCommonElementsIsOpen,
          }}
        />
      </RecommendationsProvider>
    </ErrorBoundary>
  );
};

export default Wrapper;
