import React, { createContext, useContext, useEffect, useState } from 'react';
import { useHover } from '../../../hooks/useHover';
import { useCreativeAnalyticsGet, useCreativeAnalyticsSet } from '../contexts';
import Tooltip from '../../../components/Tooltip';
import SquaresList from './SquaresList';
import { getPValue } from '../get-p-value';
import HoverWindow from './HoverWindow';
import {
  GOOGLE_VISION_CATEGORIES,
  REKOGNITION_CATEGORIES,
} from '../categories';
import {
  CUSTOM_SCORING_METRICS,
  TIP_TEXT,
  useClientMetricsTipText,
  useCustomEventsTipText,
} from './constants';
import { CommonLabels } from './CommonLabels/CommonLabels';
import {
  toCurrency,
  toFixed_2,
  toInt,
  toLocale_2,
  toPercent,
  toPercentRounded,
  toPercent_2,
  toPercent_3,
} from '../../../utils/numbers';
import { getCPAQuantile, getCPAQuantileDebug } from '../filter-utils';
import { PlanningSelect } from './Cells/PlanningSelect';
import { DistributionPValue } from './Cells/DistributionPValue';
import { useAdditionalMetricsColumns } from './hooks/useAdditionalMetricsColumns';
import { useViewSettings } from '../../../selectors';
import { useCustomIntegratedMetricsColumns } from './hooks/useCustomIntegratedMetricsColumns';
import { useCustomScoringColumns } from './hooks/useCustomScoringColumns';

const gaCols = [
  'ga_aggregate_ctr',
  // "ga_average_ctr",
  'ga_median_ctr',
];
const cpikCols = [
  'aggregate_cpik',
  // "average_cpik",
  'median_cpik',
];
const cpRecallersCols = [
  'aggregate_cost_per_estimated_ad_recallers',
  'median_cost_per_estimated_ad_recallers',
];
const defaultCols = [
  'id',
  'aggregate_cpm',
  // 'average_cpm',
  'median_cpm',
  'cpm_reach',
  'aggregate_cpc',
  // 'average_cpc',
  'median_cpc',
  'aggregate_ctr',
  'average_ctr',
  'median_ctr',
  'total_link_clicks',
  'total_impressions',
  'percent_of_link_clicks',
  'percent_of_link_clicks_diff',
  'percent_budget',
  'spend',
  'p_value',
  'dist_p_value',
  'performance_percent',
  'darwin_index',
  'wins_over_all',
  'number_unique_creatives',
  'percent_of_creative_count',
  'visuals',
  'planning',
  'average_percent_spend',
  'average_performance_score',
  ...gaCols,
  ...cpikCols,
  ...cpRecallersCols,
];
const cpaCols = [
  'aggregate_cpa',
  // 'average_cpa',
  'median_cpa',
  'total_results',
  'percent_of_results',
  'percent_of_results_diff',
  'cpa_quantile',
  'average_cpa_quintile',
];
const roasCols = [
  'aggregate_roas',
  // 'average_roas',
  'median_roas',
  'total_results',
  'roas_quantile',
  'average_roas_quintile',
];
export const nonOrganicCols = [
  'total_results',
  'percent_of_results',
  'percent_of_results_diff',
  'darwin_index',
  'spend',
  'impressions',
  'median',
  'Median',
  'cpa',
  'CPA',
  'cpm',
  'CPM',
  'cpm_reach',
  'ctr',
  'CTR',
  'cpc',
  'CPC',
  'quintile',
  'quantile',
  'total_link_clicks',
  'total_impressions',
  'percent_of_link_clicks',
  'percent_of_link_clicks_diff',
];
const nonEmailCols = [
  'cpm',
  'CPM',
  'cpm_reach',
  'ctr',
  'CTR',
  'cpc',
  'CPC',
  'spend',
  'percent_budget',
  'total_impressions',
  'total_results',
  'total_link_clicks',
  'total_impressions',
  'percent_of_link_clicks',
  'percent_of_link_clicks_diff',
];

const nameOptions = ['name', 'options'];
const name = ['name'];
const CPA_PRESET = [...defaultCols, ...cpaCols, ...nameOptions];
// period2 && !isCompare
const CPA_P1_PRESET = [...defaultCols, ...cpaCols, ...name];
// period2 && isCompare
const CPA_P2_PRESET = [...defaultCols, ...cpaCols];
const ROAS_PRESET = [...defaultCols, ...roasCols, ...nameOptions];
// period2 && !isCompare
const ROAS_P1_PRESET = [...defaultCols, ...roasCols, ...name];
// period2 && isCompare
const ROAS_P2_PRESET = [...defaultCols, ...roasCols];

export const dropROASColumns = ({ accessor }) => CPA_PRESET.includes(accessor);
export const dropCPAColumns = ({ accessor }) => ROAS_PRESET.includes(accessor);
export const dropROASColumnsP1 = ({ accessor }) =>
  CPA_P1_PRESET.includes(accessor);
export const dropROASColumnsP2 = ({ accessor }) =>
  CPA_P2_PRESET.includes(accessor);
export const dropCPAColumnsP1 = ({ accessor }) =>
  ROAS_P1_PRESET.includes(accessor);
export const dropCPAColumnsP2 = ({ accessor }) =>
  ROAS_P2_PRESET.includes(accessor);
export const dropNonOrganicColumns = ({ accessor }) =>
  !nonOrganicCols.some((x) => {
    return accessor.split('_').includes(x) || accessor === x;
  });
export const dropNonEmailColumns = ({ accessor }) =>
  !nonEmailCols.some((x) => {
    return accessor.split('_').includes(x) || accessor === x;
  });

export const Trophy = ({ content, color }) => {
  return (
    <Tooltip content={content} className="frequency-table__tooltip" follow>
      <i
        className="fa fa-trophy"
        style={{
          color: color,
          lineHeight: 'none',
        }}
      />
    </Tooltip>
  );
};

const _ = require('lodash');

export const useCustomColumnHeaders = () => {
  const { clientMetrics, isOrganicView, isEmailView, isDirectMail } =
    useCreativeAnalyticsGet();
  const clientMetricsTipText = useClientMetricsTipText(clientMetrics);

  const customColumnHeaders =
    (isDirectMail || isOrganicView) && clientMetrics?.length > 0
      ? _.flatten(
          clientMetrics.map((metric) => {
            if (isOrganicView) {
              return ['Average ' + metric, 'Total ' + metric];
            } else if (isEmailView) {
              return [metric];
            }
            return [
              'Aggregate ' + metric,
              // 'Average ' + metric,
              'Median ' + metric,
            ];
          })
        ).map((metricLabel) => {
          const metric = metricLabel.split(' ').slice(1).join(' ');
          return {
            Header: metricLabel.includes('Aggregate') ? metric : metricLabel,
            accessor: metricLabel,
            disableSortBy: true,
            disableFilters: true,
            tipText: clientMetricsTipText[metricLabel],
            Cell: ({ row: { original } }) => {
              if (!original[metricLabel]) {
                return <p>0</p>;
              }
              try {
                return <p>{original[metricLabel].toFixed(2)}</p>;
              } catch (e) {
                return original[metricLabel];
              }
            },
          };
        })
      : [];

  return customColumnHeaders;
};

export const useCustomEventsHeaders = (customEvents) => {
  const customEventsHeaders = customEvents
    ? _.flatten(
        customEvents.map((event) => {
          return [
            event.name,
            // 'Average ' + event.name,
            'Median ' + event.name,
          ];
        })
      ).map((label) => {
        const customEventsTipText = useCustomEventsTipText(
          customEvents,
          label.split(' ')[1]
        );
        const labelAccessor = label.toLowerCase().replace(/\s+/g, '_') || '';
        const metric = label.split(' ').slice(1).join(' ');

        return {
          Header: label,
          accessor: label,
          disableSortBy: true,
          disableFilters: true,
          tipText: customEventsTipText[label],
          Cell: ({ row: { original } }) => {
            try {
              let display = toPercent_2(original[label]);

              if (
                label.toLowerCase().includes('share of impressions') ||
                label.toLowerCase().includes('share of assets')
              ) {
                display = toPercentRounded(original[label]);
              }
              if (original[label] === null || original[label] === undefined) {
                return <p>-</p>;
              }
              return <p>{display}</p>;
            } catch (e) {
              return original[label] || <p>-</p>;
            }
          },
        };
      })
    : [];

  return customEventsHeaders;
};
const isStatic =
  window.location.pathname.includes('static-report') ||
  window.location.pathname.includes('report-group');

export const useColumns = () => {
  const text = TIP_TEXT;
  const customColumnHeaders = useCustomColumnHeaders();
  const customEventsHeaders = useCustomEventsHeaders();
  const additionalMetricsColumns = useAdditionalMetricsColumns();
  const customScoringColumns = useCustomScoringColumns();
  const isUBeauty = additionalMetricsColumns.some(
    (col) => col.accessor === 'value_Rockerbox_ROAS'
  );
  const customIntegratedMetricsColumns = !isUBeauty
    ? useCustomIntegratedMetricsColumns()
    : [];
  const { isOrganicView, isEmailView } = useCreativeAnalyticsGet();

  return [
    {
      Header: 'Name',
      accessor: 'name',
      disableFilters: true,
      width: 250,
      maxWidth: 400,
      Cell: (cell) => {
        const [ref, isHovered] = useHover();
        const { name } = cell.row.original;
        const [commonLabels, setCommonLabels] = useState({});
        const [overMax, setOverMax] = useState(false);
        const [loading, setLoading] = useState(false);

        if (!isHovered || cell.row.original.categoryHeader) {
          return <div ref={ref}>{name}</div>;
        }

        const handleLabels = (data, loading) => {
          const apiData = {
            ...data,
          };
          setLoading(loading);
          setCommonLabels(apiData);

          let { commonAll, commonWinners, commonLosers } = apiData;
          if (
            commonAll.length > 6 ||
            commonWinners.length > 6 ||
            commonLosers.length > 6
          ) {
            setOverMax(true);
          }
        };

        return (
          <>
            <div className="name-text">
              <Tooltip
                shouldShow={!isStatic}
                content={
                  <HoverWindow
                    isHovered={isHovered}
                    cell={cell}
                    handleCommonLabels={handleLabels}
                  />
                }
                className="frequency-table__tooltip"
                placement="right"
                follow
                distance={0}
                noPadding
              >
                <div>{name}</div>
              </Tooltip>

              {!isStatic && (
                <CommonLabels
                  labels={commonLabels}
                  name={name}
                  loading={loading}
                />
              )}
            </div>
          </>
        );
      },
    },
    ...customScoringColumns,
    ...customColumnHeaders,
    ...customEventsHeaders,
    ...additionalMetricsColumns,
    ...customIntegratedMetricsColumns,
    {
      Header: 'CPA',
      accessor: 'aggregate_cpa',
      disableFilters: true,
      tipText: text.aggregate_cpa,
      Cell: ({
        row: {
          original: { aggregate_cpa },
        },
      }) => {
        return (
          <p>
            {aggregate_cpa > 0
              ? isEmailView
                ? toLocale_2(aggregate_cpa)
                : toCurrency(aggregate_cpa)
              : '-'}
          </p>
        );
      },
    },
    {
      Header: 'ROAS',
      accessor: 'aggregate_roas',
      disableSortBy: true,
      disableFilters: true,
      tipText: text.aggregate_roas,
      Cell: ({
        row: {
          original: { aggregate_roas },
        },
      }) => {
        return <p>{toFixed_2(aggregate_roas)}</p>;
      },
    },
    // {
    //   Header: 'Average CPA',
    //   accessor: 'average_cpa',
    //   disableSortBy: true,
    //   disableFilters: true,
    //   tipText: text.average_cpa,
    //   Cell: ({
    //     row: {
    //       original: { average_cpa },
    //     },
    //   }) => {
    //     return <p>{toCurrency(average_cpa)}</p>;
    //   },
    // },
    // {
    //   Header: 'Average ROAS',
    //   accessor: 'average_roas',
    //   disableSortBy: true,
    //   disableFilters: true,
    //   tipText: text.average_roas,
    //   Cell: ({
    //     row: {
    //       original: { average_roas },
    //     },
    //   }) => {
    //     return <p>{toCurrency(average_roas)}</p>;
    //   },
    // },
    {
      Header: 'Median CPA',
      accessor: 'median_cpa',
      disableFilters: true,
      tipText: text.median_cpa,
      Cell: ({
        row: {
          original: { median_cpa },
        },
      }) => {
        return (
          <p>{isEmailView ? toLocale_2(median_cpa) : toCurrency(median_cpa)}</p>
        );
      },
    },
    {
      Header: 'Median ROAS',
      accessor: 'median_roas',
      disableFilters: true,
      tipText: text.median_roas,
      Cell: ({
        row: {
          original: { median_roas },
        },
      }) => {
        return <p>{toFixed_2(median_roas)}</p>;
      },
    },
    {
      Header: 'Total Results',
      accessor: 'total_results',
      disableFilters: true,
      tipText: text.total_results,
    },
    {
      Header: `% of ${isEmailView ? 'Placed Orders' : 'Results'}`,
      accessor: 'percent_of_results',
      disableFilters: true,
      tipText: text.percent_of_results,
    },
    {
      Header: `% of ${isEmailView ? 'Placed Orders' : 'Results'} Difference`,
      accessor: 'percent_of_results_diff',
      disableFilters: true,
      tipText: text.percent_of_results_diff,
    },
    {
      Header: 'CPM',
      accessor: 'aggregate_cpm',
      disableFilters: true,
      tipText: text.aggregate_cpm,
      Cell: ({
        row: {
          original: { aggregate_cpm },
        },
      }) => {
        return <p>{toCurrency(aggregate_cpm)}</p>;
      },
    },
    // {
    //   Header: 'Average CPM',
    //   accessor: 'average_cpm',
    //   disableSortBy: true,
    //   disableFilters: true,
    //   tipText: text.average_cpm,
    //   Cell: ({
    //     row: {
    //       original: { average_cpm },
    //     },
    //   }) => {
    //     return <p>{toCurrency(average_cpm)}</p>;
    //   },
    // },
    {
      Header: 'Median CPM',
      accessor: 'median_cpm',
      disableFilters: true,
      tipText: text.median_cpm,
      Cell: ({
        row: {
          original: { median_cpm },
        },
      }) => {
        return <p>{toCurrency(median_cpm)}</p>;
      },
    },
    {
      Header: 'CPM-Reach',
      accessor: 'cpm_reach',
      disableFilters: true,
      tipText: text.cpm_reach,
      Cell: ({
        row: {
          original: { cpm_reach },
        },
      }) => {
        return <p>{toCurrency(cpm_reach)}</p>;
      },
    },
    {
      Header: 'CPC',
      accessor: 'aggregate_cpc',
      disableFilters: true,
      tipText: text.aggregate_cpc,
      Cell: ({
        row: {
          original: { aggregate_cpc },
        },
      }) => {
        return <p>{toCurrency(aggregate_cpc)}</p>;
      },
    },
    // {
    //   Header: 'Average CPC',
    //   accessor: 'average_cpc',
    //   disableSortBy: true,
    //   disableFilters: true,
    //   tipText: text.average_cpc,
    //   Cell: ({
    //     row: {
    //       original: { average_cpc },
    //     },
    //   }) => {
    //     return <p>{toCurrency(average_cpc)}</p>;
    //   },
    // },
    {
      Header: 'Median CPC',
      accessor: 'median_cpc',
      disableFilters: true,
      tipText: text.median_cpc,
      Cell: ({
        row: {
          original: { median_cpc },
        },
      }) => {
        return <p>{toCurrency(median_cpc)}</p>;
      },
    },
    {
      Header: 'CTR',
      accessor: 'aggregate_ctr',
      disableFilters: true,
      tipText: text.aggregate_ctr,
      Cell: ({
        row: {
          original: { aggregate_ctr },
        },
      }) => {
        return <p>{toPercent_2(aggregate_ctr)}</p>;
      },
    },
    {
      Header: 'Average CTR',
      accessor: 'average_ctr',
      disableSortBy: true,
      disableFilters: true,
      tipText: text.average_ctr,
      Cell: ({
        row: {
          original: { average_ctr },
        },
      }) => {
        return <p>{toPercent_2(average_ctr / 100)}</p>;
      },
    },
    {
      Header: 'Median CTR',
      accessor: 'median_ctr',
      disableFilters: true,
      tipText: text.median_ctr,
      Cell: ({
        row: {
          original: { median_ctr },
        },
      }) => {
        return <p>{toPercent_2(median_ctr / 100)}</p>;
      },
    },
    {
      Header: 'GA CTR',
      accessor: 'ga_aggregate_ctr',
      disableFilters: true,
      tipText: text.ga_aggregate_ctr,
      Cell: ({
        row: {
          original: { ga_aggregate_ctr },
        },
      }) => {
        return <p>{toPercent_2(ga_aggregate_ctr)}</p>;
      },
    },
    // {
    //   Header: 'GA Average CTR',
    //   accessor: 'ga_average_ctr',
    //   disableSortBy: true,
    //   disableFilters: true,
    //   tipText: text.ga_average_ctr,
    //   Cell: ({
    //     row: {
    //       original: { ga_average_ctr },
    //     },
    //   }) => {
    //     return <p>{toPercent_2(ga_average_ctr)}</p>;
    //   },
    // },
    {
      Header: 'GA Median CTR',
      accessor: 'ga_median_ctr',
      disableFilters: true,
      tipText: text.ga_median_ctr,
      Cell: ({
        row: {
          original: { ga_median_ctr },
        },
      }) => {
        return <p>{toPercent_2(ga_median_ctr)}</p>;
      },
    },
    {
      Header: 'Total Link Clicks',
      accessor: 'total_link_clicks',
      disableFilters: true,
      tipText: text.total_link_clicks,
    },
    {
      Header: '% of Link Clicks',
      accessor: 'percent_of_link_clicks',
      disableFilters: true,
      tipText: text.percent_of_link_clicks,
    },
    {
      Header: 'Total Impressions',
      accessor: 'total_impressions',
      disableFilters: true,
      Cell: ({ value }) => {
        return <p>{toLocale_2(value)}</p>;
      },
    },
    {
      Header: '% of Link Click Difference',
      accessor: 'percent_of_link_clicks_diff',
      disableFilters: true,
      tipText: text.percent_of_link_clicks_diff,
    },
    {
      Header: isOrganicView ? '% of Posts' : '% Spend',
      accessor: 'percent_budget',
      disableFilters: true,
      tipText: text.percent_budget,
    },
    {
      Header: 'Spend',
      accessor: 'spend',
      disableFilters: true,
      tipText: text.spend,
      Cell: ({
        row: {
          original: { spend },
        },
      }) => {
        return <p>{toCurrency(spend)}</p>;
      },
    },
    {
      Header: 'Win/Loss p-value',
      accessor: 'p_value',
      disableFilters: true,
      tipText: text.p_value,
      Cell: ({ row: { original: label } }) => {
        const { filteredData } = useCreativeAnalyticsGet();
        const pValue = getPValue(filteredData, label);
        const significant = pValue < 0.05 && label.denominator > 1;
        const potential =
          pValue > 0.05 && pValue < 0.2 && label.denominator > 1;

        return (
          <div className="d-flex justify-content-center">
            {significant && (
              <span style={{ marginRight: '0.5rem' }}>
                <Trophy
                  content="Statistically Significant: A p-value less than 0.05 (typically ≤ 0.05) is statistically significant. It indicates strong evidence against the null hypothesis, as there is less than a 5% probability the null is correct (and the results are random)."
                  color="#FFB673"
                />
              </span>
            )}
            {label.name !== 'All Visuals' ? (
              <Tooltip
                content={`What this means: There is a ${parseFloat(
                  pValue
                ).toLocaleString(undefined, {
                  style: 'percent',
                  minimumFractionDigits: 0,
                })} chance that the “Win Rate” for the ${
                  label.visuals.length
                } visuals with the "${
                  label.name
                }" label is due to random chance.`}
                className="frequency-table__tooltip"
                follow
              >
                <p>{`${pValue}`}</p>
              </Tooltip>
            ) : (
              <p>-</p>
            )}
          </div>
        );
      },
    },
    {
      Header: 'Distribution p-value',
      accessor: 'dist_p_value',
      disableFilters: true,
      tipText: text.p_value,
      Cell: DistributionPValue,
    },
    {
      Header: 'Darwin Index',
      accessor: 'darwin_index',
      disableFilters: true,
      tipText: text.darwin_index,
      Cell: ({ value }) => (
        <Tooltip
          content="This is the average success rate of the label across the Darwin Index."
          className="frequency-table__tooltip"
          follow
        >
          <p>{value}</p>
        </Tooltip>
      ),
    },
    {
      Header: 'Average CPA Quintile',
      accessor: 'cpa_quantile',
      disableFilters: true,
      Cell: ({ row: { original: label } }) => {
        const { cpasList, isROAS } = useCreativeAnalyticsGet();
        const cpa_quantile = getCPAQuantile(label, cpasList, isROAS);

        return (
          <Tooltip
            content="Your CPA broken down into 5 quintiles of performance, with 5 being the best."
            className="frequency-table__tooltip"
            follow
          >
            <p>{cpa_quantile || '-'}</p>
          </Tooltip>
        );
      },
    },
    {
      Header: 'Average ROAS Quintile',
      accessor: 'roas_quantile',
      disableFilters: true,
      Cell: ({ row: { original: label } }) => {
        const { cpasList, isROAS } = useCreativeAnalyticsGet();
        const cpa_quantile = getCPAQuantile(label, cpasList, isROAS);

        return (
          <Tooltip
            content="Your ROAS broken down into 5 quintiles of performance, with 5 being the best."
            className="frequency-table__tooltip"
            follow
          >
            <p>{cpa_quantile || '-'}</p>
          </Tooltip>
        );
      },
    },
    {
      Header: 'Index CPA Quintile',
      accessor: 'average_cpa_quintile',
      disableFilters: true,
      Cell: ({ value }) => (
        <Tooltip
          content="The performance quintile of all advertisers in the Darwin Index, with 5 being the best."
          className="frequency-table__tooltip"
          follow
        >
          <p>{value || '-'}</p>
        </Tooltip>
      ),
    },
    {
      Header: 'Index ROAS Quintile',
      accessor: 'average_roas_quintile',
      disableFilters: true,
      Cell: ({ value }) => (
        <Tooltip
          content="The performance quintile of all advertisers in the Darwin Index, with 5 being the best."
          className="frequency-table__tooltip"
          follow
        >
          <p>{value || '-'}</p>
        </Tooltip>
      ),
    },
    {
      Header: 'Index % Spend',
      accessor: 'average_percent_spend',
      disableFilters: true,
      Cell: ({ value }) => (
        <Tooltip
          content="The average amount of spend going to this label across all advertisers in the Darwin Index."
          className="frequency-table__tooltip"
          follow
        >
          <p>{value ? toPercentRounded(value) : '-'}</p>
        </Tooltip>
      ),
    },
    {
      Header: 'Win rate',
      accessor: 'performance_percent',
      disableFilters: true,
      tipText: text.performance_percent,
      Cell: ({ row: { original: label } }) => {
        const { filteredData } = useCreativeAnalyticsGet();
        const pValue = getPValue(filteredData, label);

        return (
          <div className="d-flex justify-content-center">
            {label.name !== 'All Visuals' ? (
              <Tooltip
                content={`p-value: ${parseFloat(pValue).toFixed(2)}`}
                className="frequency-table__tooltip"
                follow
              >
                <p>{label.performance_percent}%</p>
              </Tooltip>
            ) : (
              <p>{label.performance_percent}%</p>
            )}
          </div>
        );
      },
    },
    {
      Header: 'Average Performance Score',
      accessor: 'average_performance_score',
      // tipText: text.visuals,
      Cell: ({ value }) => (
        // <Tooltip
        //   content="The average amount of spend going to this label across all advertisers in the Darwin Index."
        //   className="frequency-table__tooltip"
        //   follow
        // >
        <p>{value ? toFixed_2(value) : '-'}</p>
        // </Tooltip>
      ),
    },
    {
      Header: 'Wins/All',
      accessor: 'wins_over_all',
      disableFilters: true,
      tipText: text.wins_over_all,
      Cell: ({
        row: {
          original: { numerator, denominator, performance_percent },
        },
      }) => (
        <p>
          <span
            style={{ color: performance_percent >= 50 ? '#2C9C81' : '#C35A80' }}
          >
            {numerator}
          </span>
          /{denominator}
        </p>
      ),
    },
    {
      Header: '# of Unique Creatives',
      accessor: 'number_unique_creatives',
      disableFilters: true,
      tipText: text.number_unique_creatives,
    },
    {
      Header: '% of Creative Count',
      accessor: 'percent_of_creative_count',
      disableFilters: true,
      tipText: text.percent_of_creative_count,
    },
    {
      Header: 'Visuals',
      accessor: 'visuals',
      minWidth: 250,
      tipText: text.visuals,
      Cell: ({
        row: {
          original: {
            winners,
            losers,
            testing_opportunity,
            category,
            type,
            is_compare,
            udc,
          },
        },
      }) => {
        if (testing_opportunity)
          return <p className="testing-opportunity">Testing opportunity</p>;
        return (
          <SquaresList
            {...{ winners, losers, category }}
            label_type={type}
            is_compare={is_compare}
            udc={udc}
          />
        );
      },
      disableFilters: true,
    },
    {
      Header: 'Planning',
      accessor: 'planning',
      // tipText: text.visuals,
      Cell: PlanningSelect,
    },
  ];
};

export const useColumnDropdownOptions = ({
  isROAS,
  isDirectMail,
  clientMetrics = [],
  gaEnabled,
  customEvents,
  additional_metrics = [],
  customIntegratedMetrics = [],
  isOrganicView,
  account_id,
  isEmailView,
  customScoringData,
}) => {
  const isUBeauty = account_id === 'act_478482719415309';
  const isGoHealth = account_id === 'act_637485863314808';

  const clientMetricsLabels =
    ((isDirectMail || isOrganicView) &&
      clientMetrics?.map((metric) => {
        if (isOrganicView) {
          return [
            {
              label: 'Average ' + metric,
              value: 'Average ' + metric,
            },
            {
              label: 'Total ' + metric,
              value: 'Total ' + metric,
            },
          ];
        }

        if (isEmailView) {
          return [
            {
              label: metric,
              value: metric,
            },
          ];
        }

        return [
          {
            label: metric,
            value: 'Aggregate ' + metric,
          },
          // {
          //   label: 'Average ' + metric,
          //   value: 'Average ' + metric,
          // },
          {
            label: 'Median ' + metric,
            value: 'Median ' + metric,
          },
        ];
      })) ||
    [];

  const cpaClientMetrics = isROAS
    ? []
    : [
        {
          label: `% of ${isEmailView ? 'Placed Orders' : 'Results'}`,
          value: 'percent_of_results',
        },
        {
          label: `% of ${isEmailView ? 'Placed Orders' : 'Results'} Difference`,
          value: 'percent_of_results_diff',
        },
      ];

  const gaLabels = gaEnabled
    ? [
        {
          label: 'GA CTR',
          value: 'ga_aggregate_ctr',
        },
        // {
        //   label: 'Average GA CTR',
        //   value: 'ga_average_ctr',
        // },
        {
          label: 'Median GA CTR',
          value: 'ga_median_ctr',
        },
      ]
    : [];

  const customEventsLabels = customEvents
    ? customEvents.map((event) => {
        return [
          {
            label: event.name,
            value: event.name,
          },
          {
            label: 'Median ' + event.name,
            value: 'Median ' + event.name,
          },
        ];
      })
    : [];

  let additionalMetricsOpts = isGoHealth
    ? [
        {
          label: `Cost per Opp Attempted`,
          value: 'cp_offsite_conversion.fb_pixel_custom.OppAttempted',
        },
        {
          label: `Median cost per Opp Attempted`,
          value: 'median_cp_offsite_conversion.fb_pixel_custom.OppAttempted',
        },
      ]
    : additional_metrics.map((metric) => {
        return [
          {
            label: metric.name.replace('Value ', ''),
            value: metric.value,
          },
          {
            label: `Cost per ${metric.name}`,
            value: `cp_${metric.value}`,
          },
          {
            label: `Median ${metric.name}`,
            value: `median_${metric.value}`,
          },
          {
            label: `Median cost per ${metric.name}`,
            value: `median_cp_${metric.value}`,
          },
        ];
      });

  const customScoringOpts = customScoringData?.length
    ? CUSTOM_SCORING_METRICS.map((m) => ({ label: m, value: m }))
    : [];

  if (isUBeauty) {
    const conversionsMetric = additional_metrics.find((m) =>
      m.name.includes('Conversions')
    );

    additionalMetricsOpts = [];

    const update = [
      {
        label: 'Rockerbox Revenue',
        value: 'value_Rockerbox_ROAS',
      },
      {
        label: `Rockerbox Roas`,
        value: `Rockerbox_Roas`,
      },
      {
        label: `${conversionsMetric.name}`,
        value: `${conversionsMetric.value}`,
      },
      {
        label: `Cost per ${conversionsMetric.name}`,
        value: `cp_${conversionsMetric.value}`,
      },
      {
        label: `Median cost per ${conversionsMetric.name}`,
        value: `median_cp_${conversionsMetric.value}`,
      },
    ];

    additionalMetricsOpts = update;
  }

  const customIntegratedMetricsOpts = !isUBeauty
    ? customIntegratedMetrics?.map((metric) => {
        return [
          {
            label: metric.label,
            value: metric.value,
          },
          {
            label: 'Median ' + metric.label,
            value: 'median_' + metric.label.toLowerCase().replace(/\s+/g, '_'),
          },
          {
            label: `Cost per ${metric.label}`,
            value: `cp_${metric.value}`,
          },
          {
            label: `Median cost per ${metric.label}`,
            value: `median_cp_${metric.value}`,
          },
        ];
      }) || []
    : [];

  let result = [
    {
      label: 'Visuals',
      value: 'visuals',
    },
    {
      label: 'Planning',
      value: 'planning',
    },
    ..._.flatten(customScoringOpts),
    ..._.flatten(clientMetricsLabels),
    {
      label: isROAS ? 'ROAS' : 'CPA',
      value: isROAS ? 'aggregate_roas' : 'aggregate_cpa',
    },
    // {
    //   label: isROAS ? 'Average ROAS' : 'Average CPA',
    //   value: isROAS ? 'average_roas' : 'average_cpa',
    // },
    {
      label: isROAS ? 'Median ROAS' : 'Median CPA',
      value: isROAS ? 'median_roas' : 'median_cpa',
    },
    {
      label: 'Total Results',
      value: 'total_results',
    },
    ..._.flatten(cpaClientMetrics),
    {
      label: 'CPM',
      value: 'aggregate_cpm',
    },
    // {
    //   label: 'Average CPM',
    //   value: 'average_cpm',
    // },
    {
      label: 'Median CPM',
      value: 'median_cpm',
    },
    {
      label: 'CPM-Reach',
      value: 'cpm_reach',
    },
    {
      label: 'CPC',
      value: 'aggregate_cpc',
    },
    // {
    //   label: 'Average CPC',
    //   value: 'average_cpc',
    // },
    {
      label: 'Median CPC',
      value: 'median_cpc',
    },
    {
      label: 'CTR',
      value: 'aggregate_ctr',
    },
    {
      label: 'Average CTR',
      value: 'average_ctr',
    },
    {
      label: 'Median CTR',
      value: 'median_ctr',
    },
    {
      label: 'Total Link Clicks',
      value: 'total_link_clicks',
    },
    {
      label: 'Total Impressions',
      value: 'total_impressions',
    },
    {
      label: '% of Link Clicks',
      value: 'percent_of_link_clicks',
    },
    {
      label: '% of Link Click Difference',
      value: 'percent_of_link_clicks_diff',
    },
    {
      label: '% Spend',
      value: 'percent_budget',
    },
    {
      label: 'Spend',
      value: 'spend',
    },
    ..._.flatten(customEventsLabels),
    ..._.flatten(gaLabels),
    ..._.flatten(additionalMetricsOpts),
    ..._.flatten(customIntegratedMetricsOpts),
    {
      label: 'Win/Loss p-value',
      value: 'p_value',
      alwaysShow: true,
    },
    {
      label: 'Average Performance Score',
      value: 'average_performance_score',
    },
    {
      label: 'Distribution p-value',
      value: 'dist_p_value',
      alwaysShow: true,
    },
    {
      label: 'Darwin index',
      value: 'darwin_index',
    },
    {
      label: `Average ${isROAS ? 'ROAS' : 'CPA'} Quintile`,
      value: `${isROAS ? 'roas' : 'cpa'}_quantile`,
    },
    {
      label: `Index ${isROAS ? 'ROAS' : 'CPA'} Quintile`,
      value: `average_${isROAS ? 'roas' : 'cpa'}_quintile`,
    },
    {
      label: 'Index % Spend',
      value: 'average_percent_spend',
    },
    {
      label: 'Win rate',
      value: 'performance_percent',
    },
    {
      label: 'Wins/All',
      value: 'wins_over_all',
      alwaysShow: true,
    },
    {
      label: '# Unique Creatives',
      value: 'number_unique_creatives',
    },
    {
      label: '% of Creative Count',
      value: 'percent_of_creative_count',
    },
  ];

  if (isOrganicView) {
    result = result.filter(
      ({ value }) => !nonOrganicCols.some((v) => value.indexOf(v) >= 0)
    );
  }

  if (isEmailView) {
    result = result.filter(
      ({ value }) => !nonEmailCols.some((v) => value.indexOf(v) >= 0)
    );
  }

  return result.filter(
    (v, i, a) => a.findIndex((v2) => v2.value === v.value) === i
  );
};
