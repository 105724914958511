import React, { useEffect } from 'react';
import { useAnalyticsFetch } from '../../store/CreativeAnalytics/api';
import CreativeAnalytics from './CreativeAnalytics';
import { useMetadataApi } from '../DNA/api';
import { useViewGroupContext } from '../ViewGroup/context';

export const Wrapper = (props) => {
  // useAnalyticsFetch();
  useMetadataApi();
  const { mainView } = useViewGroupContext();
  const { view_id } = props;

  if (mainView?.viewId === view_id) return null;

  return <CreativeAnalytics {...props} />;
};
