import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { CloseIcon, ErrorIcon, WarningIcon } from '../../../components/Icons';
import CollapsibleItem from '../../../components/CollapsibleItem/CollapsibleItem';
import CommonElements from './CommonElements/CommonElements';
import { ChevronDownIcon } from '../ReportCard/icons';
import TopSpending from './TopSpending/TopSpending';
import { LoadingIcon } from '../ReportCard/Suggestions/icons';
import Tooltip from '../../../components/Tooltip';
import { RetryButton } from './components/RetryButton';
import Button from '../../../components/Button';
import { useCreativeAnalyticsGet } from '../contexts';
import { SimpleImpactReport } from './SimpleImpact';
import { Heatmap } from './Heatmap';
import RecsList from './RecsList/RecsList';
import { Facts } from './Facts';

const Header = ({ close }) => {
  return (
    <div className="recs-header">
      <span className="recs-header__title">Analytics Intelligence</span>
      <div className="d-flex">
        <button className="recs-header__icon btn-reset" onClick={close}>
          <i className="fa fa-xmark" />
        </button>
      </div>
    </div>
  );
};

const LoadingDisplay = () => {
  return (
    <div className="recs__loading loading-text">Getting recommendations</div>
  );
};

const ErrorDisplay = ({ error }) => {
  return (
    <div className="recs__error">
      <ErrorIcon className="mr-2" />
      <h4>There was an error.</h4>
      <p>{error?.error?.toString()}</p>
    </div>
  );
};

const Section = ({ title, loading, onClick }) => {
  return (
    <div className={`recs__section d-flex`} onClick={onClick}>
      <div className={`recs__section-loading`}>
        {loading && <LoadingIcon />}
      </div>
      <div className="d-flex">
        <div className={`recs__section-title`}>{title}</div>
        <ChevronDownIcon />
      </div>
    </div>
  );
};

export const Layout = ({
  recs,
  loading,
  error,
  close,
  fetchRecs,
  commonElementsProps,
  topSpendingProps,
  isROAS,
  fetchSuggestions,
  fetchCommonElements,
  display,
  selectedVisualsChanged,
  selectedVisuals,
  setCommonElementsIsOpen,
}) => {
  const { primary_metric, planningMode } = useCreativeAnalyticsGet();
  let Render = (
    <RecsList
      {...{
        recs,
        fetchRecs,
        loading,
        selectedVisualsChanged,
        selectedVisuals,
      }}
    />
  );

  if (loading) Render = <LoadingDisplay />;
  if (error) Render = <ErrorDisplay error={error} />;

  const HowToImproveSection = (
    <CollapsibleItem
      AnchorChild={
        <Section
          title={
            'What are my top spending assets and how do I improve upon them?'
          }
          loading={
            topSpendingProps.winnerSuggestionsLoading ||
            topSpendingProps.loserSuggestionsLoading
          }
        />
      }
      ExpandableChild={
        <TopSpending {...{ topSpendingProps, isROAS, fetchSuggestions }} />
      }
      className={null}
    />
  );

  const RecommendationsSection = (
    <CollapsibleItem
      id="test-more-test-less"
      AnchorChild={
        <Section
          title={
            'What should I be testing more of? What should I be testing less of?'
          }
          loading={loading}
        />
      }
      ExpandableChild={Render}
      className={null}
      expandOnLoad={true}
    />
  );

  const CommonElementsSection = (
    <CollapsibleItem
      AnchorChild={
        <Section
          title={
            'What do my top performers have in common? What about my bottom performers?'
          }
          loading={
            commonElementsProps.winnersLoading ||
            commonElementsProps.losersLoading ||
            commonElementsProps.allAssetsLoading
          }
        />
      }
      ExpandableChild={
        <CommonElements {...{ commonElementsProps, fetchCommonElements }} />
      }
      className={null}
      expandOnLoad={false}
      onToggle={(collapsed) => setCommonElementsIsOpen(!collapsed)}
    />
  );

  const SimpleImpactSection = (
    <CollapsibleItem
      AnchorChild={
        <Section
          title={`Which categories have the most impact on my performance?`}
        />
      }
      ExpandableChild={<SimpleImpactReport {...{ primary_metric }} />}
      className={null}
      expandOnLoad={planningMode ? true : false}
    />
  );

  const HeatMapSection = (
    <CollapsibleItem
      AnchorChild={
        <Section title={`What should I be focusing my testing efforts on?`} />
      }
      ExpandableChild={<Heatmap {...{ primary_metric }} />}
      className={null}
      expandOnLoad={false}
    />
  );

  const FactsSection = (
    <CollapsibleItem
      AnchorChild={
        <Section
          title={`What are some insights into my data that I need to know?`}
        />
      }
      ExpandableChild={<Facts />}
      className={null}
      expandOnLoad={false}
    />
  );

  const Sections = planningMode ? (
    <>{SimpleImpactSection}</>
  ) : display === 'dna_table' ? (
    <>
      {RecommendationsSection}
      {/* {FactsSection} */}
    </>
  ) : (
    <>
      {RecommendationsSection}
      {SimpleImpactSection}
      {HeatMapSection}
      {HowToImproveSection}
      {CommonElementsSection}
      {/* {FactsSection} */}
    </>
  );

  return (
    <div className="recs">
      <div className="recs-wrapper">
        <Header close={close} />
        {Sections}
      </div>
    </div>
  );
};
