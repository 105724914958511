import React, { useEffect, useState } from 'react';
import Presets from './Presets';
import { useCreativeAnalyticsGet } from '../contexts';
import { useViewSettings } from '../../../selectors';
import {
  notifyError,
  useNotifications,
} from '../../../components/Notification/useNotifications';

const Wrapper = () => {
  const {
    presetsApi,
    selectedLabels,
    filteredData,
    groupByAdName,
    defaultPfrColumns,
    defaultDnaColumns,
    isROAS,
    isOrganicView,
  } = useCreativeAnalyticsGet();
  const { viewId: view_id, darwin_client_id: client_id } = useViewSettings();
  const initialState = {
    preset_name: '',
    preset_description: '',
    fix_dates: true,
    is_default: false,
    url: '',
  };
  const [presets, setPresets] = useState([]);
  const [current, setCurrent] = useState(initialState);
  const loading = presetsApi.post.loading;
  const getLoading = presetsApi.get.loading;
  const notifications = useNotifications();
  const [showSaved, setShowSaved] = useState(false);

  useEffect(() => {
    setCurrent((prev) => ({
      ...prev,
      url: window.location.href.slice(
        window.location.href.lastIndexOf('/') + 1
      ),
    }));
  }, [window.location.href]);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (presetsApi.get.data) {
      let { data } = presetsApi.get;

      if (typeof data === 'string') {
        setPresets([]);
      }

      if (data.body) {
        data = data.body;
      }

      if (Array.isArray(data)) {
        setPresets(data);
        setShowSaved(true);
      }
    }
  }, [presetsApi.get.data]);

  useEffect(() => {
    if (presetsApi.post.data) {
      const { data } = presetsApi.post;

      if (data) {
        if (data.includes('Successfully Created')) {
          setCurrent(initialState);

          notifications.remove('post-toast');

          notifications.add({
            id: 'success-toast',
            title: 'Success!',
            message: 'Preset saved successfully.',
            toastProps: {
              autoClose: 3000,
              hideProgressBar: false,
            },
          });
        }

        fetchData();
      }
    }
  }, [presetsApi.post.data]);

  useEffect(() => {
    if (presetsApi.post.error) {
      notifications.remove('post-toast');
      try {
        notifyError(current.preset_name, presetsApi.error.error.toString());
      } finally {
        console.error(presetsApi.error);
      }
    }
  }, [presetsApi.post.error]);

  const fetchData = () => {
    presetsApi.get.request({
      data: {
        client_id,
        view_id,
      },
      method: 'GET',
    });
  };

  const handleCreate = () => {
    let url = current.url;

    if (!current.fix_dates) {
      url = url
        .replace(/(period1=)(.+?)(?=&period2)/, '$1')
        .replace(/(period2=)(.+?)(?=&rc)/, '$1');
    }

    const payload = {
      client_id,
      view_id,
      preset_name: current.preset_name,
      preset_description: current.preset_description,
      fix_dates: false,
      is_default: current.is_default,
      url: url,
    };

    notifications.add({
      id: 'post-toast',
      title: 'Creating preset',
      message: 'Please wait',
      showEllipses: true,
    });

    presetsApi.post.request({
      data: payload,
      method: 'POST',
    });
  };

  const handleDelete = (id) => {
    presetsApi.post.request({
      data: {
        client_id,
      },
      method: 'DELETE',
      _id: id,
    });
  };

  const handleInput = (field, value) => {
    if (field === 'preset_name') {
      setCurrent((prev) => ({
        ...prev,
        preset_name: value,
      }));
    }

    if (field === 'preset_description') {
      setCurrent((prev) => ({
        ...prev,
        preset_description: value,
      }));
    }
  };

  const handleChecked = (field) => {
    if (field === 'fix_dates') {
      setCurrent((prev) => ({
        ...prev,
        fix_dates: current.fix_dates ? false : true,
      }));
    }

    if (field === 'is_default') {
      setCurrent((prev) => ({
        ...prev,
        is_default: current.is_default ? false : true,
      }));
    }
  };

  return (
    <div>
      <Presets
        {...{
          presets,
          current,
          handleInput,
          handleChecked,
          selectedLabels,
          handleCreate,
          handleDelete,
          filteredData,
          loading,
          getLoading,
          view_id,
          groupByAdName,
          defaultPfrColumns,
          defaultDnaColumns,
          showSaved,
          setShowSaved,
        }}
      />
    </div>
  );
};

export default Wrapper;
