import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

const Ctx = createContext(null);

export function ReportCardProvider({
  current,
  categories,
  reportCardSettings,
  reportCardPreferences,
  categorySettings,
  dispatchReportCardSettings,
  saving_settings,
  data_type,
  selected_metric,
  metric_order,
  metricsData,
  isBestPerformingView,
  filteredMetrics,
  changesMade,
  setChangesMade,
  showConfirmExit,
  setShowConfirmExit,
  fetchPhraseBuilderData,
  refreshing,
  setRefreshing,
  setShouldHaveData,
  recDepth,
  setRecDepth,
  showMenu,
  setShowMenu,
  fixedLabels,
  setFixedLabels,
  isAdvanced,
  setIsAdvanced,
  attemptedDepth,
  setAttemptedDepth,
  recsErrorDisplay,
  setRecsErrorDisplay,
  isCompetitorView,
  isOrganicView,
  primary_metric,
  children,
}) {
  return (
    <Ctx.Provider
      value={{
        current,
        categories,
        categorySettings,
        reportCardSettings,
        dispatchReportCardSettings,
        reportCardPreferences,
        saving_settings,
        data_type,
        selected_metric,
        metric_order,
        metricsData,
        isBestPerformingView,
        filteredMetrics,
        changesMade,
        setChangesMade,
        showConfirmExit,
        setShowConfirmExit,
        fetchPhraseBuilderData,
        refreshing,
        setRefreshing,
        setShouldHaveData,
        recDepth,
        setRecDepth,
        showMenu,
        setShowMenu,
        fixedLabels,
        setFixedLabels,
        isAdvanced,
        setIsAdvanced,
        attemptedDepth,
        setAttemptedDepth,
        recsErrorDisplay,
        setRecsErrorDisplay,
        isCompetitorView,
        isOrganicView,
        primary_metric,
      }}
    >
      {children}
    </Ctx.Provider>
  );
}

export function useReportCardContext() {
  return useContext(Ctx);
}
