import React, { useState, useEffect, useMemo, useReducer } from 'react';
import './ReportCard.scss';

import { useDispatch, useSelector } from 'react-redux';
import { trackEvent } from '../../../store/UserActivity/actions';
import {
  CreativeAnalyticsEvents,
  CREATIVE_ANALYTICS,
} from '../../../store/UserActivity/constants';

import Header from './Header';
import Display from './Display';
import CategoriesList from './CategoriesList';
import { getCategoryLabels } from './CategoriesList/CategoriesList';

import { getSuggestions } from './utils';
import Suggestions from './Suggestions';
import { useGetReportCardRecs } from '../PerformanceFrequency/api';
import { useAccountId } from '../selectors';
import { useViewId } from '../../../components/Hooks/view-id';
import { toDarwinDate } from '../../../utils/darwin-dates';
import RequestRevision from './RequestRevision';
import Overlay from '../../../components/Overlay';
import { ReportCardProvider } from './contexts';
import { useReportCardCategories } from './hooks';
import { useCreativeAnalyticsGet, useCreativeAnalyticsSet } from '../contexts';
import { useReportCardMetrics } from './PerformanceStats/hooks';
import { getFilteredMetrics } from '../VisualLibrary/LibraryStats/filtered-metrics';
import {
  REPORT_CARD,
  PHRASE_BUILDER,
  OVERVIEW,
  DETAILS,
  CATEGORIES,
} from './constants';
import PhraseBuilder from '../PhraseBuilder';
import LoadingAnvil from '../../../components/Helpers/LoadingAnvil';
import { usePhraseBuilderApi } from '../PhraseBuilder/api';
import PhraseBuilderModal from '../PhraseBuilder/Modal';
import { Results } from '../../CreativeStudio/Predictions/Predictions';
import Nav from './Nav';
import AssetDetails from './AssetDetails';
import { useWindowSize } from '../PerformanceFrequency/hooks';
import { usePerformanceMetrics } from '../VisualLibrary/LibraryStats/hooks';
import KeywordSettings from './KeywordSettings';
import { useDNAColumnOptions } from '../../DNA/useDNAColumnOptions';
import {
  getCategories,
  useSimpleImpactApi,
} from '../Recommendations/SimpleImpact';
import { getRows } from '../../DNA/utils';
import { useCategories } from '../hooks';
import { useCreativeAnalyticsApis } from '../api';
import {
  makeDnaTableData,
  makeImpactReportData,
  makeSummaryData,
} from '../CreativeCopilot/utils';

const ReportCard = ({
  Header,
  Display,
  loading,
  isPhraseBuilder,
  setReportCardMode,
  phraseData,
  phraseBuilderApi,
  refreshing,
  Suggestions,
  Predictions,
  Nav,
  activePage,
  AssetDetails,
  Categories,
  isBestPerformingView,
  currentFormat,
  isOrganicView,
  visual_hash,
  recsApi,
}) => {
  let Content = (
    <>
      {recsApi.post.loading ? (
        <div className="bounce-loading main">
          <span>Loading</span>
          <span className="dot"></span>
          <span className="dot"></span>
          <span className="dot"></span>
        </div>
      ) : (
        <>
          <>
            {!isBestPerformingView && Predictions}
            {!isOrganicView && Suggestions}
          </>
          <>{AssetDetails}</>
          <>{Categories}</>
        </>
      )}
    </>
  );

  if (isPhraseBuilder) {
    if (phraseBuilderApi.get.error || !Object.keys(phraseData).length) {
      Content = (
        <h4 className="p-5">
          There was an error loading the Phrase Builder. Go back to the{' '}
          <span
            onClick={() => setReportCardMode(REPORT_CARD)}
            style={{
              textDecoration: 'underline',
              color: '#039BE5',
              cursor: 'pointer',
            }}
          >
            Report Card
          </span>
          .
        </h4>
      );
    } else {
      Content = (
        <PhraseBuilder
          data={phraseData}
          error={phraseBuilderApi.get.error}
          phraseBuilderApi={phraseBuilderApi}
          visual_hash={visual_hash}
        />
      );
    }
  }

  const viewport = useWindowSize();

  let Render = (
    <>
      {isPhraseBuilder && phraseBuilderApi.get.loading && !refreshing ? (
        <LoadingAnvil />
      ) : (
        <>
          {!isBestPerformingView && (
            <div
              id="main-display"
              className={`display ${
                isPhraseBuilder
                  ? 'col-lg-4'
                  : viewport.width < 1400
                  ? 'col-12 col-lg-5 col-xl-5'
                  : 'col-xxl-6'
              }`}
            >
              {Display}
            </div>
          )}
          <div
            id="main-content"
            className={`content ${
              isPhraseBuilder
                ? 'col-lg-8'
                : isBestPerformingView
                ? 'col-lg-6 best-performing'
                : viewport.width < 1400
                ? 'col-12 col-lg-7 col-xl-7'
                : 'col-xxl-6'
            }`}
          >
            {Content}
          </div>
        </>
      )}
    </>
  );

  if (loading)
    Render = (
      <h3 className="performance-frequency__loading m-3">
        Loading report card
      </h3>
    );

  return (
    <>
      {Header}
      <div
        className={`report-card ${
          isPhraseBuilder ? 'phrase-builder' : 'container'
        }`}
      >
        {!isPhraseBuilder && (
          <div id="nav-row" className="row">
            <div className="col-12">{Nav}</div>
          </div>
        )}
        <div
          id="content-row"
          className={`row ${
            currentFormat !== 'Video' ? 'without-buttons' : ''
          }`}
        >
          {Render}
        </div>
      </div>
    </>
  );
};

export type ReportCardProps = {
  visual_hash: string;
  setParams: (params: any) => void;
  visualsLookup: VisualsLookup;
  spendThreshold: string;
  start_date: string;
  end_date: string;
  selectedAudience: string;
  insights_by_asset: boolean;
  filteredData: any[];
};

const ReportCardWrapper = ({
  isBestPerformingView,
  visual_hash,
  depth,
  fixed,
  setParams,
  visualsLookup,
  spendThreshold,
  start_date,
  end_date,
  selectedAudience,
  ReportCardPortal,
  revisionRequestApi,
  isROAS,
  fromCreativeStudio,
  setReportCardDataType,
  csViewId,
  isDirectMail = false,
  viewName,
  setReportCardMetric,
  reportCardPrefsApi,
  setRcCategorySettings,
  gaEnabled,
  reportCardMode,
  setReportCardMode,
  isTemp = false,
  filteredVisuals,
  primary_metric,
  columns,
  handleColumns,
  dispatchReportCardSettings,
}) => {
  const dispatch = useDispatch();
  const {
    reportCardSettings,
    reportCardMetric,
    clientMetrics,
    customEvents,
    filteredData,
    allVisuals,
    metricOptions,
    filteredAllVisualsLabel,
    period1,
    period2,
  } = useCreativeAnalyticsGet();
  const { handleSelectElement } = useCreativeAnalyticsSet();
  const {
    categorySettings,
    settings_changed,
    data_type,
    metric_options,
    selected_metric,
    metric_order,
  } = reportCardSettings;
  const [showCategoryDetails, setShowCategoryDetails] = useState<string | null>(
    null
  );
  const [recData, setRecData] = useState(null);
  const [recDepth, setRecDepth] = useState(1);
  const [fixedLabels, setFixedLabels] = useState([]);
  const [showMenu, setShowMenu] = useState(false);
  const [isAdvanced, setIsAdvanced] = useState(false);
  const [attemptedDepth, setAttemptedDepth] = useState(1);
  const [recsErrorDisplay, setRecsErrorDisplay] = useState('');
  const [revisionModal, dispatchRevisionModal] = useReducer(
    (state, action) => {
      switch (action.type) {
        case 'normal':
          return {
            ...state,
            show: true,
            suggestion: action.suggestion,
            opts: action.opts,
          };
        case 'close':
          return { show: false, suggestion: null };
        default:
          throw new Error('Invalid action type');
      }
    },
    { show: false, suggestion: null, opts: {} }
  );
  const recsApi = useGetReportCardRecs();
  const account_id = useAccountId();
  const view_id = fromCreativeStudio ? csViewId : useViewId();
  const [reportCardPreferences, setReportCardPreferences] = useState(null);
  const [phraseData, setPhraseData] = useState({});
  const [changesMade, setChangesMade] = useState(false);
  const [showConfirmExit, setShowConfirmExit] = useState(false);
  const [exit, setExit] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [shouldHaveData, setShouldHaveData] = useState(false);
  const [activePage, setActivePage] = useState(null);
  const [firstLoad, setFirstLoad] = useState(true);
  const [showKeywordSettingsModal, setShowKeywordSettingsModal] =
    useState(false);
  const isDemoClient = view_id === 'RM5mPMGqkyAFwDt9Ty';
  const { darwin_direct_enabled, dnaReportData } = useSelector(
    ({
      ViewSettings: { darwin_direct_enabled },
      CreativeAnalytics: { dnaReportData },
    }) => {
      return {
        darwin_direct_enabled,
        dnaReportData,
      };
    }
  );

  const isPhraseBuilder = reportCardMode === PHRASE_BUILDER;

  const current: VisualsLookupItem = visualsLookup[visual_hash] || {};

  const isCompetitorView =
    isDirectMail && viewName.toLowerCase().includes('competitor');
  const isOrganicView =
    isDirectMail && viewName.toLowerCase().includes('organic');

  const recsLoading = recsApi.post.loading;
  const recsError = recData && recData.Error;
  const saving_settings = reportCardPrefsApi.post.loading;

  const fetchPhraseBuilderData = () => {
    phraseBuilderApi.get.request({ visual: visual_hash, account_id });
  };
  const phraseBuilderApi = usePhraseBuilderApi(fetchPhraseBuilderData);

  // impact report stuff
  const simpleImpactApi = useSimpleImpactApi();
  const {
    request: simpleImpactRequest,
    data: simpleImpactData,
    loading: simpleImpactLoading,
    error: simpleImpactError,
  } = simpleImpactApi;

  const all_visuals_array = useMemo(() => {
    return filteredAllVisualsLabel?.visuals ?? [];
  }, [filteredAllVisualsLabel]);

  const categorical_columns = useMemo(
    () => getCategories(dnaReportData),
    [dnaReportData]
  );

  const [activeCategories, setActiveCategories] = useState(categorical_columns);

  useEffect(() => {
    setActiveCategories(categorical_columns);
  }, [view_id, period1, period2, dnaReportData]);

  const fetchImpactReportData = (data_list) => {
    simpleImpactRequest({
      data_list,
      primary_metric,
      categorical_columns: activeCategories,
    });
  };

  // summary data stuff
  const navCategories = useCategories();

  useEffect(() => {}, [navCategories]);

  const dnaColumnOptions = useDNAColumnOptions({
    isROAS,
    customEvents,
    clientMetrics,
  });
  const dnaRows = useMemo(() => {
    return getRows(all_visuals_array, dnaReportData, primary_metric);
  }, [all_visuals_array, dnaReportData, primary_metric]);

  useEffect(() => {
    if (dnaRows.length && categorical_columns.length) {
      fetchImpactReportData(dnaRows);
    }
  }, [dnaRows]);

  // copilot stuff
  useEffect(() => {
    getSettingsRequest(view_id);
  }, []);

  const { copilotApi } = useCreativeAnalyticsApis();
  const {
    data: copilotData,
    error: copilotError,
    loading: copilotLoading,
    request: copilotRequest,
  } = copilotApi.post;
  const {
    data: getSettingsData,
    error: getSettingsError,
    loading: getSettingsLoading,
    request: getSettingsRequest,
  } = copilotApi.get;

  const [clientPreferences, setClientPreferences] = useState('');
  const [advertiserDetails, setAdvertiserDetails] = useState('');
  const [previousOutput, setPreviousOutput] = useState('');
  const [userFeedback, setUserFeedback] = useState('');

  const copilotRequestSuccess =
    copilotData && copilotData.recommendation_summary;
  const copilotRequestError =
    copilotError || (copilotData && !copilotData.recommendation_summary);

  useEffect(() => {
    if (getSettingsData) {
      setClientPreferences(getSettingsData[0].client_preferences);
      setAdvertiserDetails(getSettingsData[0].advertiser_details);
    }
  }, [getSettingsData]);

  useEffect(() => {
    if (copilotRequestSuccess) {
      setPreviousOutput(copilotData.recommendation_summary);
    }
  }, [copilotRequestSuccess]);

  const fetchCopilot = () => {
    const dnaTableData = makeDnaTableData(
      dnaRows,
      dnaColumnOptions.map(({ value }) => value)
    );

    let payload = {
      PRIMARY_METRIC: primary_metric,
      ADVERTISER_DETAILS: advertiserDetails || 'None',
      CLIENT_PREFERENCES: clientPreferences || 'None',
      DNA_TABLE: [],
      IMPACT_REPORT: simpleImpactData.length
        ? makeImpactReportData(simpleImpactData)
        : {},
      SUMMARY_DATA: makeSummaryData(navCategories.navCategories, isROAS),
      INDEX_DATA: false,
      IS_REVISION: previousOutput ? true : false,
      USER_INSTRUCTIONS:
        'Suggest three simple ways to improve the visual based on the data. It is CRITICAL that you bullet point each suggestion and add a top and bottom margin of 8px to each bullet.',
      PREVIOUS_OUTPUT: previousOutput || 'None',
      USER_FEEDBACK: userFeedback || 'None',
      REQUEST_TYPE: 'creative request',
      visual_example_included: current.data.asset_url ? true : false,
      visual_example_labels: current.labels
        .filter(
          ({ name }) =>
            !['All Visuals, All New Visuals, Top 10 Spending Visuals'].includes(
              name
            )
        )
        .map((label) => `${label.category}: ${label.name}`),
      visual_example_id: current.data.id || 'None',
    };

    if (current.data.visual_type === 'image') {
      payload = {
        ...payload,
        visual_example_url: current.data.asset_url,
      };
    }

    copilotRequest(payload);
  };

  useEffect(() => {
    if (!fromCreativeStudio && !isBestPerformingView)
      handleSelectElement(visual_hash, 'visual_hash', false);
  }, [fromCreativeStudio]);

  useEffect(() => {
    if (isPhraseBuilder) {
      fetchPhraseBuilderData();
    }
  }, [isPhraseBuilder]);

  useEffect(() => {
    if (phraseBuilderApi.get.data) {
      setPhraseData(phraseBuilderApi.get.data);
    }
  }, [phraseBuilderApi.get.data]);

  useEffect(() => {
    if (phraseBuilderApi.get.error) {
      console.error(phraseBuilderApi.get.error);
    }
  }, [phraseBuilderApi.get.error]);

  useEffect(() => {
    setReportCardDataType(data_type);
  }, [data_type]);

  useEffect(() => {
    setReportCardMetric(selected_metric);
  }, [selected_metric]);

  useEffect(() => {
    setRcCategorySettings(categorySettings);
  }, [categorySettings]);

  useEffect(() => {
    if (settings_changed) {
      reportCardPrefsApi.post.request({
        view_id,
        categories: categorySettings,
        type: data_type,
        selected_down_funnel_metric: selected_metric,
        metric_order,
      });
    }
  }, [settings_changed, data_type, categorySettings, selected_metric]);

  const bpCurrentVisuals = useMemo(() => {
    if (isBestPerformingView)
      return filteredVisuals.map(({ id }) => visualsLookup[id]);
    return [];
  }, [filteredVisuals]);

  const bpCurrentCategories = useMemo(() => {
    let allCategories = bpCurrentVisuals
      .map(({ categories }) => categories)
      .flat();
    let map = new Map(allCategories.map((o) => [o.category, o]));
    let unique = [...map.values()];
    return unique;
  }, [bpCurrentVisuals]);

  const categories = !isBestPerformingView
    ? useReportCardCategories(current, categorySettings)
    : useMemo(() => {
        if (!bpCurrentCategories) return [];
        if (!categorySettings || !categorySettings.length)
          return bpCurrentCategories;

        return categorySettings.reduce((acc, category) => {
          const match = bpCurrentCategories.find(
            (c) => c.category === category.category_name
          );

          if (match && category.enabled.report_card) {
            acc.push(match);
          }

          return acc;
        }, []);
      }, [categorySettings, bpCurrentCategories]);

  const filteredCategories = useMemo(() => {
    return current && current.categories
      ? current.categories.filter((c) =>
          categorySettings.some(
            (cs) =>
              cs.category_name === c.category && cs.enabled.recommendations
          )
        )
      : [];
  }, [current, categorySettings]);

  const categoryLabels = !isBestPerformingView
    ? filteredCategories
        .map((category) => {
          return getCategoryLabels(
            current,
            category,
            fromCreativeStudio,
            false
          );
        })
        .flat()
        .map(({ categoryLabel }) => categoryLabel.name)
    : [];

  const formatLabel = !isBestPerformingView
    ? getCategoryLabels(
        current,
        current.categories.find((obj) => obj.category === 'Format'),
        fromCreativeStudio,
        false
      )
    : [];

  const currentFormat = formatLabel[0]?.categoryLabel.name || null;

  const recPayload = (depth) => {
    return {
      account_id: account_id,
      view_id,
      start_date: toDarwinDate(start_date),
      end_date: toDarwinDate(end_date),
      min_spend: parseInt(spendThreshold),
      doc_to_predict: categoryLabels,
      fixed_labels: fixedLabels,
      number_to_return: 3,
      recommendation_depth: depth,
      visual_hash: visual_hash,
    };
  };

  const fetchRecs = (depth, isAdvanced) => {
    recsApi.post.request(recPayload(depth));
    setAttemptedDepth(depth);
    setIsAdvanced(isAdvanced);
  };

  useEffect(() => {
    if (recsApi.post.data) {
      if (recsError) {
        console.error(recsError);
        setIsAdvanced(false);
        if (!firstLoad) setRecsErrorDisplay('There was an error.');
        return;
      }

      const {
        data: { data: data },
      } = recsApi.post;

      setRecData(data);
      setRecDepth(attemptedDepth);
      setParams({ depth: attemptedDepth, fixed: fixedLabels });
      setShowMenu(false);
      setRecsErrorDisplay(null);
      setFirstLoad(false);
    }
    handleDefaultPage();
  }, [recsApi.post.data, recsError]);

  const handleDefaultPage = () => {
    if (firstLoad) {
      if (
        (!isOrganicView &&
          darwin_direct_enabled &&
          !isBestPerformingView &&
          !fromCreativeStudio) ||
        isBestPerformingView
      ) {
        setActivePage(CATEGORIES);
      } else if (isOrganicView) {
        setActivePage(DETAILS);
      } else {
        setActivePage(CATEGORIES);
        setAttemptedDepth(null);
      }
      setFirstLoad(false);
    }
  };

  useEffect(() => {
    if (!isAdvanced) {
      setParams({ fixed: [] });
    }
  }, [fixedLabels, isAdvanced]);

  depth = parseInt(depth);

  // useEffect(() => {
  //   setRecDepth(depth);
  //   if (fixed.length) setFixedLabels(fixed.split(','));
  //   if (depth > 3 || fixed.length) {
  //     fetchRecs(depth, true);
  //     setIsAdvanced(true);
  //   } else {
  //     fetchRecs(depth, false);
  //   }
  // }, []);

  const bpFilteredCategories = useMemo(() => {
    return bpCurrentCategories
      ? bpCurrentCategories.filter((c) =>
          categorySettings.some(
            (cs) => cs.category_name === c.category && cs.enabled.report_card
          )
        )
      : [];
  }, [bpCurrentCategories, categorySettings]);

  const bpCurrent = useMemo(() => {
    return {
      categories: bpFilteredCategories,
      data: {},
    };
  }, [bpFilteredCategories]);

  const suggestions = useMemo(() => {
    if (!isBestPerformingView) {
      if (recDepth === 1 && current) {
        return getSuggestions(
          current,
          isROAS,
          categorySettings,
          data_type,
          fromCreativeStudio,
          false,
          primary_metric
        );
      } else if (recData && recDepth > 1) {
        return recData.mock_ad_list || [];
      }
    } else {
      return getSuggestions(
        bpCurrent,
        isROAS,
        categorySettings,
        data_type,
        fromCreativeStudio,
        true,
        primary_metric
      );
    }
  }, [
    current,
    recDepth,
    recData,
    recsLoading,
    categorySettings,
    data_type,
    fromCreativeStudio,
    bpCurrentVisuals,
  ]);

  const depthOptions = categoryLabels
    .map((v, i) => ({
      value: i + 1,
      label: (i + 1).toString(),
    }))
    .slice(1);

  useEffect(() => {
    if (visual_hash) {
      ReportCardPortal.show();
      dispatch(
        trackEvent(
          CREATIVE_ANALYTICS,
          CreativeAnalyticsEvents.LOAD_REPORT_CARD,
          { visual_hash }
        )
      );
    }
  }, [visual_hash]);

  const handleExit = (exitReportCard) => {
    setShowConfirmExit(true);
    setExit(exitReportCard);
  };

  const handleClose = (exitRc) => {
    if (exit || exitRc) {
      setParams({ rc: '', depth: '1', fixed: [] });
      ReportCardPortal.hide();
    }

    if (!fromCreativeStudio) {
      setShowConfirmExit(false);
      setReportCardMode(REPORT_CARD);
    }
  };

  const formatRecommendations = (suggestions) => {
    const recommendations = Array.isArray(suggestions)
      ? suggestions
      : [suggestions];
    return recommendations.map(({ category, current, change }) => {
      return { category, current, change };
    });
  };

  const handleQuickRevisionRequest = (suggestions, opts) => {
    let recommendations = [];
    let additional_message = null;

    if (opts.ai_suggestion) {
      additional_message = `${
        suggestions.recommendation_type || ''
      } Recommendation: ${suggestions.recommendation}, Reason: ${
        suggestions.why
      }${
        suggestions.visual_url ? `, Visual URL: ${suggestions.visual_url}` : ''
      }`;
    } else if (opts.designers_choice) {
      additional_message =
        'Allow the designer to choose which data points to incorporate into a revision.';
    } else {
      recommendations = formatRecommendations(suggestions);
    }

    revisionRequestApi.request({
      view_id,
      revision_type: 'darwin_talent',
      report_card_url: window.location.href,
      recommendations,
      ...(additional_message && { additional_message }),
    });
  };

  function trackPath(path) {
    dispatch(
      trackEvent(CREATIVE_ANALYTICS, CreativeAnalyticsEvents.LOAD_REPORT_CARD, {
        visual_hash,
        path_chosen: path,
      })
    );
    if (path !== 'Advanced') {
      setIsAdvanced(false);
    }
  }

  const predictions = useMemo(() => {
    if (!recData) {
      return {
        quintiles: {},
        score: '',
      };
    } else {
      return {
        quintiles: recData.quintile_definitions,
        score: recData.score_message,
      };
    }
  }, [recData]);

  const isVeterans = view_id === '8ayx03hP2XpVukqPNF';

  // list of possible metrics and their values
  const metricsData = !isBestPerformingView
    ? useReportCardMetrics({
        current,
        isROASClient: isROAS,
        isDirectMail,
        reportCardMetric,
        metricOptions: reportCardSettings.metric_options || [],
        clientMetrics,
        gaEnabled,
        customEvents,
        filteredData,
      })
    : usePerformanceMetrics({
        isDirectMail,
        filteredVisuals,
        allVisuals,
        isROAS,
        isVeterans,
        gaEnabled,
        viewName,
      });

  // filter based on which metrics are toggled on in settings
  const filteredMetrics = useMemo(() => {
    return getFilteredMetrics(
      metricsData,
      reportCardSettings,
      isDirectMail,
      viewName
    );
  }, [metricsData, reportCardSettings, isROAS, isDirectMail]);

  const randomQuintile = useMemo(() => _.random(2, 4), [visual_hash]);

  return (
    <ReportCardProvider
      {...{
        current,
        reportCardSettings,
        reportCardPreferences,
        categories,
        categorySettings,
        dispatchReportCardSettings,
        saving_settings,
        data_type,
        metric_options,
        selected_metric,
        metric_order,
        metricsData,
        filteredMetrics,
        changesMade,
        setChangesMade,
        showConfirmExit,
        setShowConfirmExit,
        fetchPhraseBuilderData,
        refreshing,
        setRefreshing,
        setShouldHaveData,
        recDepth,
        setRecDepth,
        showMenu,
        setShowMenu,
        fixedLabels,
        setFixedLabels,
        isAdvanced,
        setIsAdvanced,
        attemptedDepth,
        setAttemptedDepth,
        recsErrorDisplay,
        setRecsErrorDisplay,
        isCompetitorView,
        isOrganicView,
        primary_metric,
      }}
    >
      <ReportCardPortal.Portal>
        {revisionModal.show && !fromCreativeStudio && (
          <Overlay close={() => dispatchRevisionModal({ type: 'close' })}>
            <RequestRevision
              close={() => dispatchRevisionModal({ type: 'close' })}
              suggestions={revisionModal.suggestion}
              api={revisionRequestApi}
              options={revisionModal.opts}
            />
          </Overlay>
        )}
        {showConfirmExit && (
          <Overlay close={() => setShowConfirmExit(false)}>
            <PhraseBuilderModal
              handleClickAway={() => {
                return;
              }}
              show={true}
              handleSetShow={() => setShowConfirmExit(false)}
              handleConfirm={() => handleClose(exit)}
              message="Exit without saving changes?"
              className="exit-modal"
            ></PhraseBuilderModal>
          </Overlay>
        )}
        {showKeywordSettingsModal && (
          <KeywordSettings
            setShowKeywordSettingsModal={setShowKeywordSettingsModal}
            visual_hash={visual_hash}
            view_id={view_id}
            phraseData={phraseData}
            current={current}
          />
        )}
        <ReportCard
          loading={reportCardPrefsApi.get.loading}
          visual_hash={visual_hash}
          Header={
            <Header
              bestPerforming={isBestPerformingView}
              {...{
                spendThreshold,
                start_date,
                end_date,
                selectedAudience,
                data_type,
                metric_options,
                selected_metric,
                isROAS,
                isPhraseBuilder,
                setReportCardMode,
                handleClose,
                handleExit,
                fromCreativeStudio,
                primary_metric,
                setShowKeywordSettingsModal,
                primary_metric,
              }}
            />
          }
          Nav={
            <Nav
              {...{
                activePage,
                setActivePage,
                fromCreativeStudio,
                isOrganicView,
                fetchCopilot,
                copilotData,
                copilotRequestError,
                copilotLoading,
              }}
            />
          }
          Display={
            !isBestPerformingView ? (
              <Display
                {...{
                  current,
                  fromCreativeStudio,
                  currentFormat,
                }}
              />
            ) : null
          }
          Categories={
            <CategoriesList
              bestPerforming={isBestPerformingView}
              isRoas={isROAS}
              current={!isBestPerformingView ? current : bpCurrent}
              {...{
                categories,
                showCategoryDetails,
                setShowCategoryDetails,
                fromCreativeStudio,
                activePage,
              }}
            />
          }
          Suggestions={
            !isCompetitorView ? (
              <Suggestions
                bestPerforming={isBestPerformingView}
                {...{
                  suggestions,
                  recsLoading,
                  recsError,
                  predictions,
                  isROAS,
                  fromCreativeStudio,
                  isDirectMail,
                  viewName,
                  categories,
                  dispatchRevisionModal,
                  handleQuickRevisionRequest,
                  trackPath,
                  setParams,
                  depthOptions,
                  fetchRecs,
                  categoryLabels,
                  isDemoClient,
                  view_id,
                  start_date,
                  end_date,
                  darwin_direct_enabled,
                  spendThreshold,
                  visual_hash,
                  activePage,
                  copilotData,
                  copilotLoading,
                  copilotRequestError,
                  columns,
                  handleColumns,
                  fetchCopilot,
                  userFeedback,
                  setUserFeedback,
                  previousOutput,
                }}
              />
            ) : null
          }
          Predictions={
            isDemoClient && fromCreativeStudio ? (
              <div className="performance-predictions card show">
                <div className="subheader subheader--sm">
                  Performance Predictions
                </div>
                <Results quintile={randomQuintile} />
              </div>
            ) : null
          }
          AssetDetails={
            <AssetDetails
              {...{ metricsData, isBestPerformingView, activePage }}
            />
          }
          {...{
            showCategoryDetails,
            isDirectMail,
            viewName,
            isPhraseBuilder,
            setReportCardMode,
            phraseData,
            phraseBuilderApi,
            refreshing,
            activePage,
            isBestPerformingView,
            currentFormat,
            isOrganicView,
            recsApi,
          }}
        />
      </ReportCardPortal.Portal>
    </ReportCardProvider>
  );
};

export default ReportCardWrapper;
