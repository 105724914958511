import { useMemo, useState } from 'react';
import { useCreativeAnalyticsGet } from '../contexts';
import { getRows } from '../../DNA/utils';
import { useSelector } from 'react-redux';
import { usePreviousDateRangeData } from '../../DNA/PeriodComparison/api';

export const useVisualRows = () => {
  const { start_date, end_date } = useCreativeAnalyticsGet();
  const trendData: {
    data: [];
    status: 'success' | 'loading' | 'error';
    request: () => void;
  } = usePreviousDateRangeData();

  const dnaReportData = useSelector(
    ({ CreativeAnalytics: { dnaReportData } }) => dnaReportData
  );
  const { filteredAllVisualsLabel, primary_metric } = useCreativeAnalyticsGet();

  const all_visuals_array = useMemo(() => {
    return filteredAllVisualsLabel?.visuals ?? [];
  }, [filteredAllVisualsLabel]);

  ({ dnaReportData, trendData });
  const rows = useMemo(() => {
    return getRows(
      all_visuals_array,
      dnaReportData,
      primary_metric,
      trendData,
      start_date,
      end_date
    );
  }, [
    all_visuals_array,
    dnaReportData,
    primary_metric,
    trendData,
    start_date,
    end_date,
  ]);

  return { rows, fetchPrevPeriodData: () => trendData.request(), trendData };
};
