import React from 'react';
import { DEFAULT, FAVORITES } from '../constants';
import { FavoriteFillIcon, FavoriteOutlineIcon, LoadingIcon } from '../icons';
import { useSuggestionsContext } from '../contexts';

const Tabs = ({ handleTab, favoritesApiStatus }) => {
  const { activeTab, favoriteSuggestions, copilotLoading } =
    useSuggestionsContext();
  const { loading } = favoritesApiStatus;

  return (
    <div className="suggestions__tabs">
      <Tab active={activeTab === DEFAULT} onClick={() => handleTab(DEFAULT)}>
        <span>How to Improve</span>
      </Tab>
      {/* <Tab
        active={activeTab === FAVORITES}
        onClick={() => handleTab(FAVORITES)}
      >
        {loading ? (
          <LoadingIcon />
        ) : favoriteSuggestions.length ? (
          <FavoriteFillIcon />
        ) : (
          <FavoriteOutlineIcon />
        )}
        <span>Favorite Suggestions ({favoriteSuggestions.length})</span>
      </Tab> */}
      <Tab
        active={activeTab === 'reasons'}
        onClick={() => {
          if (!copilotLoading) handleTab('reasons');
        }}
      >
        <span>Reasons</span>
        {copilotLoading && (
          <i
            className="fas fa-circle-notch fa-spin ml-2"
            style={{ fontSize: '0.8em', marginTop: '2px' }}
          />
        )}
      </Tab>
    </div>
  );
};

export default Tabs;

const Tab = ({ active, onClick, children }) => {
  return (
    <div className={`tab ${active ? 'active' : ''} d-flex align-items-center`} onClick={onClick}>
      {children}
    </div>
  );
};
