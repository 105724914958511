import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useGetRequest } from '../../components/Hooks/useGetRequest';
import { useDispatch, useSelector } from 'react-redux';
import { TabList, Tab } from './Tabs';
import { MOCK } from './mock';
import StaticReport from '../StaticReport';
import { Link } from 'react-router-dom';

import './styles.scss';
import { isUrl } from '../../utils';
import { NestedView } from '../NestedView';
import { beginTokenFlow, useViewStatus } from '../../components/App/App';
import { setViewSettings } from '../../store/ViewSettings/actions';
import { useSearchParams } from '../../components/Hooks/search-params';
import { BasicError } from '../../components/Helpers/Error';

const getReportId = (url) => {
  if (!url?.length) return null;
  const regex = /\/static-report\/([a-f0-9]{64})/;
  const match = url.match(regex);
  return match ? match[1] : null;
};

const ReportGroup = ({ _id, exit, viewError }) => {
  const dispatch = useDispatch();
  const { getParams, setParams } = useSearchParams();
  const [, , idParam] = window.location.pathname.split('/');
  // const [, , report_group_id] = window.location.pathname.split('/');
  const report_group_id = useMemo(() => {
    return _id ? _id : idParam;
  }, [_id, idParam]);

  const api = useGetRequest(
    ['api', 'get-report-group', report_group_id].join('/'),
    true
  );
  const data = report_group_id === 'test' ? MOCK : api.data;

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const selectedTab = data?.[0]?.report_group_tabs?.find((tab, i) => {
    return selectedTabIndex === i;
  });
  const prev = useRef();

  useEffect(() => {
    if (prev.current !== report_group_id) {
      prev.current = report_group_id;
      api.request();
    }
  }, [report_group_id]);

  const handleSelectTab = (tabIndex) => {
    setSelectedTabIndex(tabIndex);
  };
  const reportUrlOrViewId = selectedTab?.report_url;

  const isStaticReport = isUrl(reportUrlOrViewId);
  const selectedReportId = getReportId(reportUrlOrViewId);
  const viewId =
    !isStaticReport && typeof reportUrlOrViewId === 'string'
      ? reportUrlOrViewId
      : null;

  const {
    loggedIn,
  } = useViewStatus();

  const prevViewId = useRef();

  const report_group_name = data?.[0]?.report_group_name;

  useEffect(() => {
    if (report_group_name) {
      dispatch(setViewSettings({ report_group_name }));
    }
  }, [report_group_name]);

  useEffect(() => {
    if (loggedIn) {
      if (prevViewId.current !== viewId) {
        setParams({
          selectedVisuals: '',
          columns: '',
          primary_metric: '',
        });
        if (viewId) {
          dispatch(
            setViewSettings({
              viewId,
              // viewName,
              // isLeverView,
              resetStore: true,
              initialView: false,
            })
          );
        }
        prevViewId.current = viewId;
      }
    }
  }, [viewId, loggedIn]);

  let Display = null;

  if (viewError) {
    Display = (
      <div className="m-3">
        <BasicError />
      </div>
    );
  } else if (selectedReportId) {
    if (isStaticReport) {
      Display = <StaticReport rid={selectedReportId} />;
    }
  } else if (viewId) {
    if (!loggedIn) {
      Display = (
        <div className="p-3">
          <p>You must be logged in to access this.</p>
          <Link to="/login">Login</Link>
        </div>
      );
    } else {
      Display = <NestedView viewId={viewId} />;
    }
  }

  beginTokenFlow(viewId);

  if (api.loading) {
    return <p className="m-3">Loading...</p>;
  } else if (api.error && !data) {
    return (
      <p className="m-3">
        There was an error fetching the report group.{' '}
        {api.error.error?.toString()}
      </p>
    );
  } else if (!data) {
    return <p className="m-3">Report group not found.</p>;
  }

  return (
    <div className="report-group">
      <TabList amount={data?.[0]?.report_group_tabs?.length ?? 0} exit={exit}>
        {data?.[0]?.report_group_tabs?.map((tab, i) => {
          return (
            <Tab
              isSelected={i === selectedTabIndex}
              label={tab.tab_name}
              onSelect={() => handleSelectTab(i)}
            ></Tab>
          );
        })}
      </TabList>

      <div className="report-group-content">
        {Display ?? <p className="m-3">Nothing to display.</p>}
      </div>
    </div>
  );
};

export default ReportGroup;
