import { useRef, useEffect } from 'react';

import { abortableFetch } from '../../../utils';
import {
  useApiState,
  bindFetchToRef,
} from '../../../components/Hooks/useApiState';
import { batch, useDispatch, useSelector } from 'react-redux';
import { setLoader } from '../../../store/Loaders/actions';
import { setError } from '../../../store/Errors/actions';
import { setRecommendationsReady } from '../../../store/CreativeAnalytics/creativeAnalyticsSlice';
import { useError, useLoading } from '../../../selectors';

export const PFR_RECOMMENDATIONS = 'CreativeAnalytics.Recommendations';

function getRecommendations(payload) {
  return abortableFetch(
    ['api', 'creative-analytics', 'get-recommendations'].join('/'),
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
      },
      body: JSON.stringify(payload),
    }
  );
}

export const useRecommendationsStatus = () => {
  const loading = useLoading(PFR_RECOMMENDATIONS);
  const error = useError(PFR_RECOMMENDATIONS);
  const ready = useSelector(
    ({ CreativeAnalytics: { recommendationsReady } }) => recommendationsReady
  );

  if (ready) return 'success';
  if (error) return 'error';
  if (loading) return 'loading';
  return null;
};

export function useRecommendationsApi() {
  const storeDispatch = useDispatch();
  const mounted = useRef(false);
  const withRef = bindFetchToRef(mounted);
  const [recommendations, postDispatch] = useApiState(
    (payload) => withRef(getRecommendations(payload)).then(({ data }) => data?.message || []),
    {
      data: null,
      error: null,
      loading: false,
      count: 0,
    }
  );

  useEffect(() => {
    if (recommendations.data) {
      batch(() => {
        storeDispatch(setLoader(PFR_RECOMMENDATIONS, false));
        storeDispatch(setError(PFR_RECOMMENDATIONS, false));
        storeDispatch(setRecommendationsReady(true));
      });
    }
  }, [recommendations.data]);

  useEffect(() => {
    if (recommendations.loading) {
      batch(() => {
        storeDispatch(setError(PFR_RECOMMENDATIONS, false));
        storeDispatch(setRecommendationsReady(false));
      });
    }
  }, [recommendations.loading]);

  useEffect(() => {
    if (recommendations.error) {
      batch(() => {
        storeDispatch(setLoader(PFR_RECOMMENDATIONS, false));
        storeDispatch(setError(PFR_RECOMMENDATIONS, true));
        storeDispatch(setRecommendationsReady(false));
      });
    }
  }, [recommendations.error]);

  useEffect(() => {
    mounted.current = true;

    return () => (mounted.current = false);
  }, []);

  return {
    ...recommendations,
    request: (payload) => {
      postDispatch(payload);
      storeDispatch(setLoader(PFR_RECOMMENDATIONS, true));
    },
  };
}
