import React, { useState, useEffect, useMemo, useRef } from 'react';
import _ from 'lodash';
import {
  LAST_MONTH,
  darwinDateToFormat,
  toDarwinDate,
} from '../../../utils/darwin-dates';
import { getJSON } from '../utils/getJSON';
import { LabelObject, MissingDataType } from '../types';
import { useInterval } from '../../../hooks/useInterval';
import { useDispatch } from 'react-redux';
import {
  setBannerDisplay,
  setMissingData,
  setPerformanceData,
  setRightDrawerDisplay,
  setStoredCompareData,
} from '../../../store/CreativeAnalytics/creativeAnalyticsSlice';
import {
  notifyError,
  useNotifications,
} from '../../../components/Notification/useNotifications';
import { useCreativeAnalyticsStore } from '../../../selectors';
import { groupLabelsByRatio } from '../utils/groupLabelsByRatio/groupLabelsByRatio';
import { useFetchPFRData } from './useFetchPFRData';
import { endOfMonth, startOfMonth } from 'date-fns';
import { useDeepCompare } from '../../../hooks/useDeepCompare';
import { useSelectedLabels } from './useSelectedLabels';
import { trackEvent } from '../../../store/UserActivity/actions';
import { HARD_REFRESH } from '../../../store/UserActivity/constants';
import { GENERAL_LOADING } from '../ImpactReport/constants';
import { groupByAdName } from '../utils/groupByAdName/groupByAdName';
import useTrackDeps from '../../../hooks/useTrackDeps';

const MOCK = {
  data: [],
  missing_data: [
    {
      value: 'labelqueue',
      label: 'Label Queue',
      visuals: 6,
      spend: 65.99,
    },
    {
      value: 'missing_permissions',
      label: 'Missing Permissions',
      visuals: 2,
      spend: 13.45,
    },
    {
      value: 'product_catalog',
      label: 'Product Catalog',
      visuals: 14,
      spend: 56934.55,
    },
  ],
};

type PerformanceDataProps = {
  view_id: string;
  account_id?: string;
  period1Start: string;
  period1End: string;
  selectedUdcLabels?: [];
};

export const usePerformanceData = ({
  performanceDataProps,
}: PerformanceDataProps) => {
  const {
    account_id,
    period1Start,
    period1End,
    period2Start,
    period2End,
    selectedUdcLabels,
    getUdcApi,
    keywordsApi,
    getDerivedLabelsApi,
    getAdNameLabelsApi,
    fetchCustomSettings,
    showActiveOnly,
    spendThreshold,
    only_new_ads,
    newAds,
    minResults,
    shouldGroupLabelsByRatio,
    customIntegratedMetrics,
    clientMetrics,
    additional_metrics,
    primary_metric,
    labelsParam,
    udc_labels,
    selectedAudience,
    refresh,
    loadingRcSettings,
  } = performanceDataProps;
  const dispatch = useDispatch();
  const [showLoading, setShowLoading] = useState<boolean>(false);

  const { data, loading, error, request, retrievedDate } = useFetchPFRData({
    start_date: period1Start,
    end_date: period1End,
    autoFetch: false,
    active_only: showActiveOnly,
    selectedUdcLabels: udc_labels,
  });

  const {
    data: compareData,
    loading: loadingCompare,
    request: requestCompareData,
  } = useFetchPFRData({
    start_date: period1Start,
    end_date: period1End,
    autoFetch: false,
    active_only: showActiveOnly,
    selectedUdcLabels: udc_labels,
  });

  const fetchPerformanceData = () => {
    request(refresh);
    getUdcApi.request({ account_id });
    keywordsApi.request({
      account_id,
      start_date: period1Start,
      end_date: period1End,
    });
    getAdNameLabelsApi.request(account_id);
    getDerivedLabelsApi.get.request(account_id);
  };

  function fetchCompareData() {
    if (period2Start && period2End) {
      requestCompareData();
    }
  }

  const mounted = useRef(false);

  useEffect(() => {
    if (mounted.current) {
      fetchPerformanceData();
    } else {
      // Skip fetch on initial mount
      mounted.current = true;
    }
  }, [showActiveOnly]);

  const prevDeps = useRef(null);
  useEffect(() => {
    if (
      !_.isEqual(prevDeps.current, {
        selectedAudience,
        period1Start,
        period1End,
        udc_labels,
        refresh,
      })
    ) {
      fetchPerformanceData();
      prevDeps.current = {
        selectedAudience,
        period1Start,
        period1End,
        udc_labels,
        refresh,
      };
    }
  }, [selectedAudience, period1Start, period1End, udc_labels, refresh]);

  useEffect(() => {
    if (period2Start?.length) {
      fetchCompareData();
    }
  }, [period2Start, period2End]);

  useEffect(() => {
    if (data?.length > 0) {
      dispatch(setPerformanceData(data));
    }
  }, [data]);

  useEffect(() => {
    if (compareData?.length > 0) {
      dispatch(setStoredCompareData(data));
    }
  }, [compareData]);

  useEffect(() => {
    if (loading && loadingCompare) {
      setShowLoading(true);
    }

    if (!loading && !loadingCompare) {
      setShowLoading(false);
    }
  }, [loading, loadingCompare]);

  useEffect(() => {
    if (retrievedDate === toDarwinDate(new Date())) {
      dispatch(trackEvent(GENERAL_LOADING, HARD_REFRESH));
    }
  }, [retrievedDate]);

  const [selectedLabels, visualIdsFromSelectedLabels] = useSelectedLabels(
    labelsParam,
    udc_labels,
    data,
    selectedAudience
  );

  const updatedData = useMemo(() => {
    try {
      return groupByAdName(
        data,
        shouldGroupLabelsByRatio,
        { customIntegratedMetrics, clientMetrics, additional_metrics },
        {
          spendThreshold,
          visualIdsFromSelectedLabels,
        }
      );
    } catch (e) {
      console.log(e);
      return data;
    }
  }, [
    data,
    shouldGroupLabelsByRatio,
    customIntegratedMetrics,
    clientMetrics,
    additional_metrics,
    spendThreshold,
    visualIdsFromSelectedLabels,
  ]);

  const updatedCompareData = useMemo(() => {
    try {
      return groupByAdName(
        compareData,
        shouldGroupLabelsByRatio,
        { customIntegratedMetrics, clientMetrics, additional_metrics },
        {
          spendThreshold,
          visualIdsFromSelectedLabels,
        }
      );
    } catch (e) {}
  }, [
    compareData,
    shouldGroupLabelsByRatio,
    customIntegratedMetrics,
    clientMetrics,
    additional_metrics,
    visualIdsFromSelectedLabels,
  ]);

  const memoizedData = useDeepCompare(updatedData);

  const { allVisualsLabel, allVisualsList } = useMemo(() => {
    const allVisualsLabel = updatedData?.find(
      (label) => label.value === 'all_visuals' || label.value === 'all_ads'
    );

    return {
      allVisualsLabel,
      allVisualsList: allVisualsLabel
        ? [...allVisualsLabel.winners, ...allVisualsLabel.losers]
        : [],
    };
  }, [memoizedData]);

  return {
    data: memoizedData,
    compareData: updatedCompareData,
    loading: loading,
    error,
    loadingCompare,
    fetchPerformanceData,
    fetchCompareData,
    allVisualsLabel,
    allVisualsList,
    showLoading,
    selectedLabels,
    visualIdsFromSelectedLabels,
    retrievedDate,
  };
};
