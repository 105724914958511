import React from "react";
import { PresetsIcon } from "./icons";
import Tooltip from "../../../components/Tooltip";

const Toggle = ({ onClick, presetsOpen }) => {
  return (
    <Tooltip
      content="View Presets"
    >
      <div className={`presets__toggle darwin-button darwin-button--raised`} onClick={onClick}>
        <PresetsIcon open={presetsOpen} />
      </div>
    </Tooltip>
  );
};

export default Toggle;
