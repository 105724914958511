/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import './styles.scss';
import Button from '../Button';

// export interface ButtonProps extends React.ComponentPropsWithoutRef<'button'> {
//   appearance?: string;
//   size?: string;
//   fullWidth?: boolean;
//   loading: boolean;
// }

function IconButton({
  onClick,
  children,
  appearance = 'raised',
  style,
  ...restProps
}) {
  const defaultStyle = {
    height: '30px',
    minWidth: '30px',
    padding: 0,
  };

  const mergedStyle = {
    ...defaultStyle,
    ...style,
  };

  return (
    <Button
      {...restProps}
      onClick={onClick}
      appearance={appearance}
      style={mergedStyle}
    >
      {children}
    </Button>
  );
}

export default IconButton;
