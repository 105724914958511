import { useEffect } from 'react';
import { useGetRequest } from '../../../../components/Hooks/useGetRequest';
import { useDispatch, useSelector } from 'react-redux';
import { setCustomCategoriesData } from '../../../../store/CreativeAnalytics/creativeAnalyticsSlice';

const makeEndpoint = (darwin_client_id) => {
  return [
    'api',
    'creative-analytics',
    'custom-categories',
    darwin_client_id,
    'fetch-all-ccc',
  ].join('/');
};

const formatData = (data) => {};

const handleRes = async (data) => {
  const s3_url = data.s3_url || null;

  if (!s3_url) return null;

  const jsonData = await getJSON(s3_url)
    .then((json) => {
      return json;
    })
    .catch((error) => {
      console.error(error);

      return null;
    });

  return jsonData;
};

export const getJSON = (url) => {
  return new Promise((resolve, reject) => {
    var xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.onload = function () {
      var status = xhr.status;
      if (status === 200) {
        resolve(JSON.parse(xhr.response));
      } else {
        console.log('Error pulling json');
        reject(null);
      }
    };
    xhr.send();
  });
};

const extractData = (data) => {
  console.log(data);
  if (!data) return null;

  try {
    if (Array.isArray(data)) {
      return { labels: [...data] };
    } else {
      const { data: labels, metric_data, missing_data } = data;
      return { labels, metric_data, missing_data };
    }
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const useFetchCustomCategoriesApi = () => {
  const dispatch = useDispatch();
  const { darwin_client_id, customCategoriesData } = useSelector(
    ({
      ViewSettings: { darwin_client_id },
      CreativeAnalytics: { customCategoriesData },
    }) => ({ darwin_client_id, customCategoriesData })
  );

  const { data, request, loading, error } = useGetRequest(
    makeEndpoint(darwin_client_id)
  );

  useEffect(() => {
    const handleDataResponse = async () => {
      if (data) {
        const res = await handleRes(data);
        if (res?.list_categories) {
          dispatch(setCustomCategoriesData(res.list_categories));
        }
      }
    };

    handleDataResponse();
  }, [data]);

  return {
    customCategoriesData,
    request,
    loading,
    error,
  };
  // when changes made, make sure to invalidate store
};
