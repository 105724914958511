import React from 'react';
import flatten from 'lodash/flatten';

export const useCustomScoringColumns = () => {
  const customScoringMetrics = [
    {
      label: 'Adset Relative CPA Percentage',
      value: 'line_item_cpa_percent_better_than_adset_cpa',
    },
    {
      label: 'Adset Relative CTR Percentage',
      value: 'line_item_ctr_percent_better_than_adset_ctr',
    },
    {
      label: 'Adset Relative CPC Percentage',
      value: 'line_item_cpc_percent_better_than_adset_cpc',
    },
    {
      label: 'Adset SOV',
      value: 'adset_share_of_voice',
    },
    {
      label: 'Average CPA Score',
      value: 'CPA Score',
    },
    {
      label: 'Average CTR Score',
      value: 'CTR Score',
    },
    {
      label: 'Average CPC Score',
      value: 'CPC Score',
    },
  ]
  
  const customScoringColumns = customScoringMetrics.map((metric) => {
    return [
      {
        Header: `${metric.label}`,
        accessor: metric.label,
        disableFilters: true,
        Cell: ({ row: { original } }) => {
          const value = original[metric.label];
          if (!value) return <p>0</p>;
          return <p>{value.toFixed(2)}</p>;
        },
      },
    ]
  })

  return flatten(customScoringColumns)
}