import { darwinDateToFormat } from '../../../utils/darwin-dates';

export const makeFormattedDates = (dates) => {
  // expecting string e.g. "2010-01-01,2023-09-30"
  let [start, end] = ['', ''];
  if (dates) {
    [start, end] = dates
      .split(',')
      .map((date) => darwinDateToFormat(date, 'MM/dd/yyyy'));
  }

  return [start, end];
};

export const getLabelsLookup = (data, value) => {
  if (!data) return null;

  return data[
    data.findIndex((x) => x.value === value || x.name === value)
  ];
};
