import React from 'react';
import { useDispatch } from 'react-redux';
import { setRightDrawerDisplay } from '../../../store/CreativeAnalytics/creativeAnalyticsSlice';
import { toCurrency } from '../../../utils/numbers';

const Header = ({ close }) => {
  return (
    <div className="right-drawer-header">
      <p>Missing Data</p>
      <div className="d-flex">
        <button className="right-drawer-header__icon btn-reset" onClick={close}>
          <i className="fa fa-xmark" />
        </button>
      </div>
    </div>
  );
};

const List = ({ missingData }) => {
  console.log({ missingData });

  return (
    <ul className="missing-data-list">
      {missingData.map(({ label, visuals, spend }) => (
        <li>
          <div>
            <p className="missing-data-list__name">{label}</p>
            <p className="missing-data-list__spend">{!!spend ? toCurrency(spend) : 'N/A'}</p>
          </div>
          <div className="missing-data-list__visuals">
            <p>{!!visuals ? `${visuals} visuals` : 'N/A'}</p>
          </div>
        </li>
      ))}
    </ul>
  );
};

export const Layout = ({ missingData }) => {
  const dispatch = useDispatch();

  return (
    <div className="missing-data">
      <Header close={() => dispatch(setRightDrawerDisplay(null))} />
      <List missingData={missingData} />
    </div>
  );
};
