import React, { useState, useEffect } from 'react';
import ReactDOMServer from 'react-dom/server';
import { useDispatch, useSelector } from 'react-redux';
import { trackEvent } from '../../../store/UserActivity/actions';
import {
  ADD_TO_REQUEST,
  CREATIVE_ANALYTICS,
} from '../../../store/UserActivity/constants';
import {
  INITIAL,
  REQUEST_OVERVIEW,
  AVOID_THESE,
  INCLUDE_THESE,
  NUM_OF_REQUESTS,
} from './constants';
import { useCreativeAnalyticsGet } from '../contexts';
import { format } from 'date-fns';

export const useCreativeRequest = () => {
  const {
    filteredAllVisualsLabel,
    selectedAudience,
    spendThreshold,
    start_date,
    end_date,
    selectedLabels,
  } = useCreativeAnalyticsGet();
  const { viewName, user_id } = useSelector(
    ({ ViewSettings: { viewName, user_id } }) => ({ viewName, user_id })
  );
  const [requests, setRequests] = useState(INITIAL);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [overviews, setOverviews] = useState(
    [...Array(NUM_OF_REQUESTS)].map((x) => '')
  );
  const [descriptions, setDescriptions] = useState(
    [...Array(NUM_OF_REQUESTS)].map((x) => '')
  );
  const dispatch = useDispatch();

  const trackAddToRequest = (request_field, label_added) => {
    dispatch(
      trackEvent(CREATIVE_ANALYTICS, ADD_TO_REQUEST, {
        request_field,
        label_added,
      })
    );
  };

  const currentRequest = requests[currentIndex];

  const addLinkToOverview = (display, url) => {
    const navigate = () => {
      window.location.href = url;
    };

    let previewLink = (
      <a
        href={url}
        onClick={navigate}
        target="_blank"
        rel="noopener noreferrer"
      >
        {display}
      </a>
    );

    const update = [...overviews].map((req, i) => {
      if (i === currentIndex) {
        return `${
            req
          } ${ReactDOMServer.renderToStaticMarkup(previewLink)}`
      }
      return req;
    });

    setOverviews(update);
  };

  const addToOverview = (display, url, visual_type, category, value) => {
    const navigate = () => {
      window.location.href = url;
    };

    const isMov = url.includes('.mov');

    let asset = (
      <>
        <a
          href={url}
          onClick={navigate}
          target="_blank"
          rel="noopener noreferrer"
          data-category={category}
          data-label-value={value}
        >
          {display}
        </a>
        <img src={url} className="request-overview__img" />
      </>
    );

    if (visual_type === 'video') {
      asset = (
        <>
          <a
            href={url}
            onClick={navigate}
            target="_blank"
            rel="noopener noreferrer"
            data-category={category}
            data-label-value={value}
          >
            {display}
          </a>
          {!isMov && (
            <iframe
              className="ql-video"
              frameborder="0"
              allowfullscreen="true"
              src={url}
            ></iframe>
          )}
        </>
      );
    }

    const update = [...overviews].map((req, i) => {
      if (i === currentIndex) {
        return `${
            req
          }${ReactDOMServer.renderToStaticMarkup(asset)}`
      }
      return req;
    });

    trackAddToRequest('overview', value);
    setOverviews(update);
  };

  const handleInclude = (value, url, category) => {
    let updatedAvoid = currentRequest[AVOID_THESE];

    if (currentRequest[AVOID_THESE].some((item) => item.value === value)) {
      updatedAvoid = currentRequest[AVOID_THESE].filter(
        (label) => label.value !== value
      );
    }

    const updatedInclude = requests.map((req, i) => {
      if (i === currentIndex) {
        return {
          ...req,
          [INCLUDE_THESE]: [...req[INCLUDE_THESE], { value, url, category }],
          [AVOID_THESE]: updatedAvoid,
        };
      }
      return req;
    });

    trackAddToRequest('include', value);
    setRequests(updatedInclude);
  };

  const handleAvoid = (value, url) => {
    let updatedInclude = currentRequest[INCLUDE_THESE];

    if (currentRequest[INCLUDE_THESE].some((item) => item.value === value)) {
      updatedInclude = currentRequest[INCLUDE_THESE].filter(
        (label) => label.value !== value
      );
    }

    const updatedAvoid = requests.map((req, i) => {
      if (i === currentIndex) {
        return {
          ...req,
          [AVOID_THESE]: [...req[AVOID_THESE], { value, url }],
          [INCLUDE_THESE]: updatedInclude,
        };
      }
      return req;
    });

    trackAddToRequest('avoid', value);
    setRequests(updatedAvoid);
  };

  const handleRemoveLabel = (type, value) => {
    let newLabels = [];

    const removeInclude = type === 'include';
    const removeAvoid = type === 'avoid';

    if (removeInclude)
      newLabels = currentRequest[INCLUDE_THESE].filter(
        (label) => label.value !== value
      );
    else if (removeAvoid)
      newLabels = currentRequest[AVOID_THESE].filter(
        (label) => label.value !== value
      );

    const update = requests.map((req, i) => {
      if (i === currentIndex) {
        return {
          ...req,
          [AVOID_THESE]: removeAvoid ? newLabels : req[AVOID_THESE],
          [INCLUDE_THESE]: removeInclude ? newLabels : req[INCLUDE_THESE],
        };
      }
      return req;
    });

    setRequests(update);
  };

  const handleAddDataPoint = (label, reqIndex) => {
    const update = requests.map((req, i) => {
      if (i === reqIndex) {
        const prev = req.data_points || [];

        const formatDate = (date) => format(date, 'P');

        const getSnapshot = ({
          name,
          value,
          category,
          aggregate_cpa,
          numerator,
          denominator,
          percent_budget,
          aggregate_roas,
        }) => {
          return {
            name,
            value,
            category,
            aggregate_cpa,
            numerator,
            denominator,
            percent_budget,
            aggregate_roas,
          };
        };

        const newDataPoint = {
          allVisualsSnapshot: getSnapshot(filteredAllVisualsLabel),
          labelSnapshot: getSnapshot(label),
          reqIndex,
          opts: {
            spendThreshold,
            audience: selectedAudience?.length > 0 ? selectedAudience : 'all',
            start_date: formatDate(start_date),
            end_date: formatDate(end_date),
            filters: selectedLabels.map((x) => x.label).join(' > '),
          },
          viewName,
          url: window.location.href,
          user_id,
        };

        return { ...req, data_points: [...prev, newDataPoint] };
      }

      return req;
    });

    setRequests(update);
  };
  const handleRemoveDataPoint = (label, reqIndex) => {
    setRequests((prev) => {
      return prev.map((req, i) => {
        if (i === reqIndex && !!req.data_points) {
          let update = [...req.data_points].filter(
            (d) => d.labelSnapshot.value !== label.value
          );

          return {
            ...req,
            data_points: update,
          };
        }

        return req;
      });
    });
  };

  return {
    requests,
    setRequests,
    currentIndex,
    setCurrentIndex,
    currentRequest,
    setInclude: handleInclude,
    setAvoid: handleAvoid,
    removeLabel: handleRemoveLabel,
    addToOverview,
    addLinkToOverview,
    descriptions,
    setDescriptions,
    overviews,
    setOverviews,
    handleAddDataPoint,
    handleRemoveDataPoint,
  };
};
