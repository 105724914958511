import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useInterval } from '../../hooks/useInterval';
import { useNotifications } from '../Notification/useNotifications';
import { assetsAddOne } from '../../store/Marketplace/Assets/actions';
import { versionsAddOne } from '../../store/Marketplace/Versions/actions';
import { abortableFetch } from '../../utils';
import { bindFetchToRef, useApiState } from '../Hooks/useApiState';
import { trackEvent } from '../../store/UserActivity/actions';
import { CREATIVE_STUDIO } from '../../store/UserActivity/constants';

function uploadCommentAsset(payload) {
  console.log(payload.file)
  return abortableFetch(
    [
      'api',
      'marketplace',
      'assets',
      payload.asset_id,
      'comment-asset-upload',
    ].join('/'),
    {
      method: 'POST',
      body: payload.file,
      credentials: 'include',
    }
  );
}

export const useUploadCommentAssetApi = () => {
  const mounted = useRef(false);
  const withRef = bindFetchToRef(mounted);
  const dispatch = useDispatch();
  const notifications = useNotifications();
  const [postState, postDispatch] = useApiState(
    (payload) => withRef(uploadCommentAsset(payload)).then((data) => {
      console.log(payload)
      console.log(data)
      return data;
    }),
    {
      loading: false,
      error: null,
      data: null,
      count: 0,
    }
  );

  useEffect(() => {
    mounted.current = true;

    return () => (mounted.current = false);
  }, []);

  useEffect(() => {
    if (postState.data) {
      console.log(postState.data);
      notifications.remove('comment-asset-upload')
      notifications.add({
        id: `asset-uploaded`,
        title: 'Asset uploaded',
      });
      setTimeout(() => {
        notifications.remove('asset-uploaded')
      }, 5000)
    }
    
    dispatch(trackEvent(CREATIVE_STUDIO, 'Uploaded asset'))
  }, [postState.data]);

  useEffect(() => {
    if (postState.loading) {
      notifications.add({
        id: `comment-asset-upload`,
        title: 'Uploading asset',
        message: 'Please wait',
        showEllipses: true,
      });
    }
  }, [postState.loading]);

  useEffect(() => {
    if (postState.error) {      
      notifications.remove('comment-asset-upload')
      try {
        notifyError('There was an error.', postState.error.error.message.toString());
      } catch (error) {
        console.error(error)
        notifyError('There was an error.', postState.error.error.toString());
      }
    }
  }, [postState.error]);

  const upload = async (files, asset_id, s3_filename, insertToEditor, quillObj, handleEditorChange) => {
    if (!files[0]) return console.error("Unable to upload file.");
    const file = files[0];
    const formData = makeCommentAssetFormData(file, asset_id, s3_filename);
    for (const value of formData.values()) {
      console.log(value);
    }

    console.log(file);

    await postDispatch({
      asset_id,
      file: makeCommentAssetFormData(file, asset_id, s3_filename),
    })
      .then((res) => {
        insertToEditor(
          `https://creative-man-comments.s3.amazonaws.com/${s3_filename}`,
          file.type,
          quillObj,
          handleEditorChange,
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return {
    upload,
    ...postState,
  };
};

const makeCommentAssetFormData = (file, asset_id, s3_filename) => {
  const data = new FormData();

  data.append('s3_filename', s3_filename);
  data.append('asset_id', asset_id);
  data.append('created', file.lastModified);
  data.append('filename', file.name);
  data.append('type', file.type);
  data.append('file', file);

  return data;
};

function uploadStudioAsset(payload) {
  console.log(payload)
  return abortableFetch(
    [
      'api',
      'marketplace',
      'assets',
      payload.viewId,
      'studio-asset-upload',
    ].join('/'),
    {
      method: 'POST',
      body: payload.file,
      credentials: 'include',
    }
  );
}

export const useUploadStudioAssetApi = (assetPredictionStatusApi) => {
  const mounted = useRef(false);
  const withRef = bindFetchToRef(mounted);
  const dispatch = useDispatch();
  const notifications = useNotifications();
  const [postState, postDispatch] = useApiState(
    (payload) => withRef(uploadStudioAsset(payload)).then((data) => {
      console.log(data)
      return data;
    }),
    {
      loading: false,
      error: null,
      data: null,
      count: 0,
    }
  );

  useEffect(() => {
    mounted.current = true;

    return () => (mounted.current = false);
  }, []);

  useEffect(() => {
    if (postState.data) {
      console.log(postState.data);
      notifications.remove('asset-upload')
      notifications.add({
        id: `asset-predictions-running`,
        title: 'Labelling asset',
        message: 'Please wait',
        showEllipses: true,
      });
      assetPredictionStatusApi.fetch();
    }
  }, [postState.data]);

  useEffect(() => {
    if (postState.loading) {
      notifications.add({
        id: `asset-upload`,
        title: 'Uploading asset',
        message: 'Please wait',
        showEllipses: true,

      });
    }
  }, [postState.loading]);

  useEffect(() => {
    if (postState.error) {
      notifications.remove('relabel-asset')
      try {
        notifyError('There was an error.', postState.error.error.message.toString());
      } catch (error) {
        console.error(error)
        notifyError('There was an error.', postState.error.toString());
      }
    }
  }, [postState.error]);

  const upload = (files, viewId, platform) => {
    if (!files[0]) return console.error('Unable to upload file.');
    const formData = makeFormData(files[0], viewId, platform);
    for (const value of formData.values()) {
      console.log(value);
    }

    postDispatch({
      viewId,
      file: makeFormData(files[0], viewId, platform),
    });
  }

  return {
    upload,
    ...postState,
  };
};

const makeFormData = (file, view_id, platform) => {
  const data = new FormData();

  data.append('view_id', view_id);
  data.append('platform', platform);
  data.append('created', file.lastModified);
  data.append('filename', file.name);
  data.append('type', file.type);
  data.append('file', file);

  return data;
};

export const notifyError = (name, error) => {
  const notifications = useNotifications();

  notifications.add({
    title: name,
    message: error,
    toastProps: {
      className: 'error-toast',
    },
  });
};