/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useLazyLoader } from '../../../components/LazyLoader/useLazyLoader';
import { useScrollIntoView } from '../../../hooks/useScrollIntoView';
import './Recommendations.scss';
import Card from './Card';
import LazyLoader from '../../../components/LazyLoader';
import { useSearchParams } from '../../../components/Hooks/search-params';
import { sortTryThese } from './utils';
import { useCreativeAnalyticsGet } from '../contexts';
import { useDispatch } from 'react-redux';
import { trackEvent } from '../../../store/UserActivity/actions';
import {
  CREATIVE_ANALYTICS,
  TRENDING_LAZY_LOADS,
} from '../../../store/UserActivity/constants';
import RecommendationsSearch from './RecommendationsSearch';
import { useSavedConceptsApi } from './api';
import { useWindowSize } from '../PerformanceFrequency/hooks';

export const TryThese = ({
  trendingRecs,
  inspirationRecs,
  tab,
  account_id,
  view_id,
  appliedFilter,
  handleSetFilter,
}) => {
  const { spendThreshold, rightDrawerDisplay, requestOpen } = useCreativeAnalyticsGet();
  const [isSearched, setIsSearched] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const savedConceptsApi = useSavedConceptsApi();
  const [justSaved, setJustSaved] = useState(null);
  const [justUnsaved, setJustUnsaved] = useState(null);
  const [savedConcepts, setSavedConcepts] = useState([]);

  const filterOutSaved = ({ label }) =>
    !savedConcepts.find((saved) => label === saved.label);

  const trendingSorted = useMemo(
    () => trendingRecs.filter(filterOutSaved).sort(sortTryThese),
    [trendingRecs, savedConcepts]
  );

  const inspirationSorted = useMemo(
    () => inspirationRecs.filter(filterOutSaved).sort(sortTryThese),
    [inspirationRecs, savedConcepts]
  );

  const allTryThese = [...trendingRecs, ...inspirationRecs].sort(sortTryThese);

  useEffect(() => {
    savedConceptsApi.get.request(view_id);
  }, []);

  useEffect(() => {
    if (savedConceptsApi.get.data) {
      const { data } = savedConceptsApi.get;
      setSavedConcepts(
        [...trendingRecs, ...inspirationRecs]
          .filter((x) =>
            data.saved_concepts.some(
              (f) => f.value === x.label && f.category === x.category
            )
          )
          .sort(sortTryThese)
      );
    }
  }, [savedConceptsApi.get.data]);

  const makeSavedConceptsPayload = (updatedConcepts) => {
    const concepts = updatedConcepts.map(({ label, category }) => ({
      value: label,
      category: category,
    }));

    const payload = {
      view_id: view_id,
      saved_concepts: concepts,
    };

    return payload;
  };

  const handleSaveConcept = (label) => {
    let updatedConcepts = [];
    if (savedConcepts.find((concept) => concept.label === label.label)) {
      // unsave
      updatedConcepts = savedConcepts.filter(
        (concept) => concept.label !== label.label
      );
      setJustUnsaved(label.label);
    } else {
      // save
      updatedConcepts = [...savedConcepts, label];
      setJustSaved(label.label);
    }

    savedConceptsApi.post.request(makeSavedConceptsPayload(updatedConcepts));

    setTimeout(() => {
      setSavedConcepts(updatedConcepts.sort(sortTryThese));
      setJustSaved(null);
      setJustUnsaved(null);
    }, 2000);
  };

  useEffect(() => {
    if (savedConceptsApi.post.error) {
      console.log(savedConceptsApi.post.error);
    }
  }, [savedConceptsApi.post.error]);

  const { getParams } = useSearchParams();
  const { ratings } = getParams('ratings');

  const cardProps = {
    tab,
    spendThreshold,
    account_id,
    handleSaveConcept,
    justSaved,
    justUnsaved,
  };

  const handleSearch = (searchTerm) => {
    if (searchTerm && searchTerm.length) {
      setIsSearched(true);
      getSearchResults(searchTerm);
    } else {
      setIsSearched(false);
    }
  };

  const searchOptions = useMemo(() => {
    return allTryThese.map((rec) => {
      return {
        label: rec.label,
        value: rec.label,
        description: rec.description,
        category: rec.category,
      };
    });
  }, [allTryThese]);

  const getSearchResults = (searchTerm) => {
    setSearchResults(
      allTryThese.filter((rec) =>
        rec.label.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  };

  const isSaved = (label) => {
    return savedConcepts.find((concept) => concept.label === label.label);
  };

  const fullScreen = !rightDrawerDisplay && !requestOpen;
  
  const sectionProps = {
    ratings,
    isSearched,
    isSaved,
    appliedFilter,
    fullScreen,
  };

  if (trendingRecs.length < 1 && inspirationRecs.length < 1) {
    return (
      <p className="recommendations__error">
        Not enough data to display recommendations.
      </p>
    );
  }

  return (
    <>
      <RecommendationsSearch
        {...{ handleSearch, searchOptions, appliedFilter, handleSetFilter }}
      />
      <SearchResults {...{ searchResults, cardProps, sectionProps }} />
      <div className={`recommendation-sections ${isSearched ? 'hidden' : ''}`}>
        <Trending {...{ trendingSorted, cardProps, sectionProps }} />
        <SavedConcepts {...{ savedConcepts, cardProps, sectionProps }} />
        <Inspiration {...{ inspirationSorted, cardProps, sectionProps }} />
      </div>
    </>
  );
};

const SearchResults = ({ searchResults, cardProps, sectionProps }) => {
  const { isSearched, isSaved, fullScreen } = sectionProps;

  return (
    <div className={`recommendations-list ${!isSearched ? 'hidden' : ''} ${fullScreen ? 'full-screen' : ''}`}>
      {searchResults.map((label) => {
        return (
          <LazyLoader
            className="recommendation-card-wrapper"
            placeholder={<div style={{ height: '80px' }} />}
          >
            <Card isSaved={isSaved(label)} {...{ ...cardProps, label }} />
          </LazyLoader>
        );
      })}
    </div>
  );
};

const SavedConcepts = ({ savedConcepts, cardProps, sectionProps }) => {
  const { ratings, isSaved, appliedFilter, fullScreen } = sectionProps;
  const filteredSaved = savedConcepts.length
    ? savedConcepts.filter((concept) =>
        !!appliedFilter
          ? concept.example.some((asset) => asset.type === appliedFilter)
          : concept
      )
    : [];

  return (
    <div id="saved-section">
      <div className="section-block">
        <h2 className="section-block__title">Your Saved Concepts</h2>
      </div>
      <div className={`recommendations-list ${fullScreen ? 'full-screen' : ''}`}>
        {filteredSaved.length ? (
          <>
            {filteredSaved.map((label) => {
              const rating = parseFloat(label.rating) || null;

              if (!!ratings || rating > 0) {
                return (
                  <LazyLoader
                    className="recommendation-card-wrapper"
                    placeholder={<div style={{ height: '80px' }} />}
                  >
                    <Card
                      isSaved={isSaved(label)}
                      {...{ ...cardProps, label }}
                    />
                  </LazyLoader>
                );
              }

              return null;
            })}
          </>
        ) : (
          <p className="no-saved">No saved concepts found.</p>
        )}
      </div>
    </div>
  );
};

const Trending = ({ trendingSorted, cardProps, sectionProps }) => {
  const { ratings, isSaved, fullScreen } = sectionProps;

  return (
    <div id="trending-section">
      <div className="section-block">
        <h2 className="section-block__title">
          Trending Concepts
          <i className="fa fa-arrow-trend-up ml-3"></i>
        </h2>
      </div>
      <div className={`recommendations-list ${fullScreen ? 'full-screen' : ''}`}>
        {trendingSorted.map((label) => {
          const rating = parseFloat(label.rating) || null;

          if (!!ratings || rating > 0) {
            return (
              <LazyLoader
                className="recommendation-card-wrapper"
                placeholder={<div style={{ height: '80px' }} />}
              >
                <Card isSaved={isSaved(label)} {...{ ...cardProps, label }} />
              </LazyLoader>
            );
          }

          return null;
        })}
      </div>
    </div>
  );
};

const Inspiration = ({ inspirationSorted, cardProps, sectionProps }) => {
  const { ratings, isSaved, fullScreen } = sectionProps;
  const [ref, visible, pause] = useLazyLoader();
  const dispatch = useDispatch();
  const { requestWidth } = useCreativeAnalyticsGet();
  const size = useWindowSize();
  const area = size.width - requestWidth;

  // if user reaches inspiration section, trending was fully scrolled through
  useEffect(() => {
    if (visible) {
      dispatch(trackEvent(CREATIVE_ANALYTICS, TRENDING_LAZY_LOADS));
      pause();
    }
  }, [visible]);

  return (
    <div ref={ref} id="inspiration-section">
      <div className="section-block">
        <h2 className="section-block__title mb-2">
          Historical High Performers & Creative Inspiration
          <i className="fa fa-ranking-star ml-3"></i>
        </h2>
        <h3 className="section-block__subtitle">
          This section is a curated mix of concepts that have performed well in
          the past as well as new concepts and formats that we found.
        </h3>
      </div>
      <div className={`recommendations-list ${fullScreen ? 'full-screen' : ''}`}>
        {inspirationSorted.map((label) => {
          const rating = parseFloat(label.rating) || null;

          if (ratings) {
            return (
              <LazyLoader
                className="recommendation-card-wrapper"
                placeholder={<div style={{ height: '80px' }} />}
              >
                <Card isSaved={isSaved(label)} {...{ ...cardProps, label }} />
              </LazyLoader>
            );
          }

          if (rating > 0) {
            return (
              <LazyLoader
                className="recommendation-card-wrapper"
                placeholder={<div style={{ height: '80px' }} />}
              >
                <Card isSaved={isSaved(label)} {...{ ...cardProps, label }} />
              </LazyLoader>
            );
          }

          return null;
        })}
      </div>
    </div>
  );
};
