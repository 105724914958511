import React, { useEffect, useState } from 'react';
import './Copilot.scss';
import '../../../../CreativeCopilot/Output/Output.scss';
import { useSuggestionsContext } from '../../contexts';
import { CopilotIcon } from '../../../../CreativeCopilot/components/CopilotIcon';
import LoadingMessages from '../../../../../../components/Helpers/LoadingMessages';
import { LOADING_MESSAGES } from '../../../../CreativeCopilot/constants';
import parse from 'html-react-parser';
import Button from '../../../../../../components/Button';
import Tooltip from '../../../../../../components/Tooltip';
import {
  useCreativeRequestGet,
  useCreativeRequestSet,
} from '../../../../CreativeRequest/contexts';

const Copilot = () => {
  const {
    copilotData: data,
    copilotLoading: loading,
    copilotRequestError,
    fetchCopilot,
    userFeedback,
    setUserFeedback,
    previousOutput,
  } = useSuggestionsContext();

  const { overviews, currentIndex } = useCreativeRequestGet();
  const { setOverviews } = useCreativeRequestSet();
  const [indexAdded, setIndexAdded] = useState(null);
  const [justAdded, setJustAdded] = useState(false);

  const handleOverview = (value, i) => {
    const update = [...overviews].map((desc, i) => {
      if (i === currentIndex) {
        return `${desc} <p>${value}</p><br>`;
      }

      return desc;
    });

    setOverviews(update);
    setIndexAdded(i);
    setJustAdded(true);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setJustAdded(false);
      setIndexAdded(null);
    }, 1500);
    return () => {
      clearTimeout(timer);
    };
  }, [indexAdded]);

  let Display = <div></div>;

  if (copilotRequestError) {
    Display = <div>There was an error.</div>;
  }

  if (loading) {
    Display = (
      <div className="d-flex align-items-center justify-content-center flex-column">
        <div className={`icon icon--placeholder spin-loading`}>
          <CopilotIcon />
        </div>
        <LoadingMessages messages={LOADING_MESSAGES} delay={5000} />
      </div>
    );
  }

  if (data && !loading) {
    const recommendations = Array.from(
      data.recommendation_summary.matchAll(/(?<=<li>)(.*?)(?=<\/li>)/g),
      (x) => x[1]
    );

    Display = (
      <div className="summary-text">
        <div className="mb-4" style={{ fontWeight: 600 }}>
          Suggestions for improving the visual:
        </div>
        <ul>
          {recommendations.map((rec, i) => {
            return (
              <li className="mb-4">
                <Tooltip
                  content={
                    indexAdded === i && justAdded
                      ? 'Added!'
                      : 'Add to creative request overview'
                  }
                >
                  <div
                    className="add-button"
                    onClick={() => handleOverview(rec, i)}
                  >
                    <i
                      className={`fa-solid fa-${
                        indexAdded === i && justAdded ? 'check' : 'plus'
                      }`}
                    />
                  </div>
                </Tooltip>
                {rec}
              </li>
            );
          })}
        </ul>
        <div style={{ fontWeight: 600 }}>
          Use your best judgment and be creative! Recommendations for video
          assets are only for the first 3 seconds of the video.
        </div>
      </div>
    );
  }

  return (
    <div className="suggestions__copilot">
      <div>{Display}</div>
      {previousOutput && (
        <div className="feedback">
          <div>Feedback:</div>
          <textarea
            className="text-input"
            onChange={(e) => setUserFeedback(e.target.value)}
            value={userFeedback}
          />
          <Button onClick={fetchCopilot} loading={loading} disabled={loading}>
            Submit
          </Button>
        </div>
      )}
    </div>
  );
};

export default Copilot;
