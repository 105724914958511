import React, { useState, useRef } from 'react';
import './Presets.scss';
import Toggle from './Toggle';
import Button from '../../../components/Button';
import Tooltip from '../../../components/Tooltip';
import Saved from './Saved';
import { CheckboxField, InputField, TextEditorField } from './InputFields';
import { StarIcon } from './icons';
import { makeFormattedDates } from './utils';
import { useClickOutside } from '../../../hooks/useClickOutside';
import { useViewSettings } from '../../../selectors';
import { getCol, getDefaultColumns } from '../PerformanceFrequency/hooks';
import { useCreativeAnalyticsGet } from '../contexts';
import { makeColumnsLookup } from '../utils';

const Presets = ({
  currentUrl,
  presets,
  current,
  handleInput,
  handleChecked,
  spendThreshold,
  period1,
  period2,
  min,
  selectedLabels,
  handleCreate,
  handleDelete,
  selectedAudience,
  filteredData,
  loading,
  getLoading,
  view_id,
  shouldGroupLabelsByRatio,
  defaultPfrColumns,
  defaultDnaColumns,
  isROAS,
}) => {
  const { isOrganicView } = useViewSettings();
  const { columnDropdownOptions, primary_metric } = useCreativeAnalyticsGet();
  const [presetsOpen, setPresetsOpen] = useState(false);
  const [showSaved, setShowSaved] = useState(false);
  const ref = useRef();
  useClickOutside(ref, () => setPresetsOpen(false));

  const handleToggle = () => {
    if (loading) return;
    setPresetsOpen((prev) => !prev);
  };

  const [startDate1, endDate1] = makeFormattedDates(period1);
  const [startDate2, endDate2] = makeFormattedDates(period2);

  const defaultPfr = makeColumnsLookup(
    getDefaultColumns({
      display: 'performance_frequency',
      isROAS,
      primary_metric,
      isOrganicView,
    }),
    columnDropdownOptions
  );
  const defaultDna = makeColumnsLookup(
    getDefaultColumns({
      display: 'dna_table',
      isROAS,
      primary_metric,
      isOrganicView,
    }),
    columnDropdownOptions
  );

  return (
    <div className="presets" ref={ref}>
      <Toggle onClick={handleToggle} presetsOpen={presetsOpen} />
      <div className={`presets__container ${presetsOpen ? 'show' : ''}`}>
        <div className={`presets__inner`}>
          <div className={`presets__default-create ${showSaved ? 'hide' : ''}`}>
            <div
              className="saved-toggle"
              onClick={() => {
                if (loading || getLoading) return;
                setShowSaved(true);
              }}
            >
              <div className="d-flex justify-content-between align-items-center">
                <span>Saved Presets</span>
                <span className="arrow">{`>`}</span>
              </div>
            </div>
            <div className="presets__title">Create View Preset</div>
            <InputField
              label="Name*"
              value={current.preset_name}
              onChange={(e) => handleInput('preset_name', e.target.value)}
            />
            <TextEditorField
              label="Description"
              value={current.preset_description}
              onChange={(html) => handleInput('preset_description', html)}
              hideToolbar={true}
            />
            <CheckboxField
              label="Fix Dates"
              checked={current.fix_dates}
              onClick={() => handleChecked('fix_dates')}
              tooltip={
                <div style={{ color: '#ddd' }}>
                  Always load this preset with these dates:
                  <div style={{ color: '#fff' }}>
                    Period 1:{' '}
                    <span className="ml-2">
                      {startDate1} - {endDate1}
                    </span>
                  </div>
                  {period2 && (
                    <div style={{ color: '#fff' }}>
                      Period 2:{' '}
                      <span className="ml-2">
                        {startDate2} - {endDate2}
                      </span>
                    </div>
                  )}
                </div>
              }
              CustomCheckbox={
                <div
                  className={`custom-checkbox ${
                    current.fix_dates ? 'checked' : ''
                  }`}
                />
              }
            />
            <div
              className="text-editor-field d-flex align-items-center"
              onClick={() => handleChecked('is_default')}
            >
              <div className="icon-container">
                <span className={`star-icon`}>
                  <StarIcon />
                </span>
                <span
                  className={`star-icon ${current.is_default ? 'active' : ''}`}
                >
                  <StarIcon />
                </span>
              </div>
              <label className="mb-0 ml-2">Set as default preset</label>
            </div>

            {!isOrganicView && (
              <div className="text-editor-field">
                <label>Spend Threshold</label>
                <div className="current-detail">${spendThreshold}</div>
              </div>
            )}

            {!isOrganicView && (
              <div className="text-editor-field">
                <label>Audience</label>
                <div className="current-detail">
                  {selectedAudience ? selectedAudience : 'All Audiences'}
                </div>
              </div>
            )}

            <div className="text-editor-field">
              <label>Label Filters</label>
              <div className="current-detail">
                {selectedLabels.length
                  ? selectedLabels.map((l) => (
                      <span className="list-item">{l.label}</span>
                    ))
                  : 'None'}
              </div>
            </div>
            <div className="text-editor-field">
              <label>Primary Metric</label>
              <div className="current-detail">{primary_metric}</div>
            </div>

            {!isOrganicView && (
              <div className="text-editor-field">
                <label>Min Results</label>
                <div className="current-detail">{min ? min : 'None'}</div>
              </div>
            )}

            <div className="text-editor-field">
              <label>Group by Ratio</label>
              <div className="current-detail">
                {shouldGroupLabelsByRatio ? 'Yes' : 'No'}
              </div>
            </div>
            <div className="text-editor-field">
              <label>Detailed Reports Columns</label>
              <div className="current-detail">
                {defaultPfrColumns
                  ? `${defaultPfrColumns.join(', ').replace(/_/g, ' ')}`
                  : defaultPfr}
              </div>
            </div>
            <div className="text-editor-field">
              <label>Creative Performance Columns</label>
              <div className="current-detail">
                {defaultDnaColumns
                  ? `${defaultDnaColumns.join(', ').replace(/_/g, ' ')}`
                  : defaultDna}
              </div>
            </div>
            <Tooltip
              disabled={current.preset_name.trim().length}
              content={'Preset name cannot be empty.'}
            >
              <div>
                <Button
                  appearance="secondary"
                  disabled={!current.preset_name.trim().length}
                  onClick={handleCreate}
                >
                  Create Preset
                </Button>
              </div>
            </Tooltip>
          </div>
          <Saved
            show={showSaved}
            {...{
              setShowSaved,
              presets,
              handleDelete,
              filteredData,
              loading,
              view_id,
              setPresetsOpen,
              getLoading,
              defaultPfr,
              defaultDna,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Presets;
