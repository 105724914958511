import React, { useState, useEffect } from 'react';
import Suggestions from './Suggestions';
import Menu from './Menu/Menu';
import { useReportCardContext } from '../contexts';
import Header from './components/Header';
import {
  useNewPhrasesApi,
  useGetGeneralSuggestionsApi,
  useFavoriteSuggestionsApi,
  useGetEasySuggestionsApi,
} from '../api';
import { NOW, toDarwinDate } from '../../../../utils/darwin-dates';
import { useCreativeAnalyticsGet } from '../../contexts';
import { DEFAULT, GENERAL, TOP_PERFORMANCE, TRY_THESE } from './constants';
import Tabs from './components/Tabs';
import { useDarwinClientId } from '../../CategoryBuilder/selectors';
import { EASY_SUGGESTIONS, OVERVIEW } from '../constants';
import { SuggestionsProvider } from './contexts';
import { getCategoryLabelData, makeEasyGptPayload } from './utils';
import { useDispatch } from 'react-redux';
import { trackEvent } from '../../../../store/UserActivity/actions';
import {
  EXECUTION_STYLES,
  GENERAL_SUGGESTIONS,
  PERFORMANCE_SUGGESTIONS,
  PHRASE_ALTERNATIVES,
  PHRASE_GENERAL_IDEAS,
  RECO_ENGINE,
} from '../constants';

const SuggestionsWrapper = ({
  bestPerforming,
  suggestions,
  recsLoading,
  recsError,
  predictions,
  isROAS,
  fromCreativeStudio,
  isDirectMail,
  viewName,
  categories,
  dispatchRevisionModal,
  handleQuickRevisionRequest,
  trackPath,
  setParams,
  depthOptions,
  fetchRecs,
  categoryLabels,
  isDemoClient,
  view_id,
  start_date,
  end_date,
  darwin_direct_enabled,
  spendThreshold,
  visual_hash,
  activePage,
  copilotData,
  copilotLoading,
  copilotRequestError,
  columns,
  handleColumns,
  fetchCopilot,
  userFeedback,
  setUserFeedback,
  previousOutput,
}) => {
  const {
    recDepth,
    setRecDepth,
    showMenu,
    setShowMenu,
    fixedLabels,
    setFixedLabels,
    isAdvanced,
    setIsAdvanced,
    attemptedDepth,
    setAttemptedDepth,
    recsErrorDisplay,
    setRecsErrorDisplay,
    current,
    isCompetitorView,
    isOrganicView,
  } = useReportCardContext();
  const { resultsThreshold } = useCreativeAnalyticsGet();
  const [showForm, setShowForm] = useState(false);
  const [newPhrasesData, setNewPhrasesData] = useState<any[]>([]);
  const [generalSuggestions, setGeneralSuggestions] = useState({
    data: null,
    loading: false,
    error: false,
  });
  const [executionSuggestions, setExecutionSuggestions] = useState({
    data: null,
    loading: false,
    error: false,
  });
  const [performanceSuggestions, setPerformanceSuggestions] = useState({
    data: null,
    loading: false,
    error: false,
  });
  const [easySuggestions, setEasySuggestions] = useState({
    data: null,
    loading: false,
    error: false,
  });
  const [generalType, setGeneralType] = useState(GENERAL);
  const [activeTab, setActiveTab] = useState(DEFAULT);
  const [favoriteSuggestions, setFavoriteSuggestions] = useState([]);
  const [favoritesApiStatus, setFavoritesApiStatus] = useState({
    error: false,
    loading: false,
  });
  const newPhrasesApi = useNewPhrasesApi();
  const generalSuggestionsApi = useGetGeneralSuggestionsApi();
  const favoriteSuggestionsApi = useFavoriteSuggestionsApi();
  const easySuggestionsApi = useGetEasySuggestionsApi();
  const darwin_client_id = useDarwinClientId();
  const dispatch = useDispatch();
  const [selectedCategory, setSelectedCategory] = useState(null);

  const favoritesError =
    favoriteSuggestionsApi.post.error ||
    favoriteSuggestionsApi.get.error ||
    favoriteSuggestionsApi.delete.error;

  const favoritesLoading =
    favoriteSuggestionsApi.get.loading ||
    favoriteSuggestionsApi.post.loading ||
    favoriteSuggestionsApi.delete.loading;

  const getEasySuggestions = () => {
    if (easySuggestions.loading) return;

    const categoriesWithLabelsData = categories.filter((category) =>
      category.labels.some(
        (label) => label.aggregate_roas > 0 && label.aggregate_cpa > 0
      )
    );

    const categoryLabelData = getCategoryLabelData(
      categoriesWithLabelsData,
      isROAS,
      current
    );

    const easyPayload = makeEasyGptPayload(
      categoryLabelData,
      darwin_client_id,
      view_id,
      visual_hash
    );

    easySuggestionsApi.request(easyPayload);
  };

  const getGeneralSuggestions = (type) => {
    if (generalSuggestionsApi.loading) return;

    setGeneralType(type);

    if (type === GENERAL) {
      setGeneralSuggestions((prev) => ({ ...prev, data: null }));
    } else if (type === TRY_THESE) {
      setExecutionSuggestions((prev) => ({ ...prev, data: null }));
    } else if (type === TOP_PERFORMANCE) {
      setPerformanceSuggestions((prev) => ({ ...prev, data: null }));
    }

    const payload = {
      view_id,
      start_date: toDarwinDate(start_date),
      end_date: toDarwinDate(end_date),
      visual_id: visual_hash,
      spend_threshold: parseInt(spendThreshold),
      results_threshold: resultsThreshold,
      is_general: type,
    };

    generalSuggestionsApi.request(payload);
  };

  // useEffect(() => {
  //   if (!fromCreativeStudio && !bestPerforming) getEasySuggestions();
  // }, []);

  useEffect(() => {
    const { data, error, loading } = easySuggestionsApi;
    const update = {
      data,
      error,
      loading,
    };

    setEasySuggestions(update);
  }, [
    easySuggestionsApi.data,
    easySuggestionsApi.loading,
    easySuggestionsApi.error,
  ]);

  useEffect(() => {
    const { data, error, loading } = generalSuggestionsApi;
    const update = {
      data,
      error,
      loading,
    };
    if (generalType === GENERAL) {
      setGeneralSuggestions(update);
    } else if (generalType === TRY_THESE) {
      setExecutionSuggestions(update);
    } else if (generalType === TOP_PERFORMANCE) {
      setPerformanceSuggestions(update);
    }
  }, [
    generalSuggestionsApi.data,
    generalSuggestionsApi.loading,
    generalSuggestionsApi.error,
    generalType,
  ]);

  const getNewPhrases = (phrase) => {
    setNewPhrasesData([]);

    const payload = {
      view_id,
      start_date: toDarwinDate(start_date),
      end_date: toDarwinDate(end_date),
      phrase: !!phrase ? phrase : '',
    };

    newPhrasesApi.request(payload);
  };

  useEffect(() => {
    if (newPhrasesApi.data) {
      setNewPhrasesData(newPhrasesApi.data);
    }
  }, [newPhrasesApi.data]);

  useEffect(() => {
    if (newPhrasesApi.error) {
      console.log(newPhrasesApi.error);
    }
  }, [newPhrasesApi.error]);

  // useEffect(() => {
  //   getFavorites();
  // }, []);

  useEffect(() => {
    if (favoriteSuggestionsApi.get.data) {
      if (!Array.isArray(favoriteSuggestionsApi.get.data)) {
        setFavoriteSuggestions([]);
      } else {
        setFavoriteSuggestions(favoriteSuggestionsApi.get.data);
      }
    }
  }, [favoriteSuggestionsApi.get.data]);

  useEffect(() => {
    if (favoritesError) {
      setFavoritesApiStatus((prev) => ({ ...prev, error: true }));
    } else {
      setFavoritesApiStatus((prev) => ({ ...prev, error: false }));
    }

    setFavoritesApiStatus((prev) => ({
      ...prev,
      loading: favoritesLoading ? true : false,
    }));
  }, [favoritesError, favoritesLoading]);

  useEffect(() => {
    if (
      favoriteSuggestionsApi.post.data ||
      favoriteSuggestionsApi.delete.data
    ) {
      getFavorites();
    }
  }, [favoriteSuggestionsApi.post.data, favoriteSuggestionsApi.delete.data]);

  const getFavorites = () => {
    favoriteSuggestionsApi.get.request({
      view_id,
      visual_id: visual_hash,
      client_id: darwin_client_id,
      petition_type: 'GET',
    });
  };

  const makeSaveFavoritePayload = (rec) => {
    let type = '';
    let recommendation = '';

    if (rec.recommendation) {
      type = '';
      recommendation = rec.recommendation;
    }

    if (rec.phrase) {
      type = 'Phrase';
      recommendation = rec.phrase;
    }

    if (rec.Recommendation) {
      type = 'General';
      recommendation = rec.Recommendation;
    }

    if (rec.reference_name) {
      type = 'Execution Style';
      recommendation = `Try a ${rec.reference_name} style`;
    }

    return {
      view_id,
      visual_id: visual_hash,
      client_id: darwin_client_id,
      petition_type: 'POST',
      visual_url: rec.visual_url || '',
      pin_date: toDarwinDate(NOW),
      recommendation_type: type,
      recommendation: recommendation,
      why: rec.why || rec.Why || '',
      execution_style_url: rec.visual_url || '',
    };
  };

  const handleAddFavorite = (rec) => {
    const payload = makeSaveFavoritePayload(rec);
    favoriteSuggestionsApi.post.request(payload);
  };

  const handleDeleteFavorite = (id) => {
    favoriteSuggestionsApi.delete.request({ _id: id, petition_type: 'DELETE' });
  };

  const newPhrasesLoading = newPhrasesApi.loading;
  const newPhrasesError = newPhrasesApi.error;

  const disabled = recsLoading || recsError || recDepth < 2 || showMenu;

  const handleCloseForm = () => {
    setShowForm((prev) => !prev);
  };

  const handleShowForm = () => {
    setShowForm((prev) => !prev);
    if (recDepth === 1 || !isAdvanced) {
      setAttemptedDepth(2);
    } else {
      setAttemptedDepth(recDepth);
    }
    setRecsErrorDisplay('');
  };

  const handleSimpleSelect = (depth) => {
    setShowMenu(false);
    setRecDepth(depth);
    setFixedLabels([]);
    setIsAdvanced(false);
    setParams({ depth: '1', fixed: [] });
  };

  const handleDepthSelect = (selected) => {
    setAttemptedDepth(selected.value);
  };

  const handleChecked = (e, i) => {
    let prev = fixedLabels;
    let labelIndex = prev.indexOf(e.target.name);

    if (labelIndex !== -1) {
      prev.splice(labelIndex, 1);
    } else {
      prev.push(e.target.name);
    }
    setFixedLabels([...prev]);
    setIsAdvanced(true);
  };

  const handleTab = (tab) => {
    if (tab !== activeTab) {
      setActiveTab(tab);
    }

    if (tab === 'reasons') {
      setSelectedCategory(null);
    }
  };

  const handleRevisionModal = (suggestion, opts) => {
    dispatchRevisionModal({
      type: 'normal',
      suggestion,
      opts,
    });
  };

  const trackSuggestions = (type) => {
    switch (type) {
      case PHRASE_ALTERNATIVES:
        dispatch(
          trackEvent(RECO_ENGINE, 'Clicked phrase alternative suggestions')
        );
        break;
      case PHRASE_GENERAL_IDEAS:
        dispatch(trackEvent(RECO_ENGINE, 'Clicked general phrase ideas'));
        break;
      case GENERAL_SUGGESTIONS:
        dispatch(trackEvent(RECO_ENGINE, 'Clicked general suggestions'));
        break;
      case EXECUTION_STYLES:
        dispatch(
          trackEvent(RECO_ENGINE, 'Clicked execution style suggestions')
        );
        break;
      case PERFORMANCE_SUGGESTIONS:
        dispatch(trackEvent(RECO_ENGINE, 'Clicked performance suggestions'));
        break;
      case EASY_SUGGESTIONS:
        dispatch(trackEvent(RECO_ENGINE, 'Clicked easy suggestions'));
        break;
    }
  };

  return (
    <SuggestionsProvider
      {...{
        showMenu,
        activeTab,
        bestPerforming,
        isROAS,
        fromCreativeStudio,
        isDirectMail,
        suggestions,
        handleRevisionModal,
        handleQuickRevisionRequest,
        handleAddFavorite,
        handleDeleteFavorite,
        favoriteSuggestions,
        generalSuggestions,
        executionSuggestions,
        performanceSuggestions,
        getGeneralSuggestions,
        trackSuggestions,
        easySuggestions,
        getEasySuggestions,
        copilotData,
        copilotLoading,
        copilotRequestError,
        fetchCopilot,
        userFeedback,
        setUserFeedback,
        previousOutput,
      }}
    >
      <div
        className={`suggestions card ${bestPerforming ? 'no-tabs' : ''} ${
          activePage === OVERVIEW ? 'show' : ''
        }`}
      >
        {!bestPerforming && (
          <Tabs
            {...{
              handleTab,
              favoritesApiStatus,
              favoriteSuggestions,
            }}
          />
        )}
        {(isDemoClient || bestPerforming) && (
          <Header
            {...{
              setShowMenu,
              isDemoClient,
              setRecsErrorDisplay,
            }}
          />
        )}
        <Suggestions
          {...{
            recsLoading,
            recDepth,
            recsError,
            fixedLabels,
            isAdvanced,
            disabled,
            predictions,
            categories,
            recsErrorDisplay,
            current,
            getNewPhrases,
            newPhrasesData,
            newPhrasesLoading,
            newPhrasesError,
            darwin_direct_enabled,
            isCompetitorView,
            isOrganicView,
            columns,
            handleColumns,
            selectedCategory,
            setSelectedCategory,
          }}
        />
        {isDemoClient && (
          <Menu
            {...{
              showForm,
              handleShowForm,
              handleSimpleSelect,
              trackPath,
              fetchRecs,
              handleCloseForm,
              recDepth,
              handleDepthSelect,
              categoryLabels,
              depthOptions,
              fixedLabels,
              handleChecked,
              showMenu,
              recsLoading,
              recsError,
              attemptedDepth,
              recsErrorDisplay,
            }}
          />
        )}
      </div>
    </SuggestionsProvider>
  );
};

export default SuggestionsWrapper;
