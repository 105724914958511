import React, { useState } from 'react';
import './Saved.scss';
import { StarIcon } from './icons';
import { MinimalTextEditorField } from './InputFields';
import Tooltip from '../../../components/Tooltip';
import { getLabelsLookup, makeFormattedDates } from './utils';
import { useViewSettings } from '../../../selectors';
import { useCreativeAnalyticsGet, useCreativeAnalyticsSet } from '../contexts';
import { useHistory } from 'react-router-dom';
import { toDarwinDate } from '../../../utils/darwin-dates';
import { useSearchParams } from '../../../components/Hooks/search-params';

const Saved = ({
  show,
  setShowSaved,
  presets,
  handleDelete,
  filteredData,
  loading,
  view_id,
  setPresetsOpen,
  getLoading,
  defaultPfr,
  defaultDna,
}) => {
  const { default_primary_metric } = useViewSettings();
  const { isROAS, defaultDates } = useCreativeAnalyticsGet();
  const { setParams, setSelectedLabels } = useCreativeAnalyticsSet();

  let Render = <div>No saved view presets.</div>;

  if (getLoading) Render = <div>Loading...</div>;

  if (presets.length) {
    Render = (
      <div>
        {presets.map((preset) => {
          return (
            <PresetItem
              {...{
                preset,
                filteredData,
                handleDelete,
                default_primary_metric,
                isROAS,
                loading,
                view_id,
                setSelectedLabels,
                setParams,
                setPresetsOpen,
                defaultPfr,
                defaultDna,
                defaultDates,
              }}
            />
          );
        })}
      </div>
    );
  }

  return (
    <div className={`presets__saved ${show ? 'show' : ''}`}>
      <div className="">
        <div
          onClick={() => setShowSaved(false)}
          className="back-button"
        >{`<`}</div>
        <div className="presets__title">Saved Presets</div>
      </div>
      {Render}
    </div>
  );
};

export default Saved;

const PresetItem = ({
  preset,
  filteredData,
  handleDelete,
  default_primary_metric,
  isROAS,
  loading,
  view_id,
  setSelectedLabels,
  setParams,
  setPresetsOpen,
  defaultPfr,
  defaultDna,
  defaultDates,
}) => {
  const {
    _id,
    preset_name: name,
    preset_description: description,
    fix_dates,
    is_default,
    url,
  } = preset;

  const [showDetails, setShowDetails] = useState(false);

  const withoutPrefix = url.includes(view_id)
    ? url.split(`${view_id}/`)[1]
    : url;
  const withPrefix = `https://dashboard.darwinsoftware.io/${withoutPrefix}`;
  const history = useHistory();
  const isCurrentView =
    withoutPrefix === window.location.href.split(`${view_id}/`)[1];

  const newUrl = new URL(withPrefix);
  const args = new URLSearchParams(newUrl.search);
  const period1 = args.get('period1');
  const period2 = args.get('period2');
  const audience = args.get('audience');
  const min = args.get('min');
  const primary_metric = args.get('primary_metric');
  const spendThreshold = args.get('spendThreshold');
  const labelsParam = args.get('labels');
  const defaultPfrCols = args.get('columns');
  const defaultDnaCols = args.get('dna_columns');
  const display = args.get('display');
  const cpaMetric = isROAS ? 'ROAS' : 'CPA';
  const [startDate1, endDate1] = makeFormattedDates(period1);
  const [startDate2, endDate2] = makeFormattedDates(period2);
  const { setParams: setSearchParams } = useSearchParams();

  let labels = [];
  if (labelsParam && labelsParam.length && filteredData.length) {
    labels = args
      .get('labels')
      .split(',')
      .map((l) => {
        const foundLabel = getLabelsLookup(filteredData, l);
        if (l === 'top_10_spending') return 'Top 10 Spending';
        return foundLabel?.name || l;
      });
  }

  const handleRoute = () => {
    if (isCurrentView) return;

    history.push(withoutPrefix);
    setParams({
      audience: audience ? audience : '',
      labels: args.get('labels'),
      period1: period1 || defaultDates.map(toDarwinDate).join(','),
      period2: period2,
    });
    setSearchParams({ display });
    setSelectedLabels([]);
    setPresetsOpen(false);
  };

  return (
    <div
      className={`preset__item ${showDetails ? 'expanded' : ''}`}
      onClick={() => setShowDetails((prev) => !prev)}
    >
      <ItemField
        field={'name'}
        icon={
          <i
            className={`fa-solid fa-caret-${showDetails ? 'down' : 'right'}`}
          />
        }
        value={<span>{name}</span>}
      >
        <div className="d-flex">
          {is_default && (
            <Tooltip content="Default">
              <div className="ml-3">
                <StarIcon />
              </div>
            </Tooltip>
          )}
          <Tooltip
            content={
              isCurrentView ? 'This is the current view' : 'Open this preset'
            }
          >
            <div>
              <span
                className={`open-link ${isCurrentView ? 'disabled' : ''}`}
                onClick={handleRoute}
              >
                <i className="fa-solid fa-arrow-up-right-from-square" />
              </span>
            </div>
          </Tooltip>
        </div>
      </ItemField>
      <div className={`preset__item-details ${showDetails ? 'expanded' : ''}`}>
        <ItemField
          field={'description'}
          icon={<i className="fa-solid fa-align-left" />}
          tooltip="Description"
        >
          <MinimalTextEditorField
            value={description || '<p>No description</p>'}
            hideToolbar={true}
          />
        </ItemField>
        <ItemField
          field={'fixed'}
          icon={<i className="fa fa-calendar" />}
          tooltip="Date Range"
        >
          {fix_dates ? (
            <>
              <div>
                {`${period2 ? 'Period 1: ' : ''}`}
                <span>
                  {startDate1} - {endDate1}
                </span>
              </div>
              {period2 && (
                <div>
                  Period 2:{' '}
                  <span>
                    {startDate2} - {endDate2}
                  </span>
                </div>
              )}
            </>
          ) : (
            'No fixed dates'
          )}
        </ItemField>
        <ItemField
          field={'labels'}
          icon={<i className="fa fa-money-bill" />}
          tooltip="Spend Threshold"
        >
          <span>${spendThreshold}</span>
        </ItemField>
        <ItemField
          field={'labels'}
          icon={<i className="fa fa-user-group" />}
          tooltip="Audience"
        >
          <span>{audience ? audience : 'All audiences'}</span>
        </ItemField>
        <ItemField
          field={'labels'}
          icon={<i className="fa fa-tags" />}
          tooltip="Label Filters"
        >
          <span>
            {labels.length ? labels.map((l) => <span>{l}</span>) : 'None'}
          </span>
        </ItemField>
        <ItemField
          field={'primary_metric'}
          icon={<i className="fa-solid fa-chart-line" />}
          tooltip="Primary Metric"
        >
          <span>{primary_metric || default_primary_metric || cpaMetric}</span>
        </ItemField>
        <ItemField
          field={'min'}
          icon={<i className="fa-solid fa-list" />}
          tooltip="Min Results"
        >
          <span>{min ? min : 'No'} min results</span>
        </ItemField>
        <ItemField
          field={'default_pfr_columns'}
          icon={<i className="fa-solid fa-table-columns" />}
          tooltip="Detailed Reports Columns"
        >
          <span>
            {defaultPfrCols
              ? `${defaultPfrCols.split(',').join(', ').replace(/_/g, ' ')}`
              : defaultPfr}
          </span>
        </ItemField>
        <ItemField
          field={'default_pfr_columns'}
          icon={<i className="fa-solid fa-table-columns" />}
          tooltip="Creative Performance Columns"
        >
          <span>
            {defaultDnaCols
              ? `${defaultDnaCols.split(',').join(', ').replace(/_/g, ' ')}`
              : defaultDna}
          </span>
        </ItemField>
        <div className="d-flex justify-content-end">
          {loading && <span>Deleting...</span>}
          <Tooltip content="Delete this preset">
            <span onClick={() => handleDelete(_id)}>
              <i className="fa fa-trash" />
            </span>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

const ItemField = ({ field, value, icon, tooltip, children }) => {
  return (
    <div className={`preset__item-field preset__item-field--${field}`}>
      <div className="d-flex align-items-center justify-content-start">
        {icon && (
          <Tooltip content={tooltip} disabled={!tooltip}>
            <div className="field-icon">{icon}</div>
          </Tooltip>
        )}
        {value && value}
      </div>
      {children}
    </div>
  );
};
