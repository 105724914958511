import { useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { trackEvent } from '../../../store/UserActivity/actions';
import {
  ADD_VISUALS_COLUMN,
  CREATIVE_ANALYTICS,
} from '../../../store/UserActivity/constants';

export const getCol = (isROAS, primary_metric = '', isOrganicView) => {
  try {
    if (primary_metric === 'cpa') {
      return 'aggregate_cpa';
    }

    if (primary_metric === 'roas') {
      return 'aggregate_roas';
    }

    if (['cpm', 'cpc', 'ctr'].includes(primary_metric)) {
      return `aggregate_${primary_metric}`;
    }

    if (primary_metric?.includes('median_')) {
      return primary_metric.toLowerCase();
    }

    if (primary_metric?.includes('cp_')) {
      return primary_metric.toLowerCase();
    }

    if (isOrganicView) {
      return `Average ${primary_metric}`;
    }

    if (['impressions', 'results'].includes(primary_metric)) {
      return `total_${primary_metric}`;
    }

    if (primary_metric === 'clicks') {
      return 'total_link_clicks';
    }

    if (primary_metric === 'spend') {
      return primary_metric;
    }

    return `Aggregate ${primary_metric}`;
  } catch (e) {
    console.error(e);
    return primary_metric;
  }
};

export const getDefaultColumns = ({
  display,
  isROAS,
  primary_metric,
  isOrganicView,
  dnaColumnsParam = '',
  columnsParam = '',
  planningMode,
}) => {
  if (display === 'dna_table' && dnaColumnsParam?.length) {
    return dnaColumnsParam.split(',');
  }

  if (
    ['performance_frequency', 'copilot'].includes(display) &&
    columnsParam?.length
  ) {
    return columnsParam.split(',');
  }

  if (display === 'dna_table') {
    if (isOrganicView) {
      return ['Format', 'primary_metric_quintile'];
    }

    return [
      'spend',
      primary_metric.replace('median_', ''),
      'spend_quintile',
      'primary_metric_quintile',
      'fitness_score',
    ];
  } else {
    if (planningMode) {
      return ['wins_over_all', 'performance_percent', 'visuals', 'planning'];
    }

    return ['percent_budget', getCol(isROAS, primary_metric, isOrganicView)];
  }
};

export function useColumns({
  isROAS,
  primary_metric,
  display,
  isOrganicView,
  columnsParam,
  dnaColumnsParam,
  setSearchParams,
  planningMode,
}) {
  const dispatch = useDispatch();
  const columns = getDefaultColumns({
    display,
    isROAS,
    primary_metric,
    isOrganicView,
    dnaColumnsParam,
    columnsParam,
    planningMode,
  });

  const handleColumns = ({ column, checked }) => {
    if (checked && column === 'visuals') {
      dispatch(trackEvent(CREATIVE_ANALYTICS, ADD_VISUALS_COLUMN));
    }

    let update = [...columns];
    if (checked) {
      update = [...columns, column];
    } else {
      update = columns.filter((col) => col !== column);
    }

    if (['performance_frequency', 'copilot'].includes(display)) {
      setSearchParams({ columns: update.join(',') });
    }

    if (display === 'dna_table') {
      setSearchParams({ dna_columns: update.join(',') });
    }
  };

  return [columns, handleColumns];
}

export function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
}
