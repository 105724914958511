import { useRef, useEffect, useMemo, useCallback } from 'react';
import { abortableFetch } from '../../utils';
import { useAsyncCustomCategoriesApi } from './hooks/useAsyncCustomCategoriesApi';

import {
  useApiState,
  bindFetchToRef,
} from '../../components/Hooks/useApiState';
import { useGetApi } from './CategoryBuilder/Custom/UDC/api';

import { useRevisionRequestApi } from './ReportCard/api';
import { useGetDerivedLabelsApi } from './CategoryBuilder/Custom/DerivedLabels/api';

const HEADERS = { 'content-type': 'application/json' };

function getPerformanceFreqReportAsync(payload) {
  return abortableFetch(
    [
      'api',
      'creative-reports',
      'performance-frequency',
      payload.view_id,
      'async',
      'get_perf_freq',
    ].join('/'),
    {
      method: 'POST',
      headers: HEADERS,
      credentials: 'include',
      body: JSON.stringify(payload),
    }
  );
}

export function useGetPerformanceFrequencyReportAsync() {
  const mounted = useRef(false);
  const withRef = bindFetchToRef(mounted);
  const [postState, postDispatch] = useApiState(
    (payload) =>
      withRef(getPerformanceFreqReportAsync(payload)).then((data) => data),
    {
      data: null,
      error: null,
      loading: false,
      count: 0,
    }
  );

  const handleGetPerformanceFreqAsync = (payload) => {
    postDispatch(payload);
  };

  useEffect(() => {
    mounted.current = true;

    return () => (mounted.current = false);
  }, []);

  return {
    ...postState,
    request: handleGetPerformanceFreqAsync,
  };
}

function getPerformanceFreqReportStatus(payload) {
  return abortableFetch(
    [
      'api',
      'creative-reports',
      'performance-frequency',
      payload.view_id,
      'check_status',
      'get_perf_freq',
    ].join('/'),
    {
      method: 'POST',
      headers: HEADERS,
      credentials: 'include',
      body: JSON.stringify(payload),
    }
  );
}

function useGetPerformanceFrequencyReportStatus() {
  const mounted = useRef(false);
  const withRef = bindFetchToRef(mounted);
  const [postState, postDispatch] = useApiState(
    (payload) =>
      withRef(getPerformanceFreqReportStatus(payload)).then((data) => data),
    {
      data: null,
      error: null,
      loading: false,
      count: 0,
    }
  );

  const handleGetPerformanceFreqStatus = (payload) => {
    postDispatch(payload);
  };

  useEffect(() => {
    mounted.current = true;

    return () => (mounted.current = false);
  }, []);

  return {
    ...postState,
    request: handleGetPerformanceFreqStatus,
  };
}

function getFrequencyData(payload) {
  return abortableFetch(
    [
      'api',
      'creative-reports',
      'performance-frequency',
      payload.view_id,
      'get',
    ].join('/'),
    {
      method: 'POST',
      headers: HEADERS,
      credentials: 'include',
      body: JSON.stringify(payload),
    }
  );
}

export function useGetFrequencyData() {
  const mounted = useRef(false);
  const withRef = bindFetchToRef(mounted);
  const [getState, getDispatch] = useApiState(
    (payload) =>
      withRef(getFrequencyData(payload)).then(({ data: { data } }) => data),
    {
      data: null,
      error: null,
      loading: true,
      count: 0,
    }
  );

  const handlePost = (payload) => {
    getDispatch(payload);
  };

  useEffect(() => {
    mounted.current = true;

    return () => (mounted.current = false);
  }, []);

  return {
    post: {
      ...getState,
      request: handlePost,
    },
  };
}

function postRefreshReport(payload) {
  return abortableFetch(
    ['api', 'creative-analytics', 'refresh-report-data'].join('/'),
    {
      method: 'POST',
      headers: HEADERS,
      credentials: 'include',
      body: JSON.stringify(payload),
    }
  );
}

export function useRefreshReportApi() {
  const mounted = useRef(false);
  const withRef = bindFetchToRef(mounted);
  const [postState, postDispatch] = useApiState(
    (payload) =>
      withRef(postRefreshReport(payload)).then(({ data: { data } }) => data),
    {
      data: null,
      error: null,
      loading: true,
      count: 0,
    }
  );

  const handlePost = (payload) => {
    postDispatch(payload);
  };

  useEffect(() => {
    mounted.current = true;

    return () => (mounted.current = false);
  }, []);

  return {
    post: {
      ...postState,
      request: handlePost,
    },
  };
}

function getRefreshStatus(payload) {
  return abortableFetch(
    ['api', 'creative-analytics', 'get-refresh-report-status'].join('/'),
    {
      method: 'POST',
      headers: HEADERS,
      credentials: 'include',
      body: JSON.stringify(payload),
    }
  );
}

export function useGetRefreshStatusApi() {
  const mounted = useRef(false);
  const withRef = bindFetchToRef(mounted);
  const [postState, postDispatch] = useApiState(
    (payload) =>
      withRef(getRefreshStatus(payload)).then(({ data: { data } }) => data),
    {
      data: null,
      error: null,
      loading: true,
      count: 0,
    }
  );

  const handlePost = (payload) => {
    postDispatch(payload);
  };

  useEffect(() => {
    mounted.current = true;

    return () => (mounted.current = false);
  }, []);

  return {
    post: {
      ...postState,
      request: handlePost,
    },
  };
}

function getNewAds(payload) {
  return abortableFetch(
    [
      'api',
      'creative-reports',
      'performance-frequency',
      'new-ads',
      payload.view_id,
      payload.start_date,
      payload.end_date,
      payload.insights_by_asset,
    ].join('/'),
    {
      method: 'GET',
      headers: HEADERS,
      credentials: 'include',
    }
  );
}

export function useGetNewAds() {
  const mounted = useRef(false);
  const withRef = bindFetchToRef(mounted);
  const [getState, getDispatch] = useApiState(
    (payload) => withRef(getNewAds(payload)).then(({ data }) => data),
    {
      data: null,
      error: null,
      loading: true,
      count: 0,
    }
  );

  const handleGet = (payload) => {
    getDispatch(payload);
  };

  useEffect(() => {
    mounted.current = true;

    return () => (mounted.current = false);
  }, []);

  return {
    get: {
      ...getState,
      request: handleGet,
    },
  };
}

function getStandardLabels(account_id) {
  return abortableFetch(
    ['api', 'creative-reports', 'matrix', 'standard-labels', account_id].join(
      '/'
    ),
    {
      method: 'GET',
      headers: HEADERS,
      credentials: 'include',
    }
  );
}

export function useStandardLabelsApi() {
  const mounted = useRef(false);
  const withRef = bindFetchToRef(mounted);
  const [getState, getDispatch] = useApiState(
    (account_id) =>
      withRef(getStandardLabels(account_id)).then(({ data }) => data),
    {
      data: null,
      error: null,
      loading: true,
      count: 0,
    }
  );

  useEffect(() => {
    mounted.current = true;

    return () => (mounted.current = false);
  }, []);

  return {
    get: {
      ...getState,
      request: getDispatch,
    },
  };
}

function getMissingSpend(payload) {
  return abortableFetch(
    [
      'api',
      'creative-reports',
      'performance-frequency',
      payload.view_id,
      'get-missing-spend',
    ].join('/'),
    {
      method: 'POST',
      headers: HEADERS,
      credentials: 'include',
      body: JSON.stringify(payload),
    }
  );
}

export function useGetMissingSpend() {
  const mounted = useRef(false);
  const withRef = bindFetchToRef(mounted);
  const [postState, postDispatch] = useApiState(
    (payload) => withRef(getMissingSpend(payload)).then(({ data }) => data),
    {
      data: null,
      error: null,
      loading: false,
      count: 0,
    }
  );

  useEffect(() => {
    mounted.current = true;

    return () => (mounted.current = false);
  }, []);

  return {
    post: {
      ...postState,
      request: postDispatch,
    },
  };
}

function getReportCardPrefs(view_id) {
  return abortableFetch(
    ['api', 'creative-analytics', view_id, 'report-card-prefs'].join('/'),
    {
      method: 'GET',
      headers: HEADERS,
      credentials: 'include',
    }
  );
}

function saveReportCardPrefs(payload) {
  return abortableFetch(
    [
      'api',
      'creative-analytics',
      payload.view_id,
      'report-card-prefs',
      'save',
    ].join('/'),
    {
      method: 'POST',
      headers: HEADERS,
      credentials: 'include',
      body: JSON.stringify(payload),
    }
  );
}

export function useReportCardPrefsApi() {
  const mounted = useRef(false);
  const withRef = bindFetchToRef(mounted);
  const [getState, getDispatchFn] = useApiState(
    (view_id) => withRef(getReportCardPrefs(view_id)).then(({ data }) => data),
    {
      data: null,
      error: null,
      loading: true,
      count: 0,
    }
  );
  const [postState, postDispatchFn] = useApiState(
    (payload) => withRef(saveReportCardPrefs(payload)).then(({ data }) => data),
    {
      data: null,
      error: null,
      loading: false,
      count: 0,
    }
  );

  useEffect(() => {
    mounted.current = true;

    return () => (mounted.current = false);
  }, []);



  const getDispatch = useCallback(getDispatchFn, []);
  const postDispatch = useCallback(postDispatchFn, []);

  const api = useMemo(
    () => ({
      get: {
        ...getState,
        request: getDispatch,
      },
      post: {
        ...postState,
        request: postDispatch,
      },
    }),
    [getState, getDispatch, postState, postDispatch]
  );

  return api;
}

function getDnaTable(payload) {
  return abortableFetch(
    ['api', 'creative-analytics', 'reportCardDnaTable'].join('/'),
    {
      method: 'POST',
      headers: HEADERS,
      credentials: 'include',
      body: JSON.stringify(payload),
    }
  );
}

function postGetKeywords(payload) {
  return abortableFetch(
    ['api', 'creative-analytics', 'get-all-keywords'].join('/'),
    {
      method: 'POST',
      headers: HEADERS,
      credentials: 'include',
      body: JSON.stringify(payload),
    }
  );
}

export function useGetDnaTableApi() {
  const mounted = useRef(false);
  const withRef = bindFetchToRef(mounted);
  const [postState, postDispatch] = useApiState(
    (payload) =>
      withRef(getDnaTable(payload)).then(({ data: { data } }) => data),
    {
      data: null,
      error: null,
      loading: false,
      count: 0,
    }
  );

  const handlePost = (payload) => {
    postDispatch(payload);
  };

  useEffect(() => {
    mounted.current = true;

    return () => (mounted.current = false);
  }, []);

  return {
    post: {
      ...postState,
      request: handlePost,
    },
  };
}

export function useGetKeywordsApi() {
  const mounted = useRef(false);
  const withRef = bindFetchToRef(mounted);
  const [postState, postDispatch] = useApiState(
    (payload) => withRef(postGetKeywords(payload)).then(({ data }) => data),
    {
      data: null,
      error: null,
      loading: false,
      count: 0,
    }
  );

  useEffect(() => {
    mounted.current = true;

    return () => (mounted.current = false);
  }, []);

  return {
    ...postState,
    request: postDispatch,
  };
}

function getPreset(payload) {
  return abortableFetch(['api', 'creative-analytics', 'get-preset'].join('/'), {
    method: 'POST',
    headers: HEADERS,
    credentials: 'include',
    body: JSON.stringify(payload),
  });
}

function savePreset(payload) {
  return abortableFetch(
    ['api', 'creative-analytics', 'save-preset'].join('/'),
    {
      method: 'POST',
      headers: HEADERS,
      credentials: 'include',
      body: JSON.stringify(payload),
    }
  );
}

export function usePresetsApi() {
  const mounted = useRef(false);
  const withRef = bindFetchToRef(mounted);

  const [getState, getDispatch] = useApiState(
    (payload) => withRef(getPreset(payload)).then(({ data }) => data),
    {
      data: null,
      error: null,
      loading: false,
      count: 0,
    }
  );
  const [postState, postDispatch] = useApiState(
    (payload) => withRef(savePreset(payload)).then(({ data }) => data),
    {
      data: null,
      error: null,
      loading: false,
      count: 0,
    }
  );

  useEffect(() => {
    mounted.current = true;

    return () => (mounted.current = false);
  }, []);

  return {
    get: {
      ...getState,
      request: getDispatch,
    },
    post: {
      ...postState,
      request: postDispatch,
    },
  };
}

function preprocessVisuals(payload) {
  return abortableFetch(
    ['api', 'creative-analytics', 'preprocess-visuals'].join('/'),
    {
      method: 'POST',
      headers: HEADERS,
      credentials: 'include',
      body: JSON.stringify(payload),
    }
  );
}

function assignClusters(payload) {
  return abortableFetch(
    ['api', 'creative-analytics', 'assign-clusters'].join('/'),
    {
      method: 'POST',
      headers: HEADERS,
      credentials: 'include',
      body: JSON.stringify(payload),
    }
  );
}

function getClusterData(payload) {
  return abortableFetch(
    ['api', 'creative-analytics', 'get-cluster-data'].join('/'),
    {
      method: 'POST',
      headers: HEADERS,
      credentials: 'include',
      body: JSON.stringify(payload),
    }
  );
}

export function useClustersApi() {
  const mounted = useRef(false);
  const withRef = bindFetchToRef(mounted);
  const [saveState, saveDispatch] = useApiState(
    (payload) => withRef(preprocessVisuals(payload)).then(({ data }) => data),
    {
      data: null,
      error: null,
      loading: false,
      count: 0,
    }
  );
  const [postState, postDispatch] = useApiState(
    (payload) => withRef(assignClusters(payload)).then(({ data }) => data),
    {
      data: null,
      error: null,
      loading: false,
      count: 0,
    }
  );
  const [getState, getDispatch] = useApiState(
    (payload) => withRef(getClusterData(payload)).then(({ data }) => data),
    {
      data: null,
      error: null,
      loading: false,
      count: 0,
    }
  );

  useEffect(() => {
    mounted.current = true;

    return () => (mounted.current = false);
  }, []);

  return {
    save: {
      ...saveState,
      request: saveDispatch,
    },
    post: {
      ...postState,
      request: postDispatch,
    },
    get: {
      ...getState,
      request: getDispatch,
    },
  };
}

function getVariants(payload) {
  return abortableFetch(
    [
      'api',
      'creative-analytics',
      'get-variant-data',
    ].join('/'),
    {
      method: 'POST',
      headers: HEADERS,
      credentials: 'include',
      body: JSON.stringify(payload),
    }
  );
}

export function useVariationApi() {
  const mounted = useRef(false);
  const withRef = bindFetchToRef(mounted);
  const [postState, postDispatch] = useApiState(
    (payload) => withRef(getVariants(payload)).then(({ data }) => data),
    {
      data: null,
      error: null,
      loading: false,
      count: 0,
    }
  );

  useEffect(() => {
    mounted.current = true;

    return () => (mounted.current = false);
  }, []);

  return {
    post: {
      ...postState,
      request: postDispatch,
    },
  };
}

function fetchCopilotData(payload) {
  return abortableFetch(
    [
      'api',
      'creative-analytics',
      'fetch-copilot-data',
    ].join('/'),
    {
      method: 'POST',
      headers: HEADERS,
      credentials: 'include',
      body: JSON.stringify(payload),
    }
  );
}

function saveCopilotSettings(payload) {
  return abortableFetch(
    [
      'api',
      'creative-analytics',
      'save-copilot-settings',
    ].join('/'),
    {
      method: 'POST',
      headers: HEADERS,
      credentials: 'include',
      body: JSON.stringify(payload),
    }
  );
}

function getCopilotSettings(view_id) {
  return abortableFetch(
    [
      'api',
      'creative-analytics',
      view_id,
      'get-copilot-settings',
    ].join('/'),
    {
      method: 'GET',
      headers: HEADERS,
      credentials: 'include',
    }
  );
}

export function useCopilotApi() {
  const mounted = useRef(false);
  const withRef = bindFetchToRef(mounted);
  const [postState, postDispatch] = useApiState(
    (payload) => withRef(fetchCopilotData(payload)).then(({ data }) => data),
    {
      data: null,
      error: null,
      loading: false,
      count: 0,
    }
  );
  const [saveState, saveDispatch] = useApiState(
    (view_id) => withRef(saveCopilotSettings(view_id)).then(({ data }) => data),
    {
      data: null,
      error: null,
      loading: false,
      count: 0,
    }
  );
  const [getState, getDispatch] = useApiState(
    (payload) => withRef(getCopilotSettings(payload)).then(({ data }) => data),
    {
      data: null,
      error: null,
      loading: false,
      count: 0,
    }
  );
  useEffect(() => {
    mounted.current = true;

    return () => (mounted.current = false);
  }, []);

  return {
    post: {
      ...postState,
      request: postDispatch,
    },
    save: {
      ...saveState,
      request: saveDispatch,
    },
    get: {
      ...getState,
      request: getDispatch,
    },
  };
}

function getCopyData(payload) {
  return abortableFetch(
    [
      'api',
      'creative-analytics',
      'get-copy-data',
    ].join('/'),
    {
      method: 'POST',
      headers: HEADERS,
      credentials: 'include',
      body: JSON.stringify(payload),
    }
  );
}

export function useCopyReportApi() {
  const mounted = useRef(false);
  const withRef = bindFetchToRef(mounted);
  const [postState, postDispatch] = useApiState(
    (payload) => withRef(getCopyData(payload)).then(({ data }) => data),
    {
      data: null,
      error: null,
      loading: false,
      count: 0,
    }
  );

  useEffect(() => {
    mounted.current = true;

    return () => (mounted.current = false);
  }, []);

  return {
    post: {
      ...postState,
      request: postDispatch,
    },
  };
}

function saveAssetIteration(payload) {
  return abortableFetch(
    [
      'api',
      'creative-analytics',
      'save-asset-iteration',
    ].join('/'),
    {
      method: 'POST',
      headers: HEADERS,
      credentials: 'include',
      body: JSON.stringify(payload),
    }
  );
}

function deleteAssetIteration(asset_iteration_id) {
  return abortableFetch(
    [
      'api',
      'creative-analytics',
      asset_iteration_id,
      'delete-asset-iteration',
    ].join('/'),
    {
      method: 'GET',
      headers: HEADERS,
      credentials: 'include',
    }
  );
}

function getAssetIterations(account_id) {
  return abortableFetch(
    [
      'api',
      'creative-analytics',
      account_id,
      'get-asset-iterations',
    ].join('/'),
    {
      method: 'GET',
      headers: HEADERS,
      credentials: 'include',
    }
  );
}

export function useIterationsApi() {
  const mounted = useRef(false);
  const withRef = bindFetchToRef(mounted);
  const [postState, postDispatch] = useApiState(
    (payload) => withRef(saveAssetIteration(payload)).then(({ data }) => data),
    {
      data: null,
      error: null,
      loading: false,
      count: 0,
    }
  );
  const [deleteState, deleteDispatch] = useApiState(
    (asset_iteration_id) => withRef(deleteAssetIteration(asset_iteration_id)).then(({ data }) => data),
    {
      data: null,
      error: null,
      loading: false,
      count: 0,
    }
  );
  const [getState, getDispatch] = useApiState(
    (payload) => withRef(getAssetIterations(payload)).then(({ data }) => data),
    {
      data: null,
      error: null,
      loading: false,
      count: 0,
    }
  );
  useEffect(() => {
    mounted.current = true;

    return () => (mounted.current = false);
  }, []);

  return {
    post: {
      ...postState,
      request: postDispatch,
    },
    delete: {
      ...deleteState,
      request: deleteDispatch,
    },
    get: {
      ...getState,
      request: getDispatch,
    },
  };
}

export const useCreativeAnalyticsApis = () => {
  const frequencyApi = useGetPerformanceFrequencyReportAsync();
  const frequencyApiStatus = useGetPerformanceFrequencyReportStatus();
  const frequencyCompareApi = useGetPerformanceFrequencyReportAsync();
  const frequencyCompareApiStatus = useGetPerformanceFrequencyReportStatus();
  const newAdsApi = useGetNewAds();
  const asyncApi = useAsyncCustomCategoriesApi();
  const postRefreshApi = useRefreshReportApi();
  const refreshStatusApi = useGetRefreshStatusApi();
  const missingSpendApi = useGetMissingSpend();
  const getUdcApi = useGetApi();
  const keywordsApi = useGetKeywordsApi();
  const revisionRequestApi = useRevisionRequestApi();
  const reportCardPrefsApi = useReportCardPrefsApi();
  const getDerivedLabelsApi = useGetDerivedLabelsApi();
  const presetsApi = usePresetsApi();
  const clustersApi = useClustersApi();
  const variationApi = useVariationApi();
  const copilotApi = useCopilotApi();
  const copyReportApi = useCopyReportApi();
  const iterationsApi = useIterationsApi();

  return {
    frequencyApi,
    frequencyApiStatus,
    frequencyCompareApi,
    frequencyCompareApiStatus,
    newAdsApi,
    asyncApi,
    postRefreshApi,
    refreshStatusApi,
    missingSpendApi,
    getUdcApi,
    keywordsApi,
    revisionRequestApi,
    reportCardPrefsApi,
    getDerivedLabelsApi,
    presetsApi,
    clustersApi,
    variationApi,
    copilotApi,
    copyReportApi,
    iterationsApi,
  };
};
