import React, { useMemo } from 'react';
import Wrapper from './Wrapper';
import './Table.scss';
import { useSelector } from 'react-redux';
import { METRICS_DATA } from '../../store/CreativeAnalytics/constants';
import { addMetrics } from './PeriodComparison/utils';
import { useCustomMetrics } from '../CreativeAnalytics/selectors';
import { useCreativeAnalyticsGet } from '../CreativeAnalytics/contexts';
import { formatPreviousData } from './CreativePerformance/utils';
import { LoadingSkeleton } from './Helpers';
import { checkIfVisualIsSelected } from '../CreativeAnalytics/hooks/useFilteredData/utils/filterVisuals';

const Dna = (props) => {
  const { shouldGroupLabelsByRatio, pfrLoading, refresh } = props;
  const { clientMetrics, filteredAllVisualsLabel, selectedLabels } =
    useCreativeAnalyticsGet();
  const { custom_events, additional_metrics, customIntegratedMetrics } =
    useCustomMetrics();
  const {
    data,
    loading,
    error,
    metadata,
    spendThreshold,
    custom_performance_scores,
  } = useSelector(
    ({
      ViewSettings: { spendThreshold, custom_performance_scores },
      CreativeAnalytics: { metricsData, dnaReportData },
      Loaders,
      Errors,
    }) => {
      return {
        data: metricsData || [],
        metadata: dnaReportData,
        loading: Loaders[METRICS_DATA],
        error: Errors[METRICS_DATA],
        spendThreshold,
        custom_performance_scores,
      };
    }
  );

  const all_visuals = filteredAllVisualsLabel?.visuals ?? null;

  const shouldUsePfrData =
    (all_visuals && shouldGroupLabelsByRatio) || (!data && all_visuals);

  const visualsData = useMemo(() => {
    try {
      if (all_visuals) {
        return all_visuals.map((visual) => ({
          ...visual,
          _id: visual.id,
        }));
      } else if (data) {
        return data
          .filter((visual) => {
            return (
              visual.spend >= spendThreshold &&
              (selectedLabels.length === 0 ||
                checkIfVisualIsSelected(
                  { ...visual, id: visual._id },
                  selectedLabels
                ))
            );
          })
          .map(
            addMetrics({
              clientMetrics,
              customEvents: custom_events,
              additional_metrics,
              customIntegratedMetrics,
              custom_performance_scores,
            })
          );
      }
    } catch (e) {
      console.error(e);
      return [];
    }
  }, [
    all_visuals,
    data,
    clientMetrics,
    custom_events,
    additional_metrics,
    customIntegratedMetrics,
    spendThreshold,
    selectedLabels,
  ]);

  const byId = useMemo(() => {
    return formatPreviousData(visualsData);
  }, [visualsData]);

  const isLoading =
    (loading && !visualsData?.length) ||
    (pfrLoading);

  if (isLoading) return <LoadingSkeleton />;

  return (
    <Wrapper {...{ visualsData, byId, loading, error, metadata, pfrLoading }} {...props} />
  );
};

export default Dna;
