import { darwinDateToFormat } from "../../../utils/darwin-dates";

export const makeFormattedDates = (dates) => {
  // expecting string e.g. "2010-01-01,2023-09-30"
  let [start, end] = ["", ""];
  if (dates) {
    [start, end] = dates
      .split(",")
      .map((date) => darwinDateToFormat(date, "MM/dd/yyyy"));
  }

  return [start, end];
};

export const getLabelsLookup = (filteredData, value) => {
  if (!filteredData) return null;

  return filteredData[filteredData.findIndex((x) => x.value === value)];
};
