import { useRouteMatch } from 'react-router-dom';
import { AD_STUDY } from '../../store/CreativeReports/constants';
import { BLOCK_REPORT } from '../Permissions/constants';

export const getReportOptions = (
  enableFacebookReports,
  enableVisualReport,
  isThimble,
  isDirectMail,
  darwin_light,
  hasPermission
) => {
  let options = [
    ...(hasPermission(BLOCK_REPORT) ? [{ label: 'Blocks', value: 'block' }] : []),
  ];

  if (enableFacebookReports) {
    if (enableVisualReport && !darwin_light) {
      options.push({ label: 'Visuals', value: 'visuals' });
    }
    if (isThimble) {
      THIMBLE_REPORTS.forEach((report) => options.push(report));
    }
  }

  return options;
};

// @ts-ignore

export const usePath = () => {
  const {
    params: { viewId, page },
  } = useRouteMatch('/:viewId/:page');
  const params = new URLSearchParams(window.location.search);

  return {
    viewId,
    initialEventKey:
      page === 'creative-reports'
        ? `${page}?report=${params.get('report') || 'ad_name'}`
        : page === 'diagnostics'
        ? `${page}?type=${params.get('type') || 'ad'}`
        : page,
  };
};

/**
 * @param {String} report Value of report=... parameter.
 *
 * @return {String}
 */

export const setReportParams = (report) => {
  const params = new URLSearchParams(window.location.search);

  params.delete('report');

  if (params.has('type')) {
    const type = params.get('type');
    const shouldDelete = !(type === 'video' || type === 'image');

    shouldDelete && params.delete('type');
  }

  if (report !== AD_STUDY) {
    params.delete('study_type');
  }

  return [...params.keys()].length > 0
    ? [report, params.toString()].join('&')
    : report;
};

export const setAnalyticsParams = (mode) => {
  const params = new URLSearchParams(window.location.search);

  params.forEach((value, key) => {
    const preserve = ['spendThreshold', 'period1'];
    const shouldDelete = !preserve.includes(key);
    shouldDelete && params.delete(key);
  });

  return [...params.keys()].length > 0
    ? `${mode}?${[params.toString()].join('&')}`
    : mode;
};
